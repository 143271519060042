import React from 'react'
import "./AdsVertical.css"

import AdSenseAd from './AdSenseAd'

const AdsVertical = () => {
  return (
    <aside>
    {/* AdsVertical */}
      <AdSenseAd></AdSenseAd>
    </aside>
  )
}

export default AdsVertical
