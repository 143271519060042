import React from 'react'
import "./AdsHorizontal.css"


import AdSenseAd from './AdSenseAd'

const AdsHorizontal = () => {
  return (
    <figure>
    <AdSenseAd/>
     {/* AdsHorizontal */}
    </figure>
  )
}

export default AdsHorizontal
