import React, { useEffect } from 'react';

const AdSenseAd = () => {
    useEffect(() => {
        // Toto zabezpečí, že sa reklama načíta, keď sa komponent renderuje
        if (window.adsbygoogle) {
            window.adsbygoogle.push({});
        }
    }, []);

    return (
        <div>
            <ins className="adsbygoogle"
                style={{ display: 'block' }}
                data-ad-client="ca-pub-1376252668623148"
                data-ad-slot="f08c47fec0942fa0"
                data-ad-format="auto"></ins>
        </div>
    );
}

export default AdSenseAd;
