import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Navbar";
import AdsVertical from "./AdsVertical";
import Wrapper from "./Wrapper";
import CategoryPage from "./pages/CategoryPage";
import TestDetailPage from "./pages/TestDetailPage";

const App = () => {
  return (
    <Router>
      <div>
        <Navbar />
        <div className="app">
          <div className="wrapper_ads">
            <AdsVertical />
            <Routes>
              <Route path="/" element={<Wrapper />} />
              <Route
                path="/category/:categoryName"
                element={<CategoryPage />}
              />
              <Route path="/test/:testName" element={<TestDetailPage />} />
            </Routes>
            <AdsVertical />
          </div>
        </div>
      </div>
    </Router>
  );
};

export default App;
