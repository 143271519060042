import "./Navbar.css"
import testIcon from "./icons/test-icon.png"

const Navbar = () => {
  return (
    <nav>
        <div className="navWrapper">
            <div className="logo">
              <a href="/"> <img src={testIcon} alt="" /></a>   
              <a href="/"><h1>Otestuj sa!</h1></a>
            </div>
                {/* <a href="https://www.flaticon.com/free-icon/quiz_14991410?page=1&position=4&term=test+quiz&origin=style-search&related_id=14991410" title="quiz icons" className="iconCreator">Quiz icons created by Grafixpoint - Flaticon</a> */}
           
        </div>
    </nav>
  )
}

export default Navbar
