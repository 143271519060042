const categoryTests = {
  it: [
    // {
    //   name: "Meno testu",
    //   questions: [
    //     {
    //       question: "Tu bude otázka",
    //       options: [
    //         "možnosť 1 - správna",
    //         "možnosť 2",
    //         "možnosť 3",
    //         "možnosť 4",
    //       ],
    //       correctAnswer: "možnosť 1 - správna",
    //     },
    //   ],
    //   backgroundImage: require("./bg/main-bg2.jpg"),
    // },

    {
      name: "Ako funguje internet",
      questions: [
        {
          question: "Čo je základným stavebným kameňom internetu?",
          options: ["Server", "Router", "IP adresa", "HTML kód"],
          correctAnswer: "Server",
        },
        {
          question: "Čo označuje skratka IP v IP adrese?",
          options: [
            "Internetový protokol",
            "Informačný procesor",
            "Interný program",
            "Interaktívny portál",
          ],
          correctAnswer: "Internetový protokol",
        },
        {
          question:
            "Ktorá technológia slúži na bezdrôtové pripojenie zariadení k internetu?",
          options: ["Ethernet", "Wi-Fi", "Bluetooth", "USB"],
          correctAnswer: "Wi-Fi",
        },
        {
          question: "Čo robí DNS (Domain Name System)?",
          options: [
            "Prekladá názvy domén na IP adresy",
            "Šifruje internetovú komunikáciu",
            "Upravuje rýchlosť sťahovania",
            "Poskytuje internetové pripojenie",
          ],
          correctAnswer: "Prekladá názvy domén na IP adresy",
        },
        {
          question:
            "Aký protokol zabezpečuje šifrovanie komunikácie na webových stránkach?",
          options: ["HTTP", "HTTPS", "FTP", "SMTP"],
          correctAnswer: "HTTPS",
        },
        {
          question: "Čo je webový prehliadač?",
          options: [
            "Program na prístup k internetu",
            "Sieťový kábel",
            "Typ servera",
            "Zariadenie na meranie rýchlosti internetu",
          ],
          correctAnswer: "Program na prístup k internetu",
        },
        {
          question: "Čo je URL?",
          options: [
            "Adresa webovej stránky",
            "Typ súboru",
            "Programovací jazyk",
            "Štandard šifrovania",
          ],
          correctAnswer: "Adresa webovej stránky",
        },
        {
          question: "Čo robí firewall?",
          options: [
            "Blokuje neoprávnený prístup k sieti",
            "Zrýchľuje pripojenie na internet",
            "Spravuje cookies",
            "Zabezpečuje bezdrôtové pripojenie",
          ],
          correctAnswer: "Blokuje neoprávnený prístup k sieti",
        },
        {
          question: "Aká jednotka sa používa na meranie rýchlosti internetu?",
          options: ["Mb/s", "Gb", "MHz", "Kb"],
          correctAnswer: "Mb/s",
        },
        {
          question:
            "Ktorá z týchto technológií umožňuje zdieľanie internetového pripojenia cez mobilné zariadenie?",
          options: ["Wi-Fi Hotspot", "Ethernet kábel", "VPN", "DSL"],
          correctAnswer: "Wi-Fi Hotspot",
        },
        {
          question: "Čo znamená pojem cloud computing?",
          options: [
            "Ukladanie a spracovanie dát na vzdialených serveroch",
            "Rýchlejší internet",
            "Prenos dát medzi dvoma zariadeniami",
            "Optimalizácia siete",
          ],
          correctAnswer: "Ukladanie a spracovanie dát na vzdialených serveroch",
        },
        {
          question: "Čo je ping?",
          options: [
            "Nástroj na meranie odozvy siete",
            "Typ šifrovania",
            "Množstvo dát prenesených sieťou",
            "Rýchlosť sťahovania",
          ],
          correctAnswer: "Nástroj na meranie odozvy siete",
        },
        {
          question: "Akú úlohu má poskytovateľ internetových služieb (ISP)?",
          options: [
            "Zabezpečuje prístup k internetu",
            "Chráni počítače pred vírusmi",
            "Zvyšuje výkon počítača",
            "Poskytuje softvérové aktualizácie",
          ],
          correctAnswer: "Zabezpečuje prístup k internetu",
        },
        {
          question: "Čo je latency (latencia) v internetovej komunikácii?",
          options: [
            "Oneskorenie pri prenose dát",
            "Rýchlosť nahrávania dát",
            "Typ šifrovacieho algoritmu",
            "Dátová priepustnosť",
          ],
          correctAnswer: "Oneskorenie pri prenose dát",
        },
        {
          question: "Čo sa stane, ak zadáš nesprávnu IP adresu do prehliadača?",
          options: [
            "Webová stránka sa neotvorí",
            "Otvorí sa iná stránka",
            "Rýchlosť pripojenia sa zvýši",
            "Stránka sa načíta pomalšie",
          ],
          correctAnswer: "Webová stránka sa neotvorí",
        },
      ],
      backgroundImage: require("./categories/it/it-internet.jpg"),
    },

    {
      name: "Všeobecný test z informatiky",
      questions: [
        {
          question: "Čo je to operačný systém?",
          options: [
            "Program, ktorý riadi hardvér počítača a poskytuje služby aplikáciám",
            "Program na úpravu textu",
            "Hardware, ktorý slúži na uchovávanie dát",
            "Nástroj na tvorbu grafiky",
          ],
          correctAnswer:
            "Program, ktorý riadi hardvér počítača a poskytuje služby aplikáciám",
        },
        {
          question: "Čo znamená skratka 'URL'?",
          options: [
            "Uniform Resource Locator",
            "Uniform Resource Link",
            "Universal Resource Locator",
            "Universal Resource Link",
          ],
          correctAnswer: "Uniform Resource Locator",
        },
        {
          question: "Aký typ pamäte je najrýchlejší?",
          options: ["RAM", "HDD", "SSD", "Cache"],
          correctAnswer: "Cache",
        },
        {
          question: "Čo je to 'cloud computing'?",
          options: [
            "Ukladanie a spracovanie dát na vzdialených serveroch cez internet",
            "Typ programovacieho jazyka",
            "Špeciálny druh hardvéru",
            "Technika na zlepšenie grafického výkonu",
          ],
          correctAnswer:
            "Ukladanie a spracovanie dát na vzdialených serveroch cez internet",
        },
        {
          question: "Aký jazyk sa používa na tvorbu webových stránok?",
          options: ["Python", "HTML", "Java", "SQL"],
          correctAnswer: "HTML",
        },
        {
          question: "Čo je to 'firewall'?",
          options: [
            "Bezpečnostný systém, ktorý monitoruje a kontroluje prístup do počítačovej siete",
            "Typ antivírusového programu",
            "Nástroj na analýzu databáz",
            "Špeciálny druh operačného systému",
          ],
          correctAnswer:
            "Bezpečnostný systém, ktorý monitoruje a kontroluje prístup do počítačovej siete",
        },
        {
          question: "Čo je to 'software'?",
          options: [
            "Súbor programov a aplikácií používaných na vykonávanie úloh počítača",
            "Hardvér počítača",
            "Súbor dát uložený na pevnom disku",
            "Funkcia operačného systému",
          ],
          correctAnswer:
            "Súbor programov a aplikácií používaných na vykonávanie úloh počítača",
        },
        {
          question: "Čo robí 'antivírusový program'?",
          options: [
            "Detekuje a odstraňuje škodlivý softvér",
            "Optimalizuje výkon počítača",
            "Umožňuje správu súborov",
            "Zabezpečuje pripojenie k internetu",
          ],
          correctAnswer: "Detekuje a odstraňuje škodlivý softvér",
        },
        {
          question: "Čo je 'bug' v softvéri?",
          options: [
            "Chyba alebo nedostatok v kóde programu",
            "Názov pre softvérovú aktualizáciu",
            "Speciálny druh hardvéru",
            "Metóda na optimalizáciu výkonu počítača",
          ],
          correctAnswer: "Chyba alebo nedostatok v kóde programu",
        },
        {
          question: "Čo je to 'database'?",
          options: [
            "Organizovaný súbor dát, ktorý môže byť prístupný a spravovaný počítačovými aplikáciami",
            "Typ operačného systému",
            "Hardvérové zariadenie na ukladanie dát",
            "Aplikácia na úpravu fotografií",
          ],
          correctAnswer:
            "Organizovaný súbor dát, ktorý môže byť prístupný a spravovaný počítačovými aplikáciami",
        },
        {
          question: "Aký je účel 'cookies' na webových stránkach?",
          options: [
            "Uloženie preferencií a údajov používateľa na webovej stránke",
            "Zvýšenie rýchlosti pripojenia na internet",
            "Optimalizácia hardvéru počítača",
            "Zabezpečenie webovej stránky proti útokom",
          ],
          correctAnswer:
            "Uloženie preferencií a údajov používateľa na webovej stránke",
        },
        {
          question: "Čo je to 'URL' v kontexte webových stránok?",
          options: [
            "Adresa webovej stránky",
            "Typ antivírusového programu",
            "Kódovanie dát",
            "Funkcia webového prehliadača",
          ],
          correctAnswer: "Adresa webovej stránky",
        },
        {
          question: "Čo je to 'algorithm'?",
          options: [
            "Súbor presne definovaných krokov na riešenie problému alebo vykonanie úlohy",
            "Typ hardvéru počítača",
            "Jazyk na tvorbu webových stránok",
            "Formát pre ukladanie obrázkov",
          ],
          correctAnswer:
            "Súbor presne definovaných krokov na riešenie problému alebo vykonanie úlohy",
        },
        {
          question: "Čo je to 'encryption'?",
          options: [
            "Proces šifrovania údajov na ochranu pred neoprávneným prístupom",
            "Typ antivírusového programu",
            "Metóda na zvýšenie rýchlosti počítača",
            "Technika na tvorbu grafiky",
          ],
          correctAnswer:
            "Proces šifrovania údajov na ochranu pred neoprávneným prístupom",
        },
        {
          question: "Čo je to 'open source' software?",
          options: [
            "Softvér, ktorého kód je verejne dostupný a môže byť upravovaný komunitou",
            "Kompletný balík softvéru pre komerčné účely",
            "Softvér s obmedzeným prístupom pre vývojárov",
            "Typ hardvéru s otvorenou architektúrou",
          ],
          correctAnswer:
            "Softvér, ktorého kód je verejne dostupný a môže byť upravovaný komunitou",
        },
      ],
      backgroundImage: require("./categories/it/vseo-test-1.jpg"),
    },

    {
      name: "Klávesové skratky vo Windowse",
      questions: [
        {
          question: "Čo robí klávesová skratka Ctrl + C?",
          options: [
            "Kopíruje vybraný text alebo objekt",
            "Vystrihne vybraný text alebo objekt",
            "Zatvorí aktuálne okno",
            "Vloží vybraný text alebo objekt",
          ],
          correctAnswer: "Kopíruje vybraný text alebo objekt",
        },
        {
          question: "Čo spraví klávesová skratka Ctrl + V?",
          options: [
            "Zatvorí aktuálne okno",
            "Vloží skopírovaný text alebo objekt",
            "Zmaže vybraný text alebo objekt",
            "Kopíruje vybraný text alebo objekt",
          ],
          correctAnswer: "Vloží skopírovaný text alebo objekt",
        },
        {
          question: "Aký je účinok klávesovej skratky Ctrl + Z?",
          options: [
            "Vloží skopírovaný text alebo objekt",
            "Kopíruje vybraný text alebo objekt",
            "Zruší poslednú akciu",
            "Zatvorí aktuálne okno",
          ],
          correctAnswer: "Zruší poslednú akciu",
        },
        {
          question: "Čo sa stane po stlačení Alt + Tab?",
          options: [
            "Otvára nové okno",
            "Minimalizuje všetky okná",
            "Prepne medzi otvorenými oknami",
            "Zatvorí aktuálne okno",
          ],
          correctAnswer: "Prepne medzi otvorenými oknami",
        },
        {
          question: "Čo robí klávesová skratka Ctrl + A?",
          options: [
            "Označí všetky objekty alebo text v okne",
            "Uloží dokument",
            "Otvára nové okno",
            "Zatvorí aktuálne okno",
          ],
          correctAnswer: "Označí všetky objekty alebo text v okne",
        },
        {
          question: "Čo spraví klávesová skratka Ctrl + S?",
          options: [
            "Minimalizuje všetky okná",
            "Uloží dokument",
            "Zatvorí aktuálne okno",
            "Zmaže vybraný text alebo objekt",
          ],
          correctAnswer: "Uloží dokument",
        },
        {
          question: "Čo sa stane pri stlačení Windows + L?",
          options: [
            "Uzamkne obrazovku",
            "Minimalizuje všetky okná",
            "Zatvorí aktuálne okno",
            "Otvára nové okno",
          ],
          correctAnswer: "Uzamkne obrazovku",
        },
        {
          question: "Aký je účinok klávesovej skratky Ctrl + P?",
          options: [
            "Kopíruje vybraný text alebo objekt",
            "Otvára dialógové okno pre tlač",
            "Zruší poslednú akciu",
            "Otvára nové okno",
          ],
          correctAnswer: "Otvára dialógové okno pre tlač",
        },
        {
          question: "Čo spraví klávesová skratka Windows + E?",
          options: [
            "Otvára Prieskumníka súborov",
            "Zatvorí aktuálne okno",
            "Otvára Štart menu",
            "Minimalizuje všetky okná",
          ],
          correctAnswer: "Otvára Prieskumníka súborov",
        },
        {
          question: "Čo sa stane po stlačení Alt + F4?",
          options: [
            "Zatvorí aktuálne okno",
            "Minimalizuje všetky okná",
            "Otvára nové okno",
            "Prepne medzi otvorenými oknami",
          ],
          correctAnswer: "Zatvorí aktuálne okno",
        },
        {
          question: "Čo robí klávesová skratka Ctrl + X?",
          options: [
            "Vloží skopírovaný text alebo objekt",
            "Vystrihne vybraný text alebo objekt",
            "Zatvorí aktuálne okno",
            "Kopíruje vybraný text alebo objekt",
          ],
          correctAnswer: "Vystrihne vybraný text alebo objekt",
        },
        {
          question: "Aký je účinok klávesovej skratky Ctrl + Shift + Esc?",
          options: [
            "Otvára nové okno",
            "Otvára Správcu úloh",
            "Minimalizuje všetky okná",
            "Zatvorí aktuálne okno",
          ],
          correctAnswer: "Otvára Správcu úloh",
        },
        {
          question: "Čo spraví klávesová skratka Windows + D?",
          options: [
            "Zruší poslednú akciu",
            "Otvára dialógové okno pre tlač",
            "Minimalizuje všetky okná a zobrazí pracovnú plochu",
            "Otvára nové okno",
          ],
          correctAnswer: "Minimalizuje všetky okná a zobrazí pracovnú plochu",
        },
        {
          question: "Čo robí klávesová skratka F2?",
          options: [
            "Otvára dialógové okno pre tlač",
            "Premenuje vybraný súbor alebo priečinok",
            "Minimalizuje všetky okná",
            "Zatvorí aktuálne okno",
          ],
          correctAnswer: "Premenuje vybraný súbor alebo priečinok",
        },
        {
          question: "Čo sa stane po stlačení Windows + R?",
          options: [
            "Zruší poslednú akciu",
            "Otvára nové okno",
            "Otvára dialógové okno 'Spustiť'",
            "Prepne medzi otvorenými oknami",
          ],
          correctAnswer: "Otvára dialógové okno 'Spustiť'",
        },
      ],
      backgroundImage: require("./categories/it/it-shortcuts.jpg"),
    },

    {
      name: "Základné HTML tagy",
      questions: [
        {
          question: "Ktorý HTML tag sa používa na vytvorenie nadpisu?",
          options: ["<header>", "<title>", "<h1>", "<p>"],
          correctAnswer: "<h1>",
        },
        {
          question: "Ktorý HTML tag sa používa na označenie odstavca textu?",
          options: ["<span>", "<section>", "<div>", "<p>"],
          correctAnswer: "<p>",
        },
        {
          question: "Ktorý HTML tag slúži na vloženie obrázku?",
          options: ["<photo>", "<image>", "<picture>", "<img>"],
          correctAnswer: "<img>",
        },
        {
          question:
            "Ktorý HTML tag sa používa na vytvorenie hypertextového odkazu?",
          options: ["<a>", "<link>", "<button>", "<href>"],
          correctAnswer: "<a>",
        },
        {
          question: "Ktorý HTML tag sa používa na vytvorenie tabuľky?",
          options: ["<grid>", "<tab>", "<chart>", "<table>"],
          correctAnswer: "<table>",
        },
        {
          question: "Ktorý HTML tag sa používa na definovanie nadpisu sekcie?",
          options: ["<title>", "<header>", "<h1>", "<section>"],
          correctAnswer: "<h1>",
        },
        {
          question:
            "Ktorý HTML tag sa používa na definovanie hypertextového odkazu s názvom?",
          options: ["<link>", "<url>", "<a>", "<href>"],
          correctAnswer: "<a>",
        },
        {
          question:
            "Ktorý HTML tag sa používa na vytvorenie zoznamu s číslami?",
          options: ["<list>", "<ol>", "<dl>", "<ul>"],
          correctAnswer: "<ol>",
        },
        {
          question:
            "Ktorý HTML tag sa používa na označenie nestruktúrovaného zoznamu?",
          options: ["<ul>", "<dl>", "<list>", "<ol>"],
          correctAnswer: "<ul>",
        },
        {
          question:
            "Ktorý HTML tag sa používa na vloženie formátovaného textu alebo kódu?",
          options: ["<code>", "<format>", "<text>", "<pre>"],
          correctAnswer: "<pre>",
        },
        {
          question:
            "Ktorý HTML tag sa používa na vytvorenie formulárového poľa pre textový vstup?",
          options: ["<textarea>", "<input>", "<form>", "<field>"],
          correctAnswer: "<input>",
        },
        {
          question:
            "Ktorý HTML tag sa používa na definovanie jednej bunky v tabuľke?",
          options: ["<th>", "<tr>", "<cell>", "<td>"],
          correctAnswer: "<td>",
        },
        {
          question:
            "Ktorý HTML tag sa používa na definovanie hlavičky tabuľky?",
          options: ["<tr>", "<header>", "<td>", "<th>"],
          correctAnswer: "<th>",
        },
        {
          question:
            "Ktorý HTML tag sa používa na vytvorenie nadpisu pre sekciu stránky?",
          options: ["<section>", "<h1>", "<header>", "<title>"],
          correctAnswer: "<h1>",
        },
        {
          question:
            "Ktorý HTML tag sa používa na oddelenie sekcií v dokumente?",
          options: ["<article>", "<break>", "<section>", "<div>"],
          correctAnswer: "<div>",
        },
      ],
      backgroundImage: require("./categories/it/html.jpeg"),
    },

    {
      name: "Všeobecný test o CSS",
      questions: [
        {
          question: "Čo je CSS?",
          options: [
            "Štýlovací jazyk pre prezentáciu dokumentov HTML",
            "Programovací jazyk na server-side",
            "Jazyk na tvorbu databáz",
            "Nástroj na úpravu obrázkov",
          ],
          correctAnswer: "Štýlovací jazyk pre prezentáciu dokumentov HTML",
        },
        {
          question: "Ako pridáte externý CSS súbor do HTML dokumentu?",
          options: [
            "<style src='styles.css'></style>",
            "<import href='styles.css'>",
            "<link rel='stylesheet' href='styles.css'>",
            "<script src='styles.css'></script>",
          ],
          correctAnswer: "<link rel='stylesheet' href='styles.css'>",
        },
        {
          question: "Ktorá vlastnosť sa používa na zmenu farby textu v CSS?",
          options: ["color", "background-color", "text-color", "font-color"],
          correctAnswer: "color",
        },
        {
          question: "Ako nastavíte písmo na tučné v CSS?",
          options: [
            "text-transform: bold;",
            "font-size: bold;",
            "font-weight: bold;",
            "font-style: bold;",
          ],
          correctAnswer: "font-weight: bold;",
        },
        {
          question: "Aká vlastnosť určuje veľkosť okrajov okolo elementu?",
          options: ["padding", "margin", "border", "outline"],
          correctAnswer: "margin",
        },
        {
          question: "Ako sa nastavuje vlastnosť `flex-direction`?",
          options: [
            "Nastavuje smer položiek v Flexbox kontajneri",
            "Určuje zarovnanie položiek",
            "Mení výšku a šírku položiek",
            "Zarovnáva obsah vo vnútri elementu",
          ],
          correctAnswer: "Nastavuje smer položiek v Flexbox kontajneri",
        },
        {
          question: "Čo robí vlastnosť `display: none;`?",
          options: [
            "Zviditeľní element",
            "Umožní zobraziť element ako inline",
            "Skrýva element a odstráni ho z toku dokumentu",
            "Nastaví element na polopriehľadný",
          ],
          correctAnswer: "Skrýva element a odstráni ho z toku dokumentu",
        },
        {
          question: "Ako sa mení veľkosť písma v CSS?",
          options: [
            "text-size: 16px;",
            "font-height: 16px;",
            "font-size: 16px;",
            "text-height: 16px;",
          ],
          correctAnswer: "font-size: 16px;",
        },
        {
          question: "Ktorá vlastnosť v CSS slúži na zmenu typu písma?",
          options: ["font-family", "font-size", "font-style", "font-weight"],
          correctAnswer: "font-family",
        },
        {
          question: "Ako sa používa vlastnosť `align-self`?",
          options: [
            "Nastavuje zarovnanie jednotlivých položiek na priečnej osi, nielen celé riadky",
            "Nastavuje pozíciu položiek na stránke",
            "Umožňuje nastaviť priestor medzi položkami",
            "Nastavuje zarovnávanie všetkých položiek v riadku",
          ],
          correctAnswer:
            "Nastavuje zarovnanie jednotlivých položiek na priečnej osi, nielen celé riadky",
        },
        {
          question: "Ako sa mení pozadie elementu na obrázok?",
          options: [
            "background: url('image.jpg');",
            "image-background: url('image.jpg');",
            "background-color: url('image.jpg');",
            "background-image: url('image.jpg');",
          ],
          correctAnswer: "background-image: url('image.jpg');",
        },
        {
          question: "Čo robí vlastnosť `overflow: hidden;`?",
          options: [
            "Upravuje výšku a šírku kontajnera",
            "Zobrazí presahujúci obsah",
            "Skryje obsah, ktorý presahuje hranice elementu",
            "Nastaví okraje okolo elementu",
          ],
          correctAnswer: "Skryje obsah, ktorý presahuje hranice elementu",
        },
        {
          question: "Čo robí vlastnosť `outline`?",
          options: [
            "Mení veľkosť písma",
            "Upravuje farbu textu",
            "Pridáva obrys okolo elementu",
            "Nastavuje pozadie",
          ],
          correctAnswer: "Pridáva obrys okolo elementu",
        },
        {
          question: "Aké hodnoty môže mať vlastnosť `text-align`?",
          options: [
            "center, left, right, justify",
            "flex-start, flex-end, center",
            "wrap, nowrap",
            "row, column",
          ],
          correctAnswer: "center, left, right, justify",
        },
      ],
      backgroundImage: require(".//categories/it/it-css.jpeg"),
    },

    {
      name: "CSS selectors",
      questions: [
        {
          question:
            "Ktorý selektor sa používa na výber všetkých elementov na stránke?",
          options: ["element", "body", "*", "all"],
          correctAnswer: "*",
        },
        {
          question: "Ktorý selektor sa používa na výber prvku s konkrétnym ID?",
          options: ["id", "element#id", ".id", "#id"],
          correctAnswer: "#id",
        },
        {
          question:
            "Ktorý selektor sa používa na výber všetkých elementov s určitou triedou?",
          options: [".class", "class", "#class", "element.class"],
          correctAnswer: ".class",
        },
        {
          question:
            "Ako sa označuje selektor pre prvý element typu v rodičovskom kontejnery?",
          options: [":first-child", ":last-child", ":only-child", ":nth-child"],
          correctAnswer: ":first-child",
        },
        {
          question:
            "Ktorý selektor sa používa na výber elementov na základe atribútu?",
          options: [
            "[attribute]",
            "[value=attribute]",
            "[attribute]",
            "[attribute=value]",
          ],
          correctAnswer: "[attribute]",
        },
        {
          question:
            "Ktorý selektor sa používa na výber všetkých elementov, ktoré sú v rámci iného elementu?",
          options: [
            "parent > child",
            "element + sibling",
            "parent child",
            "element > child",
          ],
          correctAnswer: "parent child",
        },
        {
          question:
            "Ako sa označuje selektor pre element, ktorý je priamym susedom iného elementu?",
          options: [
            "element + sibling",
            "element > sibling",
            "element ~ sibling",
            "element",
          ],
          correctAnswer: "element + sibling",
        },
        {
          question:
            "Ktorý selektor sa používa na výber elementov na základe atribútu s konkrétnou hodnotou?",
          options: [
            "[attribute=value]",
            "[attribute~='value']",
            "[attribute]",
            "[value=attribute]",
          ],
          correctAnswer: "[attribute=value]",
        },
        {
          question:
            "Ako sa označuje selektor pre posledný element v rámci rodiča?",
          options: [
            ":last-of-type",
            ":last-child",
            ":nth-last-child",
            ":first-child",
          ],
          correctAnswer: ":last-child",
        },
        {
          question:
            "Ktorý selektor sa používa na výber elementov na základe ich typu a umiestnenia?",
          options: [
            "element:nth-of-type",
            "element:first-child",
            "element:last-of-type",
            "element:nth-child",
          ],
          correctAnswer: "element:nth-of-type",
        },
        {
          question:
            "Ktorý selektor sa používa na výber elementu na základe jeho typu a umiestnenia v rámci rodiča?",
          options: [
            "element:last-of-type",
            "element:first-of-type",
            "element:nth-of-type",
            "element:last-child",
          ],
          correctAnswer: "element:first-of-type",
        },
        {
          question:
            "Ako sa označuje selektor pre všetky elementy, ktoré sú súrodenci v rámci rodiča?",
          options: [
            "element ~ sibling",
            "element + sibling",
            "element",
            "element > sibling",
          ],
          correctAnswer: "element ~ sibling",
        },
        {
          question:
            "Ktorý selektor sa používa na výber elementov na základe hodnoty atribútu obsahujúceho konkrétny text?",
          options: [
            "[attribute~='text']",
            "[attribute*='text']",
            "[attribute$='text']",
            "[attribute^='text']",
          ],
          correctAnswer: "[attribute*='text']",
        },
        {
          question:
            "Ktorý selektor sa používa na výber elementov s konkrétnym počiatočným textom v atribúte?",
          options: [
            "[attribute$='text']",
            "[attribute^='text']",
            "[attribute~='text']",
            "[attribute*='text']",
          ],
          correctAnswer: "[attribute^='text']",
        },
        {
          question:
            "Ako sa označuje selektor pre elementy, ktoré nemajú konkrétny atribút?",
          options: [
            ":has([attribute])",
            ":not([attribute])",
            "[attribute!]",
            "[attribute='']",
          ],
          correctAnswer: ":not([attribute])",
        },
        {
          question:
            "Ktorý selektor sa používa na výber elementov, ktoré sú jediným dieťaťom svojho rodiča?",
          options: [":first-child", ":last-child", ":nth-child", ":only-child"],
          correctAnswer: ":only-child",
        },
      ],
      backgroundImage: require("./categories/it/it-css-selectors.jpg"),
    },

    {
      name: "CSS typography - fonts",
      questions: [
        {
          question: "Ktorá vlastnosť v CSS nastavuje veľkosť textu?",
          options: ["line-height", "font-size", "font-family", "text-size"],
          correctAnswer: "font-size",
        },
        {
          question: "Ako zmeníte hrúbku písma v CSS?",
          options: [
            "font-weight",
            "letter-spacing",
            "font-style",
            "text-transform",
          ],
          correctAnswer: "font-weight",
        },
        {
          question: "Ktorá vlastnosť v CSS nastavuje typ písma?",
          options: ["text-align", "font-weight", "font-family", "font-style"],
          correctAnswer: "font-family",
        },
        {
          question: "Ako môžete text zarovnať na stred pomocou CSS?",
          options: [
            "font-align: center;",
            "align-text: center;",
            "text-align: center;",
            "center-text: true;",
          ],
          correctAnswer: "text-align: center;",
        },
        {
          question: "Ktorá CSS vlastnosť nastavuje výšku riadku textu?",
          options: [
            "letter-spacing",
            "line-height",
            "font-size",
            "text-height",
          ],
          correctAnswer: "line-height",
        },
        {
          question: "Ako zmeníte farbu textu v CSS?",
          options: ["font-style", "font-color", "color", "text-color"],
          correctAnswer: "color",
        },
        {
          question: "Ktorá vlastnosť umožňuje textu byť zobrazený ako tučný?",
          options: [
            "font-weight: bold;",
            "font-style: italic;",
            "font-family: bold;",
            "text-style: bold;",
          ],
          correctAnswer: "font-weight: bold;",
        },
        {
          question: "Ako nastavíte kurzívu pomocou CSS?",
          options: [
            "text-transform: italic;",
            "font-weight: italic;",
            "font-style: italic;",
            "text-align: italic;",
          ],
          correctAnswer: "font-style: italic;",
        },
        {
          question: "Čo robí vlastnosť `text-transform: uppercase;`?",
          options: [
            "Prevedie text na veľké písmená",
            "Zvýši veľkosť písma",
            "Zarovná text na vrchol",
            "Prevedie text na malé písmená",
          ],
          correctAnswer: "Prevedie text na veľké písmená",
        },
        {
          question: "Ktorá vlastnosť v CSS určuje priestor medzi písmenami?",
          options: [
            "font-spacing",
            "letter-spacing",
            "line-height",
            "word-spacing",
          ],
          correctAnswer: "letter-spacing",
        },
        {
          question: "Ako môžete text podčiarknuť v CSS?",
          options: [
            "text-decoration: underline;",
            "text-align: underline;",
            "font-weight: underline;",
            "font-style: underline;",
          ],
          correctAnswer: "text-decoration: underline;",
        },
        {
          question: "Ktorá CSS vlastnosť riadi priestor medzi slovami v texte?",
          options: [
            "word-spacing",
            "line-height",
            "text-spacing",
            "letter-spacing",
          ],
          correctAnswer: "word-spacing",
        },
        {
          question: "Ako odstránite podčiarknutie z odkazu v CSS?",
          options: [
            "text-align: none;",
            "font-weight: none;",
            "text-decoration: none;",
            "font-style: normal;",
          ],
          correctAnswer: "text-decoration: none;",
        },
        {
          question: "Čo robí vlastnosť `text-align: justify;`?",
          options: [
            "Zarovná text na stred",
            "Zarovná text na obe strany",
            "Zarovná text na ľavú stranu",
            "Zarovná text na pravú stranu",
          ],
          correctAnswer: "Zarovná text na obe strany",
        },
        {
          question: "Ako nastavíte, aby text nebol zalomený na ďalší riadok?",
          options: [
            "font-style: nowrap;",
            "text-align: nowrap;",
            "white-space: nowrap;",
            "word-wrap: nowrap;",
          ],
          correctAnswer: "white-space: nowrap;",
        },
      ],
      backgroundImage: require("./categories/it/it-css-typography.jpg"),
    },

    {
      name: "CSS colors and backgrounds",
      questions: [
        {
          question: "Aká je správna syntax pre nastavenie farby textu v CSS?",
          options: [
            "text-color: blue;",
            "font-color: blue;",
            "background-color: blue;",
            "color: blue;",
          ],
          correctAnswer: "color: blue;",
        },
        {
          question:
            "Ktorá vlastnosť v CSS slúži na nastavenie farby pozadia elementu?",
          options: [
            "color",
            "font-background",
            "background-color",
            "border-color",
          ],
          correctAnswer: "background-color",
        },
        {
          question: "Ako nastavíte priehľadnosť farby pozadia v CSS?",
          options: [
            "Použitím rgba()",
            "Použitím transparent-color",
            "color-opacity",
            "opacity-color",
          ],
          correctAnswer: "Použitím rgba()",
        },
        {
          question: "Čo znamená hodnota `#ffffff` v CSS?",
          options: [
            "Modrú farbu",
            "Červenú farbu",
            "Bielu farbu",
            "Čiernu farbu",
          ],
          correctAnswer: "Bielu farbu",
        },
        {
          question: "Ako nastavíte obrázok ako pozadie elementu?",
          options: [
            "image: background('image.jpg');",
            "background-image: url('image.jpg');",
            "background: url('image.jpg');",
            "image-background: url('image.jpg');",
          ],
          correctAnswer: "background-image: url('image.jpg');",
        },
        {
          question: "Čo robí vlastnosť `background-repeat`?",
          options: [
            "Nastavuje zarovnanie textu",
            "Opakuje text vo vnútri elementu",
            "Určuje priehľadnosť pozadia",
            "Určuje, či sa obrázok pozadia bude opakovať",
          ],
          correctAnswer: "Určuje, či sa obrázok pozadia bude opakovať",
        },
        {
          question: "Ako nastavíte, aby sa obrázok pozadia neopakoval?",
          options: [
            "background-repeat: no-repeat;",
            "background-image: none;",
            "background-repeat: none;",
            "background: no-repeat;",
          ],
          correctAnswer: "background-repeat: no-repeat;",
        },
        {
          question:
            "Ktorá vlastnosť slúži na nastavenie farby textu vo vnútri elementu?",
          options: ["color", "font-color", "text-color", "background-color"],
          correctAnswer: "color",
        },
        {
          question: "Čo znamená hodnota `transparent` v CSS?",
          options: [
            "Priehľadnú farbu",
            "Modrú farbu",
            "Čiernu farbu",
            "Bielu farbu",
          ],
          correctAnswer: "Priehľadnú farbu",
        },
        {
          question: "Ako nastavíte gradientné pozadie v CSS?",
          options: [
            "background-image: linear-gradient();",
            "gradient-background: linear();",
            "background-gradient: linear();",
            "background: gradient-linear();",
          ],
          correctAnswer: "background-image: linear-gradient();",
        },
        {
          question: "Čo robí vlastnosť `background-position`?",
          options: [
            "Zarovnáva obrázok pozadia",
            "Nastavuje polohu obrázku pozadia v elemente",
            "Určuje, či sa obrázok pozadia opakuje",
            "Nastavuje priehľadnosť obrázku pozadia",
          ],
          correctAnswer: "Nastavuje polohu obrázku pozadia v elemente",
        },
        {
          question:
            "Ako nastavíte farebný prechod od červenej po modrú ako pozadie?",
          options: [
            "background-color: gradient(red, blue);",
            "background-image: linear-gradient(red, blue);",
            "background-gradient: red-to-blue;",
            "background: red-blue-gradient;",
          ],
          correctAnswer: "background-image: linear-gradient(red, blue);",
        },
        {
          question: "Čo robí vlastnosť `background-size`?",
          options: [
            "Určuje veľkosť obrázku pozadia",
            "Určuje, či sa obrázok pozadia opakuje",
            "Nastavuje farbu pozadia",
            "Zarovnáva obrázok pozadia",
          ],
          correctAnswer: "Určuje veľkosť obrázku pozadia",
        },
        {
          question: "Ako nastavíte pozadie na celú veľkosť elementu?",
          options: [
            "background-fit: full;",
            "background-size: contain;",
            "background-size: cover;",
            "background: fill;",
          ],
          correctAnswer: "background-size: cover;",
        },
        {
          question:
            "Ktorá hodnota `background-attachment` spôsobí, že pozadie zostane pevné pri scrollovaní?",
          options: ["sticky", "scroll", "fixed", "static"],
          correctAnswer: "fixed",
        },
      ],
      backgroundImage: require("./categories/it/it-css-colors.png"),
    },

    {
      name: "CSS padding a margin",
      questions: [
        {
          question: "Čo je to `padding` v CSS?",
          options: [
            "Výška elementu",
            "Šírka okraja elementu",
            "Vzdialenosť medzi okrajom elementu a jeho susedmi",
            "Vzdialenosť medzi obsahom elementu a jeho okrajom",
          ],
          correctAnswer: "Vzdialenosť medzi obsahom elementu a jeho okrajom",
        },
        {
          question: "Čo je to `margin` v CSS?",
          options: [
            "Šírka okraja elementu",
            "Výška elementu",
            "Vzdialenosť medzi obsahom elementu a jeho okrajom",
            "Vzdialenosť medzi okrajom elementu a jeho susedmi",
          ],
          correctAnswer: "Vzdialenosť medzi okrajom elementu a jeho susedmi",
        },
        {
          question:
            "Ako nastavíte `padding` pre všetky štyri strany elementu rovnakou hodnotou?",
          options: [
            "padding: 10px;",
            "padding: 10px 10px;",
            "padding: 10px 20px;",
            "padding: 10px 10px 10px 10px;",
          ],
          correctAnswer: "padding: 10px;",
        },
        {
          question:
            "Ako nastavíte `margin` pre iba hornú a dolnú stranu elementu na 15px a bočné strany na 5px?",
          options: [
            "margin: 15px 5px;",
            "margin: 5px 15px;",
            "margin: 15px 15px 5px 5px;",
            "margin: 5px 5px 15px 15px;",
          ],
          correctAnswer: "margin: 15px 5px;",
        },
        {
          question: "Aká je funkcia `margin-top`?",
          options: [
            "Nastavuje vzdialenosť medzi vrchným okrajom elementu a jeho obsahom",
            "Nastavuje vzdialenosť medzi vrchným okrajom elementu a jeho susedmi",
            "Nastavuje vzdialenosť medzi ľavým okrajom elementu a jeho obsahom",
            "Nastavuje vzdialenosť medzi spodným okrajom elementu a jeho susedmi",
          ],
          correctAnswer:
            "Nastavuje vzdialenosť medzi vrchným okrajom elementu a jeho susedmi",
        },
        {
          question:
            "Ako sa označuje vlastnosť pre nastavenie `padding` vpravo?",
          options: [
            "padding-left",
            "padding-top",
            "padding-right",
            "padding-bottom",
          ],
          correctAnswer: "padding-right",
        },
        {
          question: "Ako sa označuje vlastnosť pre nastavenie `margin` vľavo?",
          options: [
            "margin-top",
            "margin-right",
            "margin-left",
            "margin-bottom",
          ],
          correctAnswer: "margin-left",
        },
        {
          question: "Aké hodnoty môžu mať `padding` a `margin`?",
          options: [
            "Len pixely",
            "Pixely, percentá, em, rem",
            "Len percentá",
            "Len em",
          ],
          correctAnswer: "Pixely, percentá, em, rem",
        },
        {
          question:
            "Ako sa nastavuje `padding` pre všetky štyri strany na rôzne hodnoty v jednom riadku?",
          options: [
            "padding: top-right-bottom-left;",
            "padding: top right bottom left;",
            "padding: top right bottom;",
            "padding: top, right, bottom, left;",
          ],
          correctAnswer: "padding: top right bottom left;",
        },
        {
          question:
            "Aká je funkcia `margin` pri nastavovaní na hodnotu `auto`?",
          options: [
            "Nastavuje vzdialenosť medzi obsahom elementu a jeho okrajom",
            "Element bude centrovaný v rámci jeho rodičovského kontajnera",
            "Nastavuje výšku elementu",
            "Zväčšuje šírku okraja elementu",
          ],
          correctAnswer:
            "Element bude centrovaný v rámci jeho rodičovského kontajnera",
        },
        {
          question:
            "Ako nastavíte `margin` pre všetky štyri strany elementu na rôzne hodnoty: 10px pre hornú, 15px pre pravú, 20px pre dolnú a 25px pre ľavú stranu?",
          options: [
            "margin: 10px 15px 20px 25px;",
            "margin: 10px 20px 15px 25px;",
            "margin: 10px 15px 20px;",
            "margin: 10px 15px;",
          ],
          correctAnswer: "margin: 10px 15px 20px 25px;",
        },
        {
          question:
            "Ktoré CSS vlastnosti sú priamo ovplyvnené `padding` a `margin`?",
          options: [
            "Zarovnanie textu",
            "Pozícia a veľkosť elementu",
            "Farba a font textu",
            "Šírka a výška okraja",
          ],
          correctAnswer: "Pozícia a veľkosť elementu",
        },
        {
          question: "Čo sa stane, keď nastavíte `padding` na hodnotu `0`?",
          options: [
            "Element sa zväčší",
            "Nie je to možné nastaviť",
            "Obsah bude tesne pri okraji elementu",
            "Element sa zmenší",
          ],
          correctAnswer: "Obsah bude tesne pri okraji elementu",
        },
        {
          question: "Čo sa stane, keď nastavíte `margin` na hodnotu `auto`?",
          options: [
            "Element sa zväčší",
            "Nastavia sa rovnaké hodnoty pre všetky strany",
            "Element bude centrovaný v rámci jeho rodičovského kontajnera",
            "Element sa zmenší",
          ],
          correctAnswer:
            "Element bude centrovaný v rámci jeho rodičovského kontajnera",
        },
        {
          question: "Aká je funkcia `padding-top`?",
          options: [
            "Nastavuje vzdialenosť medzi vrchným okrajom elementu a jeho obsahom",
            "Nastavuje vzdialenosť medzi vrchným okrajom elementu a jeho susedmi",
            "Nastavuje vzdialenosť medzi ľavým okrajom elementu a jeho obsahom",
            "Nastavuje vzdialenosť medzi spodným okrajom elementu a jeho susedmi",
          ],
          correctAnswer:
            "Nastavuje vzdialenosť medzi vrchným okrajom elementu a jeho obsahom",
        },
        {
          question: "Ako sa nastavuje `margin` pre iba dolnú stranu elementu?",
          options: [
            "margin-left",
            "margin-top",
            "margin-right",
            "margin-bottom",
          ],
          correctAnswer: "margin-bottom",
        },
      ],
      backgroundImage: require("./categories/it/it-css-padding-margin.png"),
    },

    {
      name: "CSS flexbox",
      questions: [
        {
          question: "Čo je to Flexbox v CSS?",
          options: [
            "Súbor vlastností pre zmenu farby pozadia",
            "Systém pre usporiadanie položiek v kontajneri",
            "Technika pre animáciu prvkov",
            "Metóda na zarovnávanie textu",
          ],
          correctAnswer: "Systém pre usporiadanie položiek v kontajneri",
        },
        {
          question:
            "Aká vlastnosť sa používa na nastavenie smeru položiek v kontajneri Flexbox?",
          options: [
            "flex-wrap",
            "justify-content",
            "flex-direction",
            "align-items",
          ],
          correctAnswer: "flex-direction",
        },
        {
          question:
            "Ako nastavíte, aby sa položky Flexbox zarovnávali na stred pozdĺž hlavnej osi?",
          options: [
            "align-items: center;",
            "flex-direction: center;",
            "justify-content: center;",
            "align-content: center;",
          ],
          correctAnswer: "justify-content: center;",
        },
        {
          question: "Čo robí vlastnosť `flex-wrap`?",
          options: [
            "Nastavuje smer hlavnej osi",
            "Zmení orientáciu položiek",
            "Určuje, či sa položky Flexbox budú zalamovať na ďalší riadok",
            "Upravuje výšku položiek Flexbox",
          ],
          correctAnswer:
            "Určuje, či sa položky Flexbox budú zalamovať na ďalší riadok",
        },
        {
          question: "Aké hodnoty môže mať vlastnosť `flex-direction`?",
          options: [
            "flex-start, flex-end, center, space-between",
            "row, column, row-reverse, column-reverse",
            "wrap, nowrap, wrap-reverse",
            "align-items, align-content, align-self",
          ],
          correctAnswer: "row, column, row-reverse, column-reverse",
        },
        {
          question: "Čo je to `flex-grow`?",
          options: [
            "Vlastnosť pre zarovnávanie položiek",
            "Vlastnosť, ktorá určuje, ako sa položky Flexbox zväčšujú, aby vyplnili dostupný priestor",
            "Vlastnosť, ktorá určuje počiatočnú veľkosť položky",
            "Vlastnosť pre nastavenie maximálnej veľkosti položky",
          ],
          correctAnswer:
            "Vlastnosť, ktorá určuje, ako sa položky Flexbox zväčšujú, aby vyplnili dostupný priestor",
        },
        {
          question:
            "Aká vlastnosť sa používa na nastavenie zarovnania položiek Flexbox na priečnej osi?",
          options: [
            "flex-direction",
            "align-items",
            "flex-wrap",
            "justify-content",
          ],
          correctAnswer: "align-items",
        },
        {
          question: "Ako sa nastavuje vlastnosť `flex-basis`?",
          options: [
            "Nastavuje maximálnu veľkosť položky",
            "Nastavuje minimálnu veľkosť položky",
            "Nastavuje spôsob zarovnávania položky",
            "Nastavuje počiatočnú veľkosť položky pred jej prispôsobením",
          ],
          correctAnswer:
            "Nastavuje počiatočnú veľkosť položky pred jej prispôsobením",
        },
        {
          question: "Čo robí vlastnosť `align-content`?",
          options: [
            "Zarovnáva položky na hlavnej osi",
            "Zarovnáva celé riadky položiek Flexbox na priečnej osi",
            "Zarovnáva jednotlivé položky na priečnej osi",
            "Upravuje veľkosť položiek v riadku",
          ],
          correctAnswer:
            "Zarovnáva celé riadky položiek Flexbox na priečnej osi",
        },
        {
          question: "Aké hodnoty môže mať vlastnosť `flex-wrap`?",
          options: [
            "wrap, nowrap, wrap-reverse",
            "row, column, row-reverse, column-reverse",
            "flex-start, flex-end, center, space-between",
            "align-items, align-content, align-self",
          ],
          correctAnswer: "wrap, nowrap, wrap-reverse",
        },
        {
          question: "Čo znamená hodnota `flex: 1`?",
          options: [
            "Položka sa zmenší na minimálnu veľkosť",
            "Položka sa zväčší, aby vyplnila dostupný priestor",
            "Položka bude mať pevne stanovenú veľkosť",
            "Položka nebude mať žiadnu veľkosť",
          ],
          correctAnswer: "Položka sa zväčší, aby vyplnila dostupný priestor",
        },
        {
          question:
            "Ako sa používa vlastnosť `flex` na všetky tri hodnoty (flex-grow, flex-shrink, flex-basis) v jednom riadku?",
          options: [
            "flex: 100px 1 1;",
            "flex: 1 1 auto;",
            "flex: auto 1 1;",
            "flex: 1 1 100px;",
          ],
          correctAnswer: "flex: 1 1 100px;",
        },
        {
          question: "Čo je to `flex-shrink`?",
          options: [
            "Vlastnosť pre nastavenie maximálnej veľkosti položky",
            "Vlastnosť pre nastavenie zarovnávania položiek",
            "Vlastnosť, ktorá určuje počiatočnú veľkosť položky",
            "Vlastnosť, ktorá určuje, ako sa položky Flexbox zmenšujú, keď je málo miesta",
          ],
          correctAnswer:
            "Vlastnosť, ktorá určuje, ako sa položky Flexbox zmenšujú, keď je málo miesta",
        },
        {
          question: "Aká je funkcia `flex` vlastnosti?",
          options: [
            "Nastavuje pozíciu položiek na stránke",
            "Umožňuje nastaviť veľkosť a flexibilitu položiek Flexbox",
            "Upravuje výšku a šírku kontajnera",
            "Nastavuje farbu a štýl textu",
          ],
          correctAnswer:
            "Umožňuje nastaviť veľkosť a flexibilitu položiek Flexbox",
        },
        {
          question: "Ako sa používa vlastnosť `align-self`?",
          options: [
            "Umožňuje nastaviť priestor medzi položkami",
            "Nastavuje zarovnávanie všetkých položiek v riadku",
            "Nastavuje zarovnanie jednotlivých položiek na priečnej osi, nielen celé riadky",
            "Nastavuje veľkosť položky",
          ],
          correctAnswer:
            "Nastavuje zarovnanie jednotlivých položiek na priečnej osi, nielen celé riadky",
        },
      ],
      backgroundImage: require("./categories/it/it-css-flexbox.png"),
    },

    {
      name: "CSS grid",
      questions: [
        {
          question: "Čo je CSS Grid?",
          options: [
            "Štýlovací jazyk pre animácie",
            "Jazyk na manipuláciu s obrázkami",
            "Rámec na usporiadanie položiek do mriežky",
            "Nástroj na úpravu textu",
          ],
          correctAnswer: "Rámec na usporiadanie položiek do mriežky",
        },
        {
          question: "Ako sa definuje grid kontajner v CSS?",
          options: [
            "display: flex;",
            "display: grid;",
            "grid-container: true;",
            "layout: grid;",
          ],
          correctAnswer: "display: grid;",
        },
        {
          question: "Ako sa definuje počet stĺpcov v grid kontajneri?",
          options: [
            "columns: 1fr 2fr;",
            "grid-columns: 1fr 2fr;",
            "grid-template-columns: 1fr 2fr;",
            "grid-template-rows: 1fr 2fr;",
          ],
          correctAnswer: "grid-template-columns: 1fr 2fr;",
        },
        {
          question:
            "Ako sa vytvára riadok s rovnakou veľkosťou položiek v grid kontajneri?",
          options: [
            "rows: repeat(3, 1fr);",
            "grid-template-rows: repeat(3, 1fr);",
            "grid-rows: repeat(3, 1fr);",
            "grid-template-columns: repeat(3, 1fr);",
          ],
          correctAnswer: "grid-template-rows: repeat(3, 1fr);",
        },
        {
          question:
            "Aká vlastnosť sa používa na umiestnenie elementu v mriežke?",
          options: ["grid-align", "grid-item", "grid-area", "grid-position"],
          correctAnswer: "grid-area",
        },
        {
          question: "Ako sa nastaví šírka stĺpca na 200px v grid kontajneri?",
          options: [
            "columns: 200px;",
            "grid-width: 200px;",
            "grid-template-columns: 200px;",
            "grid-columns: 200px;",
          ],
          correctAnswer: "grid-template-columns: 200px;",
        },
        {
          question:
            "Ako sa definuje rozloženie položiek do riadkov a stĺpcov v grid kontajneri?",
          options: [
            "grid-template-areas",
            "grid-layout",
            "grid-template-columns",
            "grid-template-rows",
          ],
          correctAnswer: "grid-template-areas",
        },
        {
          question:
            "Ktorá vlastnosť určuje, ako sa má položka roztiahnuť na šírku alebo výšku v mriežke?",
          options: ["grid-row", "grid-auto-flow", "grid-area", "grid-column"],
          correctAnswer: "grid-column",
        },
        {
          question:
            "Ako sa zmení veľkosť položky v grid kontajneri, aby sa prispôsobila obsahu?",
          options: [
            "rows: auto;",
            "grid-template-rows: auto;",
            "grid-auto-rows: auto;",
            "grid-rows: auto;",
          ],
          correctAnswer: "grid-auto-rows: auto;",
        },
        {
          question: "Čo robí vlastnosť `grid-gap`?",
          options: [
            "Nastavuje veľkosť položiek v grid kontajneri",
            "Definuje medzeru medzi riadkami a stĺpcami v mriežke",
            "Upravuje farbu pozadia položiek",
            "Zarovnáva položky v grid kontajneri",
          ],
          correctAnswer: "Definuje medzeru medzi riadkami a stĺpcami v mriežke",
        },
        {
          question:
            "Ako sa nastaví zarovnanie položky v mriežke do stredu riadku?",
          options: [
            "justify-items: center;",
            "align-self: center;",
            "align-items: center;",
            "justify-self: center;",
          ],
          correctAnswer: "justify-self: center;",
        },
        {
          question:
            "Ako sa nastavia všetky riadky na rovnakú výšku v grid kontajneri?",
          options: [
            "rows: 1fr 1fr 1fr;",
            "grid-auto-rows: 1fr;",
            "grid-template-rows: 1fr 1fr 1fr;",
            "grid-rows: 1fr 1fr 1fr;",
          ],
          correctAnswer: "grid-template-rows: 1fr 1fr 1fr;",
        },
        {
          question:
            "Ako sa definuje explicitný počet riadkov a stĺpcov v CSS Grid?",
          options: [
            "grid-template-columns: repeat(3, 1fr); grid-template-rows: repeat(3, 1fr);",
            "grid-auto-rows: repeat(3, 1fr); grid-auto-columns: repeat(3, 1fr);",
            "rows: repeat(3, 1fr); columns: repeat(3, 1fr);",
            "grid-rows: repeat(3, 1fr); grid-columns: repeat(3, 1fr);",
          ],
          correctAnswer:
            "grid-template-columns: repeat(3, 1fr); grid-template-rows: repeat(3, 1fr);",
        },
        {
          question: "Ako sa nastavuje medzera medzi položkami v mriežke?",
          options: [
            "gap: 10px;",
            "grid-space: 10px;",
            "grid-margin: 10px;",
            "grid-gap: 10px;",
          ],
          correctAnswer: "grid-gap: 10px;",
        },
        {
          question: "Čo robí vlastnosť `grid-auto-flow`?",
          options: [
            "Nastavuje počet riadkov a stĺpcov",
            "Určuje, ako sa automaticky rozmiestňujú položky v mriežke",
            "Definuje medzeru medzi riadkami a stĺpcami",
            "Umožňuje zarovnanie položiek na stred",
          ],
          correctAnswer:
            "Určuje, ako sa automaticky rozmiestňujú položky v mriežke",
        },
      ],
      backgroundImage: require("./categories/it/it-css-grid.png"),
    },

    {
      name: "CSS border",
      questions: [
        {
          question: "Ako sa definuje hrúbka okraja v CSS?",
          options: [
            "border-width: 2px;",
            "border-style: 2px;",
            "border-color: 2px;",
            "border-radius: 2px;",
          ],
          correctAnswer: "border-width: 2px;",
        },
        {
          question: "Ktorá vlastnosť sa používa na nastavenie štýlu okraja?",
          options: [
            "border-color",
            "border-width",
            "border-style",
            "border-radius",
          ],
          correctAnswer: "border-style",
        },
        {
          question: "Ako sa nastavuje farba okraja?",
          options: [
            "border-style: red;",
            "border-color: red;",
            "border-width: red;",
            "border-radius: red;",
          ],
          correctAnswer: "border-color: red;",
        },
        {
          question: "Čo robí vlastnosť `border-radius`?",
          options: [
            "Nastavuje šírku okraja",
            "Nastavuje farbu okraja",
            "Zaobľuje rohy okraja",
            "Mení štýl okraja",
          ],
          correctAnswer: "Zaobľuje rohy okraja",
        },
        {
          question:
            "Ako nastavíte okraj s kombinovaným štýlom, farbou a hrúbkou?",
          options: [
            "border: 1px solid blue;",
            "border-width: 1px solid blue;",
            "border-style: 1px solid blue;",
            "border-color: 1px solid blue;",
          ],
          correctAnswer: "border: 1px solid blue;",
        },
        {
          question: "Ako sa nastaví okraj na 5px s čiernym štýlom?",
          options: [
            "border-width: 5px black;",
            "border-style: 5px black;",
            "border: 5px solid black;",
            "border-color: 5px black;",
          ],
          correctAnswer: "border: 5px solid black;",
        },
        {
          question: "Ako sa nastavuje hrúbka všetkých okrajov na 4px?",
          options: [
            "border-width: 4px;",
            "border-style: 4px;",
            "border-color: 4px;",
            "border-radius: 4px;",
          ],
          correctAnswer: "border-width: 4px;",
        },
        {
          question:
            "Ako môžete nastaviť rôzne hrúbky okrajov pre jednotlivé strany?",
          options: [
            "border-width: 1px 2px 3px 4px;",
            "border-style: 1px 2px 3px 4px;",
            "border-color: 1px 2px 3px 4px;",
            "border-radius: 1px 2px 3px 4px;",
          ],
          correctAnswer: "border-width: 1px 2px 3px 4px;",
        },
        {
          question:
            "Ako sa nastaví okraj pre všetky strany s rovnakou hrúbkou a štýlom?",
          options: [
            "border: 3px dashed green;",
            "border-style: 3px dashed green;",
            "border-color: 3px dashed green;",
            "border-width: 3px dashed green;",
          ],
          correctAnswer: "border: 3px dashed green;",
        },
        {
          question: "Ako sa nastavuje okraj s prerušeným štýlom?",
          options: [
            "border-style: dashed;",
            "border-color: dashed;",
            "border-width: dashed;",
            "border-radius: dashed;",
          ],
          correctAnswer: "border-style: dashed;",
        },
        {
          question: "Ako môžete zaobliť iba horné rohy okraja?",
          options: [
            "border-radius: 10px 10px 0 0;",
            "border-radius: 0 10px 10px 0;",
            "border-radius: 10px;",
            "border-radius: 10px 0 0 10px;",
          ],
          correctAnswer: "border-radius: 10px 10px 0 0;",
        },
        {
          question: "Ako sa nastaví okraj na 2px s pevnou farbou?",
          options: [
            "border-width: 2px solid;",
            "border-style: 2px solid;",
            "border-color: 2px solid;",
            "border: 2px solid black;",
          ],
          correctAnswer: "border: 2px solid black;",
        },
        {
          question: "Ako sa nastaví okraj s hrúbkou 3px a štýlom 'dotted'?",
          options: [
            "border: 3px dotted;",
            "border-width: 3px dotted;",
            "border-style: 3px dotted;",
            "border-color: 3px dotted;",
          ],
          correctAnswer: "border: 3px dotted;",
        },
        {
          question:
            "Ako môžete nastaviť rôzne farby okrajov pre jednotlivé strany?",
          options: [
            "border-color: red green blue yellow;",
            "border-style: red green blue yellow;",
            "border-width: red green blue yellow;",
            "border-radius: red green blue yellow;",
          ],
          correctAnswer: "border-color: red green blue yellow;",
        },
      ],
      backgroundImage: require("./categories/it/it-css-border.jpg"),
    },

    {
      name: "CSS positioning",
      questions: [
        {
          question:
            "Ktorá vlastnosť sa používa na nastavenie typu pozície elementu?",
          options: ["top", "z-index", "position", "left"],
          correctAnswer: "position",
        },
        {
          question: "Aký je predvolený typ pozície pre všetky HTML elementy?",
          options: ["relative", "absolute", "static", "fixed"],
          correctAnswer: "static",
        },
        {
          question: "Čo robí `position: relative;`?",
          options: [
            "Element sa umiestňuje v rámci svojho rodiča",
            "Element sa umiestňuje na miesto podľa iných elementov",
            "Umiestňuje element pevne na miesto vzhľadom na viewport",
            "Umožňuje posun elementu v porovnaní s jeho pôvodnou pozíciou",
          ],
          correctAnswer:
            "Umožňuje posun elementu v porovnaní s jeho pôvodnou pozíciou",
        },
        {
          question:
            "Ako sa definuje absolútna pozícia elementu voči jeho rodičovi?",
          options: [
            "position: absolute;",
            "position: relative;",
            "position: fixed;",
            "position: static;",
          ],
          correctAnswer: "position: absolute;",
        },
        {
          question:
            "Ako sa nastaví element na fixnú pozíciu vzhľadom na viewport?",
          options: [
            "position: fixed;",
            "position: absolute;",
            "position: relative;",
            "position: sticky;",
          ],
          correctAnswer: "position: fixed;",
        },
        {
          question: "Čo robí `z-index`?",
          options: [
            "Definuje šírku okraja elementu",
            "Určuje vrstvenie elementov na osi z",
            "Nastavuje vzdialenosť od okraja elementu",
            "Umiestňuje element v rámci jeho rodiča",
          ],
          correctAnswer: "Určuje vrstvenie elementov na osi z",
        },
        {
          question:
            "Ako sa použije `top: 10px;` v rámci `position: absolute;`?",
          options: [
            "Posunie element o 10px od vrchnej hrany viewportu",
            "Posunie element o 10px od ľavej hrany viewportu",
            "Posunie element o 10px od vrchnej hrany jeho rodiča",
            "Posunie element o 10px od pravej hrany rodiča",
          ],
          correctAnswer: "Posunie element o 10px od vrchnej hrany jeho rodiča",
        },
        {
          question:
            "Ktorá vlastnosť je použitá na nastavenie elementu na 'sticky' pozíciu?",
          options: [
            "position: fixed;",
            "position: sticky;",
            "position: absolute;",
            "position: relative;",
          ],
          correctAnswer: "position: sticky;",
        },
        {
          question:
            "Ako sa nastaví element, aby sa zobrazoval nad ostatnými elementami?",
          options: [
            "z-index: 0;",
            "z-index: -1;",
            "z-index: 1;",
            "z-index: 10;",
          ],
          correctAnswer: "z-index: 10;",
        },
        {
          question:
            "Ako sa použije `left: 20px;` v rámci `position: relative;`?",
          options: [
            "Posunie element o 20px doprava od jeho pôvodnej pozície",
            "Posunie element o 20px od pravej hrany viewportu",
            "Posunie element o 20px od ľavej hrany viewportu",
            "Posunie element o 20px nahor od jeho pôvodnej pozície",
          ],
          correctAnswer:
            "Posunie element o 20px doprava od jeho pôvodnej pozície",
        },
        {
          question:
            "Ako sa nastaví element, aby sa držal na vrchu stránky pri skrolovaní?",
          options: [
            "position: absolute;",
            "position: fixed;",
            "position: sticky;",
            "position: relative;",
          ],
          correctAnswer: "position: fixed;",
        },
        {
          question:
            "Ktorá vlastnosť sa používa na nastavenie východiskovej pozície pre relatívne umiestnené elementy?",
          options: ["top", "position", "transform", "left"],
          correctAnswer: "position",
        },
        {
          question: "Ako sa nastaví okraj na 2px s pevnou farbou?",
          options: [
            "bottom: 0;",
            "left: 50px;",
            "z-index: -1;",
            "position: absolute;",
          ],
          correctAnswer: "bottom: 0;",
        },
        {
          question:
            "Čo je predvolená hodnota `position` pre všetky HTML elementy?",
          options: ["static", "relative", "fixed", "absolute"],
          correctAnswer: "static",
        },
        {
          question:
            "Ako sa nastaví `z-index` pre element, aby sa zobrazoval pod ostatnými elementmi?",
          options: [
            "z-index: 10;",
            "z-index: -1;",
            "z-index: 1;",
            "z-index: 0;",
          ],
          correctAnswer: "z-index: -1;",
        },
        {
          question:
            "Ako sa nastaví element, aby bol posunutý na 50px od ľavej strany rodiča?",
          options: [
            "left: 50px;",
            "top: 50px;",
            "right: 50px;",
            "bottom: 50px;",
          ],
          correctAnswer: "left: 50px;",
        },
      ],
      backgroundImage: require("./categories/it/it-css-position.png"),
    },

    {
      name: "CSS responsive design",
      questions: [
        {
          question: "Čo je cieľom responzívneho dizajnu?",
          options: [
            "Vytváranie statických stránok",
            "Zvýšenie rýchlosti načítania stránky",
            "Optimalizácia dizajnu pre rôzne veľkosti obrazoviek",
            "Nastavenie farby pozadia stránky",
          ],
          correctAnswer: "Optimalizácia dizajnu pre rôzne veľkosti obrazoviek",
        },
        {
          question:
            "Ktorá vlastnosť sa používa na nastavenie šírky prvku v responzívnom dizajne?",
          options: ["border", "padding", "width", "height"],
          correctAnswer: "width",
        },
        {
          question:
            "Ako sa nazýva technika, ktorá umožňuje dizajnérom vytvárať rôzne štýly pre rôzne veľkosti obrazoviek?",
          options: ["Flexbox", "Transforms", "Grid", "Media queries"],
          correctAnswer: "Media queries",
        },
        {
          question: "Aký je účel vlastnosti `max-width` v media queries?",
          options: [
            "Nastavuje minimálnu šírku prvku",
            "Nastavuje maximálnu výšku prvku",
            "Nastavuje maximálnu šírku prvku",
            "Nastavuje minimálnu výšku prvku",
          ],
          correctAnswer: "Nastavuje maximálnu šírku prvku",
        },
        {
          question:
            "Ako sa nastaví základná šírka pre mobilné zariadenia v HTML?",
          options: [
            "<meta name='viewport' content='width=800'>",
            "<meta name='viewport' content='initial-scale=2'>",
            "<meta name='viewport' content='width=device-width, initial-scale=1'>",
            "<meta name='viewport' content='width=1024'>",
          ],
          correctAnswer:
            "<meta name='viewport' content='width=device-width, initial-scale=1'>",
        },
        {
          question:
            "Ktorá vlastnosť sa používa na nastavenie štýlov pre rôzne zariadenia pomocou media queries?",
          options: ["display", "breakpoint", "font-size", "width"],
          correctAnswer: "font-size",
        },
        {
          question: "Ako sa definuje breakpoint v media queries?",
          options: [
            "@media (min-width: 600px) { ... }",
            "@media (width: 600px) { ... }",
            "@media (max-width: 600px) { ... }",
            "@media (height: 600px) { ... }",
          ],
          correctAnswer: "@media (max-width: 600px) { ... }",
        },
        {
          question: "Čo robí vlastnosť `flex-wrap` v rámci flexboxu?",
          options: [
            "Nastavuje výšku flex položiek",
            "Nastavuje šírku flex položiek",
            "Umiestňuje flex položky na stred",
            "Určuje, či sa flex položky zabalí do ďalších riadkov",
          ],
          correctAnswer: "Určuje, či sa flex položky zabalí do ďalších riadkov",
        },
        {
          question:
            "Ktorá vlastnosť sa používa na nastavenie hlavného osového zarovnania v flexboxe?",
          options: [
            "flex-direction",
            "align-content",
            "align-items",
            "justify-content",
          ],
          correctAnswer: "justify-content",
        },
        {
          question: "Čo je to fluidný dizajn?",
          options: [
            "Dizajn s nemennou šírkou",
            "Dizajn, ktorý sa prispôsobuje rôznym veľkostiam obrazoviek",
            "Dizajn bez akýchkoľvek médií",
            "Dizajn s pevne stanovenými rozmermi",
          ],
          correctAnswer:
            "Dizajn, ktorý sa prispôsobuje rôznym veľkostiam obrazoviek",
        },
        {
          question: "Ako sa používa vlastnosť `min-width` v media queries?",
          options: [
            "Aplikuje štýly, keď je šírka obrazovky maximálna alebo menšia",
            "Nastavuje minimálnu výšku elementu",
            "Aplikuje štýly podľa výšky obrazovky",
            "Aplikuje štýly, keď je šírka obrazovky minimálna alebo väčšia",
          ],
          correctAnswer:
            "Aplikuje štýly, keď je šírka obrazovky minimálna alebo väčšia",
        },
        {
          question: "Čo je to grid system?",
          options: [
            "Systém pre nastavenie farieb",
            "Systém na animácie a transformácie",
            "Systém pre flexibilné prispôsobenie veľkosti fontu",
            "Systém rozloženia založený na mriežke",
          ],
          correctAnswer: "Systém rozloženia založený na mriežke",
        },
        {
          question:
            "Ktorá vlastnosť v rámci CSS Grid sa používa na nastavenie veľkosti stĺpcov?",
          options: [
            "grid-column",
            "grid-template-rows",
            "grid-template-columns",
            "grid-area",
          ],
          correctAnswer: "grid-template-columns",
        },
        {
          question:
            "Ako sa nastaví vlastnosť `aspect-ratio` pre udržanie pomeru strán v CSS?",
          options: [
            "aspect-ratio: 2 / 1;",
            "aspect-ratio: 16 / 9;",
            "aspect-ratio: 1 / 1;",
            "aspect-ratio: 9 / 16;",
          ],
          correctAnswer: "aspect-ratio: 16 / 9;",
        },
      ],
      backgroundImage: require("./categories/it/it-css-responsive.jpg"),
    },

    {
      name: "CSS transitions",
      questions: [
        {
          question: "Čo je to CSS transition?",
          options: [
            "Statická zmena farby textu",
            "Animácia, ktorá prechádza plynule z jednej hodnoty do druhej",
            "Nastavenie pevnej šírky prvku",
            "Vytvorenie mriežkového rozloženia",
          ],
          correctAnswer:
            "Animácia, ktorá prechádza plynule z jednej hodnoty do druhej",
        },
        {
          question:
            "Ktorá vlastnosť určuje, aké vlastnosti sa majú počas prechodu meniť?",
          options: [
            "transition-duration",
            "transition-property",
            "transition-delay",
            "transition-timing-function",
          ],
          correctAnswer: "transition-property",
        },
        {
          question:
            "Aká je predvolená hodnota pre vlastnosť `transition-timing-function`?",
          options: ["ease", "ease-in", "linear", "ease-out"],
          correctAnswer: "ease",
        },
        {
          question: "Aká vlastnosť určuje, ako dlho trvá prechod?",
          options: [
            "transition-timing-function",
            "transition-duration",
            "transition-property",
            "transition-delay",
          ],
          correctAnswer: "transition-duration",
        },
        {
          question: "Ktorá vlastnosť určuje oneskorenie začiatku prechodu?",
          options: [
            "transition-property",
            "transition-timing-function",
            "transition-delay",
            "transition-duration",
          ],
          correctAnswer: "transition-delay",
        },
        {
          question: "Ako sa nastavuje trvanie prechodu na 2 sekundy?",
          options: [
            "transition-duration: 200ms;",
            "transition-duration: 2ms;",
            "transition-duration: 2s;",
            "transition-duration: 0.5s;",
          ],
          correctAnswer: "transition-duration: 2s;",
        },
        {
          question:
            "Ako sa definuje prechod s hladkým zrýchlením a spomalením?",
          options: [
            "transition-timing-function: linear;",
            "transition-timing-function: ease;",
            "transition-timing-function: ease-out;",
            "transition-timing-function: ease-in;",
          ],
          correctAnswer: "transition-timing-function: ease;",
        },
        {
          question:
            "Ktorá vlastnosť sa používa na nastavenie prechodu pre `background-color`?",
          options: [
            "transition-property: height;",
            "transition-property: color;",
            "transition-property: background-color;",
            "transition-property: width;",
          ],
          correctAnswer: "transition-property: background-color;",
        },
        {
          question: "Čo robí vlastnosť `transition` v CSS?",
          options: [
            "Nastavuje pozíciu elementu",
            "Mení farbu pozadia",
            "Upravuje veľkosť fontu",
            "Kombinuje všetky prechodové vlastnosti do jednej skratky",
          ],
          correctAnswer:
            "Kombinuje všetky prechodové vlastnosti do jednej skratky",
        },
        {
          question: "Ako sa definuje prechod s oneskorením 0.5 sekundy?",
          options: [
            "transition-delay: 500ms;",
            "transition-duration: 0.5s;",
            "transition-duration: 500ms;",
            "transition-delay: 0.5s;",
          ],
          correctAnswer: "transition-delay: 0.5s;",
        },
        {
          question:
            "Ktorý z týchto predvolených efektov sa používa na hladký prechod?",
          options: ["ease-in", "linear", "ease-out", "ease"],
          correctAnswer: "ease",
        },
        {
          question: "Ako sa nastavuje prechod pre `width` a `height` súčasne?",
          options: [
            "transition-property: width, height;",
            "transition-property: height;",
            "transition-property: all;",
            "transition-property: width;",
          ],
          correctAnswer: "transition-property: width, height;",
        },
        {
          question:
            "Ako sa nastaví prechod s rovnomernou rýchlosťou počas celej doby trvania?",
          options: [
            "transition-timing-function: ease-in;",
            "transition-timing-function: ease-out;",
            "transition-timing-function: ease;",
            "transition-timing-function: linear;",
          ],
          correctAnswer: "transition-timing-function: linear;",
        },
      ],
      backgroundImage: require("./categories/it/it-css-transitions.png"),
    },

    {
      name: "CSS animations",
      questions: [
        {
          question: "Čo je to CSS animácia?",
          options: [
            "Statická zmena farby pozadia",
            "Kombinácia rôznych vlastností v jednej deklarácii",
            "Efekt, ktorý plynulo mení vlastnosti prvku počas časového intervalu",
            "Vytvorenie mriežkového rozloženia",
          ],
          correctAnswer:
            "Efekt, ktorý plynulo mení vlastnosti prvku počas časového intervalu",
        },
        {
          question: "Ktorá vlastnosť sa používa na definovanie animácie v CSS?",
          options: [
            "animation-name",
            "animation-timing-function",
            "animation-duration",
            "animation-delay",
          ],
          correctAnswer: "animation-name",
        },
        {
          question: "Ako sa definuje dĺžka animácie na 3 sekundy?",
          options: [
            "animation-duration: 3s;",
            "animation-duration: 300ms;",
            "animation-duration: 3ms;",
            "animation-duration: 30s;",
          ],
          correctAnswer: "animation-duration: 3s;",
        },
        {
          question: "Čo robí vlastnosť `animation-timing-function`?",
          options: [
            "Definuje dĺžku animácie",
            "Upravuje oneskorenie začiatku animácie",
            "Určuje spôsob zmeny rýchlosti animácie počas jej trvania",
            "Nastavuje meno animácie",
          ],
          correctAnswer:
            "Určuje spôsob zmeny rýchlosti animácie počas jej trvania",
        },
        {
          question: "Ako sa nastavuje oneskorenie animácie na 1 sekundu?",
          options: [
            "animation-delay: 1s;",
            "animation-delay: 1000ms;",
            "animation-duration: 1s;",
            "animation-duration: 1000ms;",
          ],
          correctAnswer: "animation-delay: 1s;",
        },
        {
          question: "Čo je to `@keyframes` v CSS?",
          options: [
            "Vlastnosť, ktorá určuje oneskorenie animácie",
            "Definícia animácie, ktorá určuje, aké vlastnosti sa majú meniť",
            "Vlastnosť, ktorá nastavuje dĺžku animácie",
            "Vlastnosť, ktorá určuje spôsob zmeny rýchlosti animácie",
          ],
          correctAnswer:
            "Definícia animácie, ktorá určuje, aké vlastnosti sa majú meniť",
        },
        {
          question: "Ktorá vlastnosť určuje, ako často sa animácia opakuje?",
          options: [
            "animation-iteration-count",
            "animation-timing-function",
            "animation-name",
            "animation-duration",
          ],
          correctAnswer: "animation-iteration-count",
        },
        {
          question: "Ako sa definuje animácia, ktorá sa opakuje nekonečne?",
          options: [
            "animation-iteration-count: infinite;",
            "animation-duration: infinite;",
            "animation-delay: infinite;",
            "animation-timing-function: infinite;",
          ],
          correctAnswer: "animation-iteration-count: infinite;",
        },
        {
          question:
            "Ako sa definuje animácia s názvom `fade` a trvaním 2 sekundy?",
          options: [
            "animation: fade 2s;",
            "animation: fade 2ms;",
            "animation: 2s fade;",
            "animation: 2s fade infinite;",
          ],
          correctAnswer: "animation: fade 2s;",
        },
        {
          question:
            "Ktorá vlastnosť určuje, čo sa stane s animáciou po jej dokončení?",
          options: [
            "animation-fill-mode",
            "animation-iteration-count",
            "animation-timing-function",
            "animation-delay",
          ],
          correctAnswer: "animation-fill-mode",
        },
        {
          question:
            "Ako sa definuje animácia, ktorá sa vykonáva raz a potom sa zastaví?",
          options: [
            "animation-iteration-count: 1;",
            "animation-duration: 1;",
            "animation-name: 1;",
            "animation-delay: 1;",
          ],
          correctAnswer: "animation-iteration-count: 1;",
        },
        {
          question:
            "Ako sa používa vlastnosť `animation` na definovanie všetkých aspektov animácie?",
          options: [
            "animation: name duration timing-function delay iteration-count;",
            "animation: duration name timing-function delay iteration-count;",
            "animation: name timing-function duration delay iteration-count;",
            "animation: name duration delay timing-function iteration-count;",
          ],
          correctAnswer:
            "animation: name duration timing-function delay iteration-count;",
        },
        {
          question: "Ako sa definuje animácia, ktorá sa opakuje dvakrát?",
          options: [
            "animation-iteration-count: 2;",
            "animation-duration: 2;",
            "animation-name: 2;",
            "animation-delay: 2;",
          ],
          correctAnswer: "animation-iteration-count: 2;",
        },
      ],
      backgroundImage: require("./categories/it/it-css-animations.jpg"),
    },

    {
      name: "CSS keyframes",
      questions: [
        {
          question: "Čo je to '@keyframes' v CSS?",
          options: [
            "Používa sa na určenie veľkosti písma",
            "Používa sa na nastavenie pozadia",
            "Používa sa na vytvorenie animácií",
            "Používa sa na zmenu farby textu",
          ],
          correctAnswer: "Používa sa na vytvorenie animácií",
        },
        {
          question: "Ako sa definuje '@keyframes' animácia?",
          options: [
            "@keyframes { start { ... } stop { ... } }",
            "@keyframes { from { ... } to { ... } }",
            "transform: rotate(45deg);",
            "animation: name duration;",
          ],
          correctAnswer: "@keyframes { from { ... } to { ... } }",
        },
        {
          question: "Ktorá vlastnosť aplikuje definovanú animáciu na element?",
          options: ["transform", "keyframe", "animation", "transition"],
          correctAnswer: "animation",
        },
        {
          question:
            "Čo znamená hodnota 'infinite' pre vlastnosť 'animation-iteration-count'?",
          options: [
            "Animácia sa zastaví po 3 opakovaniach",
            "Animácia sa bude opakovať neustále",
            "Animácia sa pozastaví po prvom kroku",
            "Animácia sa spustí len raz",
          ],
          correctAnswer: "Animácia sa bude opakovať neustále",
        },
        {
          question: "Čo určuje vlastnosť 'animation-duration'?",
          options: [
            "Čas medzi jednotlivými krokmi animácie",
            "Kedy animácia skončí",
            "Trvanie jednej iterácie animácie",
            "Čas, po ktorom sa animácia začne",
          ],
          correctAnswer: "Trvanie jednej iterácie animácie",
        },
        {
          question: "Čo robí vlastnosť 'animation-delay'?",
          options: [
            "Nastavuje poradie animácie",
            "Mení rýchlosť animácie",
            "Nastavuje priehľadnosť",
            "Definuje časový posun pred začiatkom animácie",
          ],
          correctAnswer: "Definuje časový posun pred začiatkom animácie",
        },
        {
          question: "Ktorá vlastnosť definuje priebeh animácie?",
          options: [
            "animation-iteration-count",
            "animation-delay",
            "animation-name",
            "animation-timing-function",
          ],
          correctAnswer: "animation-timing-function",
        },
        {
          question:
            "Aký význam má 'ease-in-out' pre 'animation-timing-function'?",
          options: [
            "Animácia sa pohybuje rovnomerne",
            "Animácia začne rýchlo a potom spomalí",
            "Animácia sa spomalí na začiatku aj na konci",
            "Animácia sa zastaví po každom kroku",
          ],
          correctAnswer: "Animácia sa spomalí na začiatku aj na konci",
        },
        {
          question: "Čo robí vlastnosť 'animation-fill-mode: forwards'?",
          options: [
            "Element zostane v stave animácie po jej skončení",
            "Zvyšuje rýchlosť animácie",
            "Animácia sa opakuje spätne",
            "Znižuje rýchlosť animácie na polovicu",
          ],
          correctAnswer: "Element zostane v stave animácie po jej skončení",
        },
        {
          question: "Čo znamená hodnota 'alternate' pre 'animation-direction'?",
          options: [
            "Animácia začne v opačnom smere",
            "Animácia sa bude striedavo prehrávať dopredu a späť",
            "Animácia sa vždy spustí od začiatku",
            "Animácia sa prehrá iba raz",
          ],
          correctAnswer: "Animácia sa bude striedavo prehrávať dopredu a späť",
        },
      ],
      backgroundImage: require("./categories/it/it-css-keyframes.webp"),
    },

    {
      name: "Základy JavaScriptu",
      questions: [
        {
          question:
            "Aký je správny spôsob, ako deklarovať premennú v JavaScripte?",
          options: [
            "var myVariable;",
            "let myVariable;",
            "const myVariable;",
            "Všetky vyššie uvedené",
          ],
          correctAnswer: "Všetky vyššie uvedené",
        },
        {
          question: "Čo je to funkcia v JavaScripte?",
          options: [
            "Premenná, ktorá uchováva hodnoty",
            "Objekt s preddefinovanými metódami",
            "Blok kódu, ktorý vykonáva určitú úlohu",
            "Typ údajov",
          ],
          correctAnswer: "Blok kódu, ktorý vykonáva určitú úlohu",
        },
        {
          question:
            "Ako sa volá metóda na pridanie elementu do poľa v JavaScripte?",
          options: ["add()", "append()", "push()", "insert()"],
          correctAnswer: "push()",
        },
        {
          question:
            "Ako sa získava hodnota vstupného poľa pomocou JavaScriptu?",
          options: [
            "document.querySelector('#inputField').text",
            "document.getElementById('inputField').value",
            "document.querySelector('#inputField').value",
            "document.getElementById('inputField').textContent",
          ],
          correctAnswer: "document.getElementById('inputField').value",
        },
        {
          question: "Aký je účel metódy `parseInt()` v JavaScripte?",
          options: [
            "Získať desatinnú časť čísla",
            "Previesť celé číslo na reťazec",
            "Previesť reťazec na celé číslo",
            "Zaokrúhliť číslo na najbližšie celé číslo",
          ],
          correctAnswer: "Previesť reťazec na celé číslo",
        },
        {
          question: "Ako vytvoríte objekt v JavaScripte?",
          options: [
            "const myObject = ();",
            "const myObject = [];",
            "const myObject = new Object();",
            "const myObject = {}; ",
          ],
          correctAnswer: "const myObject = {}; ",
        },
        {
          question: "Čo robí metóda `console.log()` v JavaScripte?",
          options: [
            "Zobrazí text v konzole pre vývojárov",
            "Otvára dialógové okno s textom",
            "Zobrazí text na obrazovke",
            "Uloží text do súboru",
          ],
          correctAnswer: "Zobrazí text v konzole pre vývojárov",
        },
        {
          question: "Ako zistíte dĺžku poľa v JavaScripte?",
          options: ["array.count", "array.length", "array.size", "array.width"],
          correctAnswer: "array.length",
        },
        {
          question: "Aký je rozdiel medzi `==` a `===` v JavaScripte?",
          options: [
            "`==` porovnáva hodnoty a typy, zatiaľ čo `===` porovnáva iba hodnoty",
            "`==` porovnáva hodnoty, zatiaľ čo `===` porovnáva hodnoty a typy",
            "`==` a `===` sú rovnaké a nemajú žiadny rozdiel",
            "`==` sa používa pre reťazce, zatiaľ čo `===` pre čísla",
          ],
          correctAnswer:
            "`==` porovnáva hodnoty, zatiaľ čo `===` porovnáva hodnoty a typy",
        },
        {
          question: "Ako sa vytvára nová funkcia v JavaScripte?",
          options: [
            "createFunction myFunction() {}",
            "function: myFunction() {}",
            "function myFunction() {}",
            "define myFunction() {}",
          ],
          correctAnswer: "function myFunction() {}",
        },
        {
          question: "Čo je to `event` v kontexte JavaScriptu?",
          options: [
            "Nástroj na ladenie kódu",
            "Typ objektu v JavaScripte",
            "Hodnota premennej",
            "Kód, ktorý sa vykonáva po kliknutí na tlačidlo",
          ],
          correctAnswer: "Kód, ktorý sa vykonáva po kliknutí na tlačidlo",
        },
        {
          question:
            "Aká je správna syntax pre podmienkový blok `if` v JavaScripte?",
          options: [
            "if { condition } // code",
            "if (condition) // code",
            "if (condition) { // code }",
            "if condition { // code }",
          ],
          correctAnswer: "if (condition) { // code }",
        },
        {
          question: "Ako sa v JavaScripte vytvára pole s prvkami?",
          options: [
            "const myArray = new Array(1, 2, 3);",
            "const myArray = (1, 2, 3);",
            "const myArray = {1, 2, 3};",
            "const myArray = [1, 2, 3];",
          ],
          correctAnswer: "const myArray = [1, 2, 3];",
        },
        {
          question: "Čo je to `DOM` v JavaScripte?",
          options: [
            "Digital Operating Machine, technológia na správu databáz",
            "Document Output Module, komponent na tlač dokumentov",
            "Data Object Model, objektový model pre spracovanie dát",
            "Document Object Model, reprezentácia HTML dokumentu",
          ],
          correctAnswer: "Document Object Model, reprezentácia HTML dokumentu",
        },
        {
          question: "Ako môžeme v JavaScripte odstrániť posledný prvok z poľa?",
          options: [
            "array.removeLast()",
            "array.pop()",
            "array.delete()",
            "array.shift()",
          ],
          correctAnswer: "array.pop()",
        },
        {
          question: "Aký je účel operátora `typeof` v JavaScripte?",
          options: [
            "Zistí typ dátovej štruktúry",
            "Zistí, či je premenná prázdna",
            "Zistí, či je premenná definovaná",
            "Zistí typ hodnoty premennej",
          ],
          correctAnswer: "Zistí typ hodnoty premennej",
        },
      ],
      backgroundImage: require("./categories/it/it-js-zaklady.jpg"),
    },

    {
      name: "Základné funkcie v javascripte - praktické programovanie",
      questions: [
        {
          question: "Ktorá z nasledujúcich funkcií vráti súčet dvoch čísel?",
          options: [
            "function sum(a, b) { console.log(a + b); }",
            "function add(a, b) { return a + b; }",
            "function sum(a, b) { return a * b; }",
            "function add(a, b) { return a - b; }",
          ],
          correctAnswer: "function add(a, b) { return a + b; }",
        },
        {
          question:
            "Aká je správna syntax pre deklarovanie anonymnej funkcie v javascripte?",
          options: [
            "let func = function() {};",
            "function() {};",
            "let func = () => {};",
            "function func() {};",
          ],
          correctAnswer: "let func = function() {};",
        },
        {
          question:
            "Čo vráti nasledujúca funkcia? function greet() { return 'Hello'; }",
          options: ["undefined", "'Hello'", "null", "greet"],
          correctAnswer: "'Hello'",
        },
        {
          question:
            "Čo robí nasledujúca funkcia? function multiply(a, b) { return a * b; }",
          options: [
            "Sčítava dve čísla",
            "Násobí dve čísla",
            "Deli dve čísla",
            "Odčíta dve čísla",
          ],
          correctAnswer: "Násobí dve čísla",
        },
        {
          question: "Aká je správna syntax pre volanie funkcie v javascripte?",
          options: [
            "call myFunction();",
            "myFunction();",
            "run myFunction();",
            "execute myFunction();",
          ],
          correctAnswer: "myFunction();",
        },
        {
          question:
            "Čo vráti nasledujúci kód? function divide(a, b) { return a / b; } divide(10, 2);",
          options: ["5", "2", "20", "0"],
          correctAnswer: "5",
        },
        {
          question: "Ako v javascripte deklarujeme funkciu s názvom 'hello'?",
          options: [
            "Všetky vyššie uvedené",
            "let hello = function() {};",
            "function hello() {};",
            "let hello = () => {};",
          ],
          correctAnswer: "Všetky vyššie uvedené",
        },
        {
          question: "Čo robí return vo funkcii v javascripte?",
          options: [
            "Vráti hodnotu a ukončí funkciu",
            "Len ukončí funkciu",
            "Vráti hodnotu, ale neukončí funkciu",
            "Vypíše hodnotu do konzoly",
          ],
          correctAnswer: "Vráti hodnotu a ukončí funkciu",
        },
        {
          question: "Čo sa stane, ak vo funkcii chýba return?",
          options: [
            "Funkcia vypíše chybu",
            "Funkcia vráti null",
            "Funkcia vráti undefined",
            "Funkcia sa nezavolá",
          ],
          correctAnswer: "Funkcia vráti undefined",
        },
        {
          question:
            "Ktorý z nasledujúcich kódov predstavuje funkciu s defaultnou hodnotou pre parameter?",
          options: [
            "function greet(name = 'Guest') { return 'Hello ' + name; }",
            "let greet = (name) => 'Hello ' + name;",
            "function greet(name) { return 'Hello ' + name; }",
            "function greet(name) { return 'Hello ' + 'Guest'; }",
          ],
          correctAnswer:
            "function greet(name = 'Guest') { return 'Hello ' + name; }",
        },
        {
          question: "Ako môžeme získať počet argumentov odovzdaných funkcii?",
          options: [
            "arguments.length",
            "function.arguments.length",
            "args.length",
            "function.args.length",
          ],
          correctAnswer: "arguments.length",
        },
        {
          question:
            "Čo vráti nasledujúci kód? function greet(name) { return name || 'Guest'; } greet();",
          options: ["'Guest'", "''", "null", "undefined"],
          correctAnswer: "'Guest'",
        },
        {
          question: "Čo je to IIFE (Immediately Invoked Function Expression)?",
          options: [
            "Funkcia, ktorá sa okamžite spustí po deklarácii",
            "Funkcia, ktorá sa nespustí automaticky",
            "Funkcia, ktorá sa spustí po načítaní stránky",
            "Funkcia, ktorá sa spustí po kliknutí",
          ],
          correctAnswer: "Funkcia, ktorá sa okamžite spustí po deklarácii",
        },
        {
          question: "Ako deklarujeme arrow funkciu v javascripte?",
          options: [
            "let func = () => {};",
            "let func = function() {};",
            "function func() {};",
            "let func = -> {};",
          ],
          correctAnswer: "let func = () => {};",
        },
        {
          question: "Čo je to callback funkcia v javascripte?",
          options: [
            "Funkcia, ktorá sa volá neskôr",
            "Funkcia, ktorá sa spustí pred inou funkciou",
            "Funkcia, ktorá je odovzdaná ako argument do inej funkcie",
            "Funkcia, ktorá sa spustí na pozadí",
          ],
          correctAnswer:
            "Funkcia, ktorá je odovzdaná ako argument do inej funkcie",
        },
      ],
      backgroundImage: require("./categories/it/it-js-functions.jpg"),
    },

    {
      name: "Test na premenné v JavaScripte",
      questions: [
        {
          question: "Čo je premenná v JavaScripte?",
          options: [
            "Úložisko pre hodnotu, ktorá sa môže meniť počas behu programu",
            "Funkcia, ktorá vracia hodnotu",
            "Konštanta, ktorá sa nemení",
            "Element HTML stránky",
          ],
          correctAnswer:
            "Úložisko pre hodnotu, ktorá sa môže meniť počas behu programu",
        },
        {
          question: "Ako deklaruješ premennú v JavaScripte?",
          options: [
            "var menoPremennej;",
            "premenna menoPremennej;",
            "vytvor premennu menoPremennej;",
            "create menoPremennej;",
          ],
          correctAnswer: "var menoPremennej;",
        },
        {
          question: "Čo znamená 'let' v JavaScripte?",
          options: [
            "Deklaruje premennú s blokovým rozsahom (block scope)",
            "Deklaruje globálnu premennú",
            "Deklaruje premennú, ktorá sa nikdy nemení",
            "Deklaruje funkciu",
          ],
          correctAnswer: "Deklaruje premennú s blokovým rozsahom (block scope)",
        },
        {
          question: "Aký je rozdiel medzi 'let' a 'const'?",
          options: [
            "'let' umožňuje meniť hodnotu, 'const' nie",
            "'const' sa používa len pre čísla, 'let' pre reťazce",
            "'let' má globálny rozsah, 'const' má blokový rozsah",
            "'const' umožňuje meniť hodnotu, 'let' nie",
          ],
          correctAnswer: "'let' umožňuje meniť hodnotu, 'const' nie",
        },
        {
          question: "Akú hodnotu má nedefinovaná premenná?",
          options: ["undefined", "null", "0", "false"],
          correctAnswer: "undefined",
        },
        {
          question: "Ako sa inicializuje premenná v JavaScripte?",
          options: [
            "Priradením hodnoty: let x = 5;",
            "Pridaním kľúčového slova: init x = 5;",
            "Použitím funkcie: initialize(x, 5);",
            "Automaticky po deklarácii bez potreby ďalších krokov",
          ],
          correctAnswer: "Priradením hodnoty: let x = 5;",
        },
        {
          question:
            "Čo sa stane, ak sa pokúsiš deklarovať premennú dvakrát s 'let'?",
          options: [
            "Program vyhodí chybu",
            "Premenná bude premenovaná automaticky",
            "Hodnota premennej bude aktualizovaná",
            "Premenná bude prepísaná bez chyby",
          ],
          correctAnswer: "Program vyhodí chybu",
        },
        {
          question:
            "Čo spôsobí deklarácia premennej pomocou 'var' v rámci funkcie?",
          options: [
            "Premenná bude mať lokálny rozsah v rámci funkcie",
            "Premenná bude dostupná globálne",
            "Premenná bude automaticky inicializovaná na 'null'",
            "Premenná bude mať blokový rozsah",
          ],
          correctAnswer: "Premenná bude mať lokálny rozsah v rámci funkcie",
        },
        {
          question:
            "Ktoré z nasledujúcich tvrdení je správne o premenných deklarovaných s 'var'?",
          options: [
            "Majú globálny alebo funkčný rozsah",
            "Majú blokový rozsah",
            "Sú automaticky konštanty",
            "Musia byť inicializované pri deklarácii",
          ],
          correctAnswer: "Majú globálny alebo funkčný rozsah",
        },
        {
          question: "Kedy používaš 'const' pre deklaráciu premennej?",
          options: [
            "Keď vieš, že hodnota sa nebude meniť",
            "Keď chceš, aby bola premenná globálna",
            "Keď chceš inicializovať premennú neskôr",
            "Keď potrebuješ dynamickú premennú",
          ],
          correctAnswer: "Keď vieš, že hodnota sa nebude meniť",
        },
        {
          question:
            "Čo sa stane, ak sa pokúsiš zmeniť hodnotu premennej deklarovanej s 'const'?",
          options: [
            "Program vyhodí chybu",
            "Premenná bude prepísaná",
            "Premenná bude ignorovaná",
            "Premenná sa zmení bez chyby",
          ],
          correctAnswer: "Program vyhodí chybu",
        },
        {
          question:
            "Ktorá z týchto možností správne deklaruje premennú s 'let'?",
          options: [
            "let meno = 'Matej';",
            "const meno = Matej;",
            "var meno = 'Matej';",
            "prem meno = 'Matej';",
          ],
          correctAnswer: "let meno = 'Matej';",
        },
        {
          question:
            "Čo sa stane, ak použiješ premennú pred jej deklaráciou s 'let'?",
          options: [
            "Program vyhodí chybu",
            "Premenná bude mať hodnotu 'undefined'",
            "Premenná bude mať hodnotu 'null'",
            "Premenná bude automaticky inicializovaná",
          ],
          correctAnswer: "Program vyhodí chybu",
        },
        {
          question:
            "Aký je výsledok nasledujúceho kódu: 'var x = 10; { var x = 20; } console.log(x);'?",
          options: ["20", "10", "undefined", "Chyba pri behu programu"],
          correctAnswer: "20",
        },
        {
          question: "Aký je rozdiel medzi 'var' a 'let' z hľadiska rozsahu?",
          options: [
            "'var' má funkčný rozsah, 'let' má blokový rozsah",
            "'var' má blokový rozsah, 'let' má globálny rozsah",
            "'var' má len globálny rozsah, 'let' má funkčný rozsah",
            "'var' a 'let' majú rovnaký rozsah",
          ],
          correctAnswer: "'var' má funkčný rozsah, 'let' má blokový rozsah",
        },
      ],
      backgroundImage: require("./categories/it/it-js-variables.jpg"),
    },

    {
      name: "Test na syntax JavaScriptu",
      questions: [
        {
          question: "Ako deklaruješ premennú v JavaScripte?",
          options: [
            "variable x = 10;",
            "var x = 10;",
            "let = x 10;",
            "x := 10;",
          ],
          correctAnswer: "var x = 10;",
        },
        {
          question: "Čo vráti nasledujúci kód: `typeof null`?",
          options: ["'object'", "'null'", "'undefined'", "'boolean'"],
          correctAnswer: "'object'",
        },
        {
          question: "Ako sa deklaruje funkcia v JavaScripte?",
          options: [
            "function: myFunction() {}",
            "function myFunction() {}",
            "def myFunction() {}",
            "fn myFunction() => {}",
          ],
          correctAnswer: "function myFunction() {}",
        },
        {
          question: "Čo znamená kľúčové slovo `this` v JavaScripte?",
          options: [
            "Odkazuje na aktuálny objekt",
            "Je to lokálna premenná",
            "Odkazuje na rodičovskú triedu",
            "Je to konštanta",
          ],
          correctAnswer: "Odkazuje na aktuálny objekt",
        },
        {
          question: "Aký bude výsledok nasledujúceho kódu: `2 + '2'`?",
          options: ["'22'", "4", "NaN", "undefined"],
          correctAnswer: "'22'",
        },
        {
          question: "Ako pristúpiš k prvému prvku poľa v JavaScripte?",
          options: ["array[1]", "array(0)", "array[0]", "array.first()"],
          correctAnswer: "array[0]",
        },
        {
          question:
            "Ako môžeš spustiť kód po určitej časovej lehote v JavaScripte?",
          options: [
            "timeout(function, delay);",
            "setTimeout(function, delay);",
            "setTime(function, delay);",
            "delay(function, time);",
          ],
          correctAnswer: "setTimeout(function, delay);",
        },
        {
          question:
            "Aký je výstup nasledujúceho kódu: `console.log(0 == '0')`?",
          options: ["false", "NaN", "true", "undefined"],
          correctAnswer: "true",
        },
        {
          question:
            "Ktoré kľúčové slovo slúži na vytvorenie konštanty v JavaScripte?",
          options: ["constant", "let", "const", "var"],
          correctAnswer: "const",
        },
        {
          question: "Čo robí `Array.prototype.map()` v JavaScripte?",
          options: [
            "Vytvorí nové pole na základe podmienky",
            "Vráti index daného prvku v poli",
            "Vytvorí nové pole s transformovanými prvkami",
            "Zredukuje pole na jeden prvok",
          ],
          correctAnswer: "Vytvorí nové pole s transformovanými prvkami",
        },
        {
          question: "Ako vieš pridať nový prvok na koniec poľa v JavaScripte?",
          options: [
            "array.add(element);",
            "array.push(element);",
            "array.append(element);",
            "array.insert(element);",
          ],
          correctAnswer: "array.push(element);",
        },
        {
          question:
            "Ako môžeš zastaviť interval spustený `setInterval` v JavaScripte?",
          options: [
            "stopInterval()",
            "clearTimeout()",
            "clearInterval()",
            "stopTimer()",
          ],
          correctAnswer: "clearInterval()",
        },
        {
          question: "Čo vráti nasledujúci kód: `[1, 2, 3].slice(1)`?",
          options: ["[2, 3]", "[1, 2]", "[1, 3]", "[1]"],
          correctAnswer: "[2, 3]",
        },
        {
          question: "Aký bude výstup nasledujúceho kódu: `Boolean('')`?",
          options: ["false", "true", "null", "undefined"],
          correctAnswer: "false",
        },
        {
          question: "Čo vráti nasledujúci kód: `typeof NaN`?",
          options: ["'number'", "'undefined'", "'NaN'", "'object'"],
          correctAnswer: "'number'",
        },
      ],
      backgroundImage: require("./categories/it/it-js-syntax.jpg"),
    },

    {
      name: "Javascript dátové typy",
      questions: [
        {
          question: "Aký dátový typ je `null` v JavaScripte?",
          options: ["undefined", "object", "boolean", "number"],
          correctAnswer: "object",
        },
        {
          question:
            "Ktorý z nasledujúcich typov je primitívny dátový typ v JavaScripte?",
          options: ["function", "array", "boolean", "object"],
          correctAnswer: "boolean",
        },
        {
          question: "Čo vráti `typeof` operátor pre reťazec?",
          options: ["string", "text", "char", "object"],
          correctAnswer: "string",
        },
        {
          question: "Ktorý dátový typ predstavuje čísla v JavaScripte?",
          options: ["number", "float", "integer", "decimal"],
          correctAnswer: "number",
        },
        {
          question: "Aký dátový typ je výsledkom výrazu `true && false`?",
          options: ["string", "boolean", "undefined", "null"],
          correctAnswer: "boolean",
        },
        {
          question: "Čo vráti `typeof` operátor pre pole?",
          options: ["array", "object", "list", "undefined"],
          correctAnswer: "object",
        },
        {
          question:
            "Ktorý z nasledujúcich nie je primitívny dátový typ v JavaScripte?",
          options: ["number", "boolean", "object", "undefined"],
          correctAnswer: "object",
        },
        {
          question: "Aká je hodnota výrazu `NaN === NaN`?",
          options: ["true", "false", "undefined", "null"],
          correctAnswer: "false",
        },
        {
          question: "Aký dátový typ je reprezentovaný hodnotou `undefined`?",
          options: ["null", "object", "boolean", "undefined"],
          correctAnswer: "undefined",
        },
        {
          question: "Čo vráti `typeof` operátor pre funkciu?",
          options: ["object", "method", "function", "procedure"],
          correctAnswer: "function",
        },
        {
          question: "Aká je hodnota `typeof` operátora pre hodnotu `42`?",
          options: ["string", "number", "boolean", "object"],
          correctAnswer: "number",
        },
        {
          question: "Ktorý z nasledujúcich nie je dátový typ v JavaScripte?",
          options: ["undefined", "symbol", "char", "null"],
          correctAnswer: "char",
        },
        {
          question: "Akú hodnotu vráti výraz `typeof NaN`?",
          options: ["number", "NaN", "undefined", "null"],
          correctAnswer: "number",
        },
        {
          question:
            "Ktorý z nasledujúcich dátových typov môže byť prázdny alebo neprázdny?",
          options: ["undefined", "boolean", "string", "null"],
          correctAnswer: "string",
        },
        {
          question: "Čo je `Symbol` v JavaScripte?",
          options: ["primitívny dátový typ", "metóda", "objekt", "pole"],
          correctAnswer: "primitívny dátový typ",
        },
        {
          question: "Čo vráti `typeof` operátor pre hodnotu `true`?",
          options: ["boolean", "string", "number", "object"],
          correctAnswer: "boolean",
        },
        {
          question: "Aký je rozdiel medzi `undefined` a `null` v JavaScripte?",
          options: [
            "`undefined` predstavuje neexistujúcu hodnotu, `null` je úmyselný prázdny odkaz",
            "`null` znamená neexistujúcu hodnotu, `undefined` je úmyselný prázdny odkaz",
            "Oba predstavujú to isté",
            "`undefined` je číslo, `null` je boolean",
          ],
          correctAnswer:
            "`undefined` predstavuje neexistujúcu hodnotu, `null` je úmyselný prázdny odkaz",
        },
        {
          question:
            "Ktorý dátový typ má obmedzenú presnosť pri manipulácii s číslami?",
          options: ["number", "boolean", "string", "symbol"],
          correctAnswer: "number",
        },
        {
          question: "Čo sa stane, keď priradíš hodnotu `null` premennej?",
          options: [
            "Premenná bude mať hodnotu `null` a bude považovaná za objekt.",
            "Premenná bude neexistovať.",
            "Premenná sa zmení na `undefined`.",
            "Premenná zostane nezmenená.",
          ],
          correctAnswer:
            "Premenná bude mať hodnotu `null` a bude považovaná za objekt.",
        },
        {
          question: "Ktorý dátový typ sa používa na prácu s textom?",
          options: ["string", "boolean", "number", "symbol"],
          correctAnswer: "string",
        },
        {
          question: "Aká hodnota sa považuje za `falsy` v JavaScripte?",
          options: ["0", "'0'", "'false'", "Všetky hodnoty sú pravdivé"],
          correctAnswer: "0",
        },
        {
          question: "Aký dátový typ je `Symbol`?",
          options: [
            "Primitívny dátový typ",
            "Referenčný typ",
            "Číselný typ",
            "Špeciálny typ pre čísla",
          ],
          correctAnswer: "Primitívny dátový typ",
        },
        {
          question:
            "Čo sa stane, ak použiješ operátor `+` medzi číslom a reťazcom?",
          options: [
            "Hodnoty sa zreťazia ako text",
            "Operácia zlyhá",
            "Výsledkom bude súčet čísel",
            "Hodnoty sa skonvertujú na boolean",
          ],
          correctAnswer: "Hodnoty sa zreťazia ako text",
        },
        {
          question:
            "Aký dátový typ má premenná, ktorá bola deklarovaná, ale nie inicializovaná?",
          options: ["undefined", "null", "boolean", "object"],
          correctAnswer: "undefined",
        },
        {
          question: "Aký dátový typ vráti `typeof` pre `NaN`?",
          options: ["number", "undefined", "boolean", "object"],
          correctAnswer: "number",
        },
        {
          question: "Čo znamená hodnota `NaN`?",
          options: [
            "Not a Number",
            "Not a Null",
            "Negative and Null",
            "Numeric and Null",
          ],
          correctAnswer: "Not a Number",
        },
        {
          question: "Čo je hodnota `Infinity` v JavaScripte?",
          options: [
            "Špeciálna hodnota pre neobmedzené číslo",
            "Nedefinovaná hodnota",
            "Reprezentácia chyby",
            "Premenná pre veľmi veľké čísla",
          ],
          correctAnswer: "Špeciálna hodnota pre neobmedzené číslo",
        },
        {
          question:
            "Ktorý z nasledujúcich dátových typov v JavaScripte podporuje symboly?",
          options: ["symbol", "boolean", "string", "number"],
          correctAnswer: "symbol",
        },
        {
          question: "Ktorý dátový typ umožňuje vykonávať operácie s bitmi?",
          options: ["number", "boolean", "symbol", "object"],
          correctAnswer: "number",
        },
        {
          question:
            "Aký dátový typ sa používa na uloženie hodnoty pravda alebo nepravda?",
          options: ["boolean", "string", "number", "object"],
          correctAnswer: "boolean",
        },
        {
          question: "Aký je výsledok operácie `10 / 0` v JavaScripte?",
          options: ["Infinity", "0", "undefined", "Error"],
          correctAnswer: "Infinity",
        },
        {
          question:
            "Aká hodnota sa považuje za pravdivú (`truthy`) v JavaScripte?",
          options: ["'false'", "'0'", "[]", "null"],
          correctAnswer: "[]",
        },
        {
          question:
            "Čo sa stane, keď použijeme `typeof` na neexistujúcu premennú?",
          options: [
            "Vyhodí chybu",
            "Vráti `undefined`",
            "Vráti `null`",
            "Vráti `false`",
          ],
          correctAnswer: "Vráti `undefined`",
        },
        {
          question:
            "Ktorý dátový typ predstavuje chybové hodnoty alebo nekonečné čísla?",
          options: ["NaN", "null", "boolean", "undefined"],
          correctAnswer: "NaN",
        },
        {
          question:
            "Ktorý dátový typ môže byť použitý na uloženie komplexných štruktúr údajov?",
          options: ["object", "boolean", "number", "symbol"],
          correctAnswer: "object",
        },
        {
          question: "Aký dátový typ sa používa pre indexované zoznamy hodnôt?",
          options: ["array", "object", "number", "boolean"],
          correctAnswer: "array",
        },
        {
          question: "Aký dátový typ sa používa pre dvojicu kľúč-hodnota?",
          options: ["object", "array", "number", "boolean"],
          correctAnswer: "object",
        },
        {
          question: "Čo vráti `typeof` operátor pre prázdne pole?",
          options: ["object", "array", "null", "undefined"],
          correctAnswer: "object",
        },
        {
          question: "Čo znamená hodnota `undefined`?",
          options: [
            "Premenná bola deklarovaná, ale nebola inicializovaná",
            "Premenná neexistuje",
            "Premenná obsahuje prázdny reťazec",
            "Premenná má hodnotu null",
          ],
          correctAnswer: "Premenná bola deklarovaná, ale nebola inicializovaná",
        },
        {
          question:
            "Ktorý z nasledujúcich operátorov skontroluje dátový typ v JavaScripte?",
          options: ["typeof", "instanceof", "in", "length"],
          correctAnswer: "typeof",
        },
        {
          question: "Čo znamená hodnota `null` v JavaScripte?",
          options: [
            "Úmyselná prázdna hodnota",
            "Neplatná hodnota",
            "Premenná, ktorá neexistuje",
            "Reťazec",
          ],
          correctAnswer: "Úmyselná prázdna hodnota",
        },
        {
          question:
            "Čo sa stane, keď použiješ `typeof` pre dátový typ `symbol`?",
          options: [
            "Vráti 'symbol'",
            "Vráti 'object'",
            "Vráti 'undefined'",
            "Vráti 'boolean'",
          ],
          correctAnswer: "Vráti 'symbol'",
        },
        {
          question:
            "Aká hodnota sa považuje za nepravdivú (`falsy`) v JavaScripte?",
          options: ["0", "undefined", "false", "Všetky z uvedených"],
          correctAnswer: "Všetky z uvedených",
        },
        {
          question: "Čo predstavuje `typeof` operátor v JavaScripte?",
          options: [
            "Typ hodnoty premennej",
            "Hodnotu premennej",
            "Dĺžku reťazca",
            "Počet vlastností objektu",
          ],
          correctAnswer: "Typ hodnoty premennej",
        },
        {
          question: "Čo vráti operátor `typeof` pre hodnotu `true`?",
          options: ["boolean", "number", "string", "undefined"],
          correctAnswer: "boolean",
        },
        {
          question: "Aký je výsledok výrazu `5 + '5'` v JavaScripte?",
          options: ["'55'", "10", "NaN", "0"],
          correctAnswer: "'55'",
        },
        {
          question: "Aký je dátový typ neinicializovanej premennej?",
          options: ["undefined", "null", "boolean", "string"],
          correctAnswer: "undefined",
        },
        {
          question: "Čo predstavuje hodnota `NaN` v JavaScripte?",
          options: [
            "Not a Number",
            "Null and Negative",
            "Number and Null",
            "Numeric and Null",
          ],
          correctAnswer: "Not a Number",
        },
      ],
      backgroundImage: require("./categories/it/it-js-datove-typy.jpg"),
    },

    {
      name: "JavaScript JSON",
      questions: [
        {
          question: "Čo znamená JSON?",
          options: [
            "JavaScript Object Notation",
            "JavaScript Online Network",
            "Java Syntax Over Network",
            "JavaScript Orientation Notation",
          ],
          correctAnswer: "JavaScript Object Notation",
        },
        {
          question: "Čo robí metóda JSON.stringify()?",
          options: [
            "Konvertuje objekt na JSON string",
            "Parsuje JSON string na objekt",
            "Vymaže vlastnosti objektu",
            "Prevedie JSON na XML",
          ],
          correctAnswer: "Konvertuje objekt na JSON string",
        },
        {
          question: "Čo robí metóda JSON.parse()?",
          options: [
            "Konvertuje JSON string na objekt",
            "Konvertuje objekt na JSON string",
            "Skopíruje objekt",
            "Vytvorí nový objekt",
          ],
          correctAnswer: "Konvertuje JSON string na objekt",
        },
        {
          question: "Aký dátový typ JSON nepodporuje?",
          options: ["undefined", "string", "object", "boolean"],
          correctAnswer: "undefined",
        },
        {
          question: "Aký je správny formát JSON?",
          options: [
            `{"name": "John"}`,
            `{'name': 'John'}`,
            `{"name" = "John"}`,
            `{"name": John}`,
          ],
          correctAnswer: `{"name": "John"}`,
        },
        {
          question:
            "Ktorý z nasledujúcich dátových typov je podporovaný v JSON?",
          options: ["boolean", "symbol", "function", "date"],
          correctAnswer: "boolean",
        },
        {
          question:
            "Ktorá metóda sa používa na konverziu JavaScript objektu na JSON?",
          options: [
            "JSON.stringify()",
            "JSON.parse()",
            "JSON.objectify()",
            "JSON.convert()",
          ],
          correctAnswer: "JSON.stringify()",
        },
        {
          question:
            "Ktorá metóda sa používa na konverziu JSON stringu na JavaScript objekt?",
          options: [
            "JSON.parse()",
            "JSON.stringify()",
            "JSON.objectify()",
            "JSON.convert()",
          ],
          correctAnswer: "JSON.parse()",
        },
        {
          question: "JSON je odvodený z ktorého jazyka?",
          options: ["JavaScript", "Java", "Python", "C++"],
          correctAnswer: "JavaScript",
        },
        {
          question: "Čo znamená 'null' v JSON?",
          options: [
            "Neexistujúcu alebo prázdnu hodnotu",
            "Reťazec",
            "Číselnú hodnotu",
            "Objekt",
          ],
          correctAnswer: "Neexistujúcu alebo prázdnu hodnotu",
        },
        {
          question: "Je JSON formát nezávislý na jazyku?",
          options: ["Áno", "Nie", "Iba v JavaScripte", "Iba v HTML"],
          correctAnswer: "Áno",
        },
        {
          question:
            "Ktorá z nasledujúcich metód môže hádzať chybu SyntaxError?",
          options: [
            "JSON.parse()",
            "JSON.stringify()",
            "JSON.compile()",
            "JSON.execute()",
          ],
          correctAnswer: "JSON.parse()",
        },
        {
          question:
            "Čo sa stane, ak sa pokúsite serializovať JavaScript funkciu do JSON?",
          options: [
            "Funkcia nebude serializovaná",
            "Funkcia sa prevedie na string",
            "Funkcia sa prevedie na objekt",
            "Program zlyhá",
          ],
          correctAnswer: "Funkcia nebude serializovaná",
        },
        {
          question: "JSON je založený na ktorých dvoch štruktúrach?",
          options: [
            "Kľúč-hodnota páry a zoznamy",
            "Reťazce a čísla",
            "Objekty a funkcie",
            "Premenné a funkcie",
          ],
          correctAnswer: "Kľúč-hodnota páry a zoznamy",
        },
        {
          question: "Aká je maximálna veľkosť JSON súboru?",
          options: ["Nie je špecifikovaná", "1 MB", "64 MB", "128 MB"],
          correctAnswer: "Nie je špecifikovaná",
        },
        {
          question:
            "Ktorý z nasledujúcich typov hodnôt nie je povolený v JSON?",
          options: ["Infinity", "null", "true", "false"],
          correctAnswer: "Infinity",
        },
        {
          question: "Aký je rozdiel medzi XML a JSON?",
          options: [
            "JSON je menej zložitý ako XML",
            "JSON je zložitejší ako XML",
            "JSON a XML sú rovnaké",
            "XML je rýchlejšie ako JSON",
          ],
          correctAnswer: "JSON je menej zložitý ako XML",
        },
        {
          question: "Aký dátový typ v JSON neexistuje?",
          options: ["Date", "String", "Number", "Boolean"],
          correctAnswer: "Date",
        },
        {
          question: "Ako sa nazýva proces konverzie objektu na textový formát?",
          options: [
            "Serializácia",
            "Deserializácia",
            "Formátovanie",
            "Parsovanie",
          ],
          correctAnswer: "Serializácia",
        },
        {
          question:
            "Ako sa nazýva proces konverzie JSON stringu na JavaScript objekt?",
          options: [
            "Deserializácia",
            "Serializácia",
            "Formátovanie",
            "Parsovanie",
          ],
          correctAnswer: "Deserializácia",
        },
        // Pridávam ďalšie otázky...
        {
          question:
            "Môže byť JSON použitý na výmenu dát medzi serverom a klientom?",
          options: ["Áno", "Nie", "Len medzi servermi", "Len v HTML"],
          correctAnswer: "Áno",
        },
        {
          question: "Ako sa vyjadrujú polia v JSON?",
          options: [
            "Pomocou hranatých zátvoriek []",
            "Pomocou zložených zátvoriek {}",
            "Pomocou okrúhlych zátvoriek ()",
            'Pomocou úvodzoviek ""',
          ],
          correctAnswer: "Pomocou hranatých zátvoriek []",
        },
        {
          question: "Ako sa vyjadrujú objekty v JSON?",
          options: [
            "Pomocou zložených zátvoriek {}",
            "Pomocou hranatých zátvoriek []",
            "Pomocou okrúhlych zátvoriek ()",
            'Pomocou úvodzoviek ""',
          ],
          correctAnswer: "Pomocou zložených zátvoriek {}",
        },
        {
          question: "Môže JSON uchovávať čísla s desatinnou čiarkou?",
          options: ["Áno", "Nie", "Iba celé čísla", "Iba textové reťazce"],
          correctAnswer: "Áno",
        },
        {
          question: "Aký je výstup JSON.stringify([1, 2, 3])?",
          options: ['"[1,2,3]"', '"1,2,3"', "[1,2,3]", "{1,2,3}"],
          correctAnswer: '"[1,2,3]"',
        },
        {
          question: "Môže JSON obsahovať vnorené objekty?",
          options: [
            "Áno",
            "Nie",
            "Len jednu úroveň",
            "Len hodnoty typu string",
          ],
          correctAnswer: "Áno",
        },
        {
          question: "Môže byť hodnota v JSON ďalší JSON objekt?",
          options: ["Áno", "Nie", "Iba číslo", "Iba string"],
          correctAnswer: "Áno",
        },
        {
          question: "Ako sa v JSON oddelujú jednotlivé položky?",
          options: ["Čiarkou", "Bodomkočiarkou", "Dvojbodkou", "Úvodzovkami"],
          correctAnswer: "Čiarkou",
        },
        {
          question: "Ako sa v JSON píšu reťazce?",
          options: [
            "V úvodzovkách",
            "Bez úvodzoviek",
            "V zložených zátvorkách",
            "V hranatých zátvorkách",
          ],
          correctAnswer: "V úvodzovkách",
        },
        {
          question: "JSON sa často používa v spojení s ktorou technológiou?",
          options: ["AJAX", "SQL", "HTML", "CSS"],
          correctAnswer: "AJAX",
        },
        // Pokračuj vo vytváraní otázok podľa rovnakého vzoru...
      ],
      backgroundImage: require("./categories/it/it-js-json.jpg"),
    },

    // Ďalšie testy
  ],

  // Ostatné kategórie a testy začiatok
  geo: [
    {
      name: "Hlavné mestá Afriky",
      questions: [
        {
          question: "Aké je hlavné mesto Alžírska?",
          options: ["Alžír", "Casablanca", "Tunis", "Rabat"],
          correctAnswer: "Alžír",
        },
        {
          question: "Aké je hlavné mesto Angoly?",
          options: ["Luanda", "Maputo", "Kinshasa", "Lusaka"],
          correctAnswer: "Luanda",
        },
        {
          question: "Aké je hlavné mesto Beninu?",
          options: ["Porto-Novo", "Cotonou", "Lomé", "Accra"],
          correctAnswer: "Porto-Novo",
        },
        {
          question: "Aké je hlavné mesto Botswany?",
          options: ["Gaborone", "Windhoek", "Pretoria", "Harare"],
          correctAnswer: "Gaborone",
        },
        {
          question: "Aké je hlavné mesto Burkina Faso?",
          options: ["Ouagadougou", "Accra", "Lima", "Banjul"],
          correctAnswer: "Ouagadougou",
        },
        {
          question: "Aké je hlavné mesto Burundi?",
          options: ["Gitega", "Kigali", "Dodoma", "Bujumbura"],
          correctAnswer: "Gitega",
        },
        {
          question: "Aké je hlavné mesto Cabo Verde?",
          options: ["Praia", "São Tomé", "Malabo", "Lima"],
          correctAnswer: "Praia",
        },
        {
          question: "Aké je hlavné mesto Čadu?",
          options: ["N'Djamena", "Libreville", "Dakar", "Brazzaville"],
          correctAnswer: "N'Djamena",
        },
        {
          question: "Aké je hlavné mesto majú Komory?",
          options: ["Moroni", "Antananarivo", "Victoria", "Port Louis"],
          correctAnswer: "Moroni",
        },
        {
          question: "Aké je hlavné mesto Demokratickej republiky Kongo?",
          options: ["Kinshasa", "Brazzaville", "Addis Ababa", "Lima"],
          correctAnswer: "Kinshasa",
        },
        {
          question: "Aké je hlavné mesto Džibuti?",
          options: ["Džibuti", "Nairobi", "Asmara", "Hargeisa"],
          correctAnswer: "Džibuti",
        },
        {
          question: "Aké je hlavné mesto Egypta?",
          options: ["Káhira", "Rím", "Madrid", "Atény"],
          correctAnswer: "Káhira",
        },
        {
          question: "Aké je hlavné mesto Ekvatoriálnej Guiney?",
          options: ["Malabo", "Libreville", "Yamoussoukro", "Banjul"],
          correctAnswer: "Malabo",
        },
        {
          question: "Aké je hlavné mesto Eritrey?",
          options: ["Asmara", "Addis Ababa", "Nairobi", "Khartoum"],
          correctAnswer: "Asmara",
        },
        {
          question: "Aké je hlavné mesto Etiópie?",
          options: ["Addis Ababa", "Kampala", "Gaborone", "Lima"],
          correctAnswer: "Addis Ababa",
        },
        {
          question: "Aké je hlavné mesto Gabonu?",
          options: ["Libreville", "Yaoundé", "Bujumbura", "Lima"],
          correctAnswer: "Libreville",
        },
        {
          question: "Aké je hlavné mesto Gambie?",
          options: ["Banjul", "Dakar", "Monrovia", "Abuja"],
          correctAnswer: "Banjul",
        },
        {
          question: "Aké je hlavné mesto Ghany?",
          options: ["Accra", "Lomé", "Kampala", "Ouagadougou"],
          correctAnswer: "Accra",
        },
        {
          question: "Aké je hlavné mesto Guiney?",
          options: ["Conakry", "Banjul", "Monrovia", "Dakar"],
          correctAnswer: "Conakry",
        },
        {
          question: "Aké je hlavné mesto Guiney-Bissau?",
          options: ["Bissau", "Conakry", "Dakar", "Lima"],
          correctAnswer: "Bissau",
        },
        {
          question: "Aké je hlavné mesto Pobrežia Slonoviny?",
          options: ["Yamoussoukro", "Accra", "Lima", "Dakar"],
          correctAnswer: "Yamoussoukro",
        },
        {
          question:
            "Aké je hlavné (administratívne) mesto Juhoafrickej Republiky (JAR)?",
          options: ["Pretoria", "Johannesburg", "Cape Town", "Durban"],
          correctAnswer: "Pretoria",
        },
        {
          question: "Aké je hlavné mesto Kene?",
          options: ["Nairobi", "Kigali", "Dodoma", "Dakar"],
          correctAnswer: "Nairobi",
        },
        {
          question: "Aké je hlavné mesto Republiky Kongo?",
          options: ["Brazzaville", "Kinshasa", "Addis Ababa", "Bujumbura"],
          correctAnswer: "Brazzaville",
        },
        {
          question: "Aké je hlavné mesto Libérie?",
          options: ["Monrovia", "Accra", "Banjul", "Freetown"],
          correctAnswer: "Monrovia",
        },
        {
          question: "Aké je hlavné mesto Líbye?",
          options: ["Tripolis", "Káhira", "Alžír", "Tunis"],
          correctAnswer: "Tripolis",
        },
        {
          question: "Aké je hlavné mesto Malawi?",
          options: ["Lilongwe", "Blantyre", "Dodoma", "Nairobi"],
          correctAnswer: "Lilongwe",
        },

        {
          question: "Aké je hlavné mesto Mali?",
          options: ["Bamako", "Ouagadougou", "N'Djamena", "Conakry"],
          correctAnswer: "Bamako",
        },
        {
          question: "Aké je hlavné mesto Maroka?",
          options: ["Rabat", "Casablanca", "Tunis", "Alžír"],
          correctAnswer: "Rabat",
        },
        {
          question: "Aké je hlavné mesto Maurícia?",
          options: ["Port Louis", "Antananarivo", "Victoria", "Lima"],
          correctAnswer: "Port Louis",
        },
        {
          question: "Aké je hlavné mesto Mauritánie?",
          options: ["Nouakchott", "Dakar", "Banjul", "Ouagadougou"],
          correctAnswer: "Nouakchott",
        },
        {
          question: "Aké je hlavné mesto Mozambiku?",
          options: ["Maputo", "Lima", "Kinshasa", "Luanda"],
          correctAnswer: "Maputo",
        },
        {
          question: "Aké je hlavné mesto Namíbie?",
          options: ["Windhoek", "Gaborone", "Pretoria", "Lima"],
          correctAnswer: "Windhoek",
        },
        {
          question: "Aké je hlavné mesto Nigeru?",
          options: ["Niamey", "Ouagadougou", "Accra", "Banjul"],
          correctAnswer: "Niamey",
        },
        {
          question: "Aké je hlavné mesto Nigérie?",
          options: ["Abuja", "Lagos", "Accra", "Dakar"],
          correctAnswer: "Abuja",
        },
        {
          question: "Aké je hlavné mesto Rwandy?",
          options: ["Kigali", "Bujumbura", "Addis Ababa", "Nairobi"],
          correctAnswer: "Kigali",
        },
        {
          question: "Aké je hlavné mesto Senegal?",
          options: ["Dakar", "Banjul", "Accra", "Conakry"],
          correctAnswer: "Dakar",
        },
        {
          question: "Aké je hlavné mesto Sierra Leone?",
          options: ["Freetown", "Monrovia", "Banjul", "Dakar"],
          correctAnswer: "Freetown",
        },
        {
          question: "Aké je hlavné mesto Somálska?",
          options: ["Mogadišo", "Asmara", "Addis Ababa", "Nairobi"],
          correctAnswer: "Mogadišo",
        },
        {
          question: "Aké je hlavné mesto Sudánu?",
          options: ["Chartúm", "Káhira", "Rijád", "Juba"],
          correctAnswer: "Chartúm",
        },
        {
          question: "Aké je hlavné mesto Tanzánie?",
          options: ["Dodoma", "Dar es Salaam", "Nairobi", "Kigali"],
          correctAnswer: "Dodoma",
        },
        {
          question: "Aké je hlavné mesto Toga?",
          options: ["Lomé", "Accra", "Cotonou", "Porto-Novo"],
          correctAnswer: "Lomé",
        },
        {
          question: "Aké je hlavné mesto Tuniska?",
          options: ["Tunis", "Alžír", "Casablanca", "Rabat"],
          correctAnswer: "Tunis",
        },
        {
          question: "Aké je hlavné mesto Ugandy?",
          options: ["Kampala", "Kigali", "Bujumbura", "Dodoma"],
          correctAnswer: "Kampala",
        },
        {
          question: "Aké je hlavné mesto Zambie?",
          options: ["Lusaka", "Harare", "Gaborone", "Kinshasa"],
          correctAnswer: "Lusaka",
        },
        {
          question: "Aké je hlavné mesto Zimbabwe?",
          options: ["Harare", "Lusaka", "Maputo", "Kinshasa"],
          correctAnswer: "Harare",
        },
        {
          question: "Aké je hlavné mesto Lesotha?",
          options: ["Maseru", "Mbabane", "Bujumbura", "Lusaka"],
          correctAnswer: "Maseru",
        },
        {
          question: "Aké je hlavné mesto Eswatini (Swaziland)?",
          options: ["Mbabane", "Maseru", "Gaborone", "Victoria"],
          correctAnswer: "Mbabane",
        },
        {
          question: "Aké je hlavné mesto Svätého Tomáša a Princovho ostrova?",
          options: ["São Tomé", "Malabo", "Libreville", "Cotonou"],
          correctAnswer: "São Tomé",
        },
        {
          question: "Aké je hlavné mesto Stredoafrickej republiky?",
          options: ["Bangui", "Brazzaville", "N'Djamena", "Kinshasa"],
          correctAnswer: "Bangui",
        },
        {
          question: "Aké je hlavné mesto Seychel?",
          options: ["Victoria", "Port Louis", "Malabo", "Moroni"],
          correctAnswer: "Victoria",
        },
        {
          question:
            "Aké je hlavné mesto Saharskej arabské demokratickej republiky?",
          options: ["Laayoune", "Nouakchott", "Tindouf", "Rabat"],
          correctAnswer: "Laayoune",
        },
        {
          question: "Aké je hlavné mesto Južného Sudánu?",
          options: ["Juba", "Khartoum", "Kampala", "Nairobi"],
          correctAnswer: "Juba",
        },
        {
          question: "Aké je hlavné mesto Zanzibaru?",
          options: ["Stone Town", "Dar es Salaam", "Arusha", "Dodoma"],
          correctAnswer: "Stone Town",
        },
      ],
      backgroundImage: require("./categories/geo/geo-afrika-hlavne-mesta.jpg"),
    },

    {
      name: "Európske štáty a hlavné mestá",
      questions: [
        {
          question: "Ktoré mesto je hlavným mestom Albánska?",
          options: ["Tirana", "Sofia", "Podgorica", "Sarajevo"],
          correctAnswer: "Tirana",
        },
        {
          question: "Aké je hlavné mesto Andorry?",
          options: ["Andorra la Vella", "Monako", "Vaduz", "San Maríno"],
          correctAnswer: "Andorra la Vella",
        },
        {
          question: "Hlavné mesto Arménska je:",
          options: ["Jerevan", "Baku", "Tbilisi", "Astana"],
          correctAnswer: "Jerevan",
        },
        {
          question: "Ktoré mesto je hlavným mestom Rakúska?",
          options: ["Viedeň", "Bratislava", "Budapešť", "Praha"],
          correctAnswer: "Viedeň",
        },
        {
          question: "Aké je hlavné mesto Azerbajdžanu?",
          options: ["Baku", "Tbilisi", "Jerevan", "Astana"],
          correctAnswer: "Baku",
        },
        {
          question: "Hlavné mesto Bieloruska je:",
          options: ["Minsk", "Kyjev", "Moskva", "Vilnius"],
          correctAnswer: "Minsk",
        },
        {
          question: "Ktoré mesto je hlavným mestom Belgicka?",
          options: ["Brusel", "Amsterdam", "Luxemburg", "Paríž"],
          correctAnswer: "Brusel",
        },
        {
          question: "Hlavné mesto Bosny a Hercegoviny je:",
          options: ["Sarajevo", "Záhreb", "Podgorica", "Skopje"],
          correctAnswer: "Sarajevo",
        },
        {
          question: "Aké je hlavné mesto Bulharska?",
          options: ["Sofia", "Bukurešť", "Atény", "Záhreb"],
          correctAnswer: "Sofia",
        },
        {
          question: "Ktoré mesto je hlavným mestom Chorvátska?",
          options: ["Záhreb", "Ľubľana", "Sofia", "Sarajevo"],
          correctAnswer: "Záhreb",
        },
        {
          question: "Aké je hlavné mesto Cypru?",
          options: ["Nikózia", "Valletta", "Ammán", "Bejrút"],
          correctAnswer: "Nikózia",
        },
        {
          question: "Hlavné mesto Českej republiky je:",
          options: ["Praha", "Bratislava", "Viedeň", "Budapešť"],
          correctAnswer: "Praha",
        },
        {
          question: "Aké je hlavné mesto Dánska?",
          options: ["Kodaň", "Oslo", "Štokholm", "Helsinki"],
          correctAnswer: "Kodaň",
        },
        {
          question: "Ktoré mesto je hlavným mestom Estónska?",
          options: ["Tallinn", "Riga", "Vilnius", "Helsinki"],
          correctAnswer: "Tallinn",
        },
        {
          question: "Hlavné mesto Fínska je:",
          options: ["Helsinki", "Tallinn", "Štokholm", "Oslo"],
          correctAnswer: "Helsinki",
        },
        {
          question: "Aké je hlavné mesto Francúzska?",
          options: ["Paríž", "Brusel", "Luxemburg", "Amsterdam"],
          correctAnswer: "Paríž",
        },
        {
          question: "Ktoré mesto je hlavným mestom Gruzínska?",
          options: ["Tbilisi", "Jerevan", "Baku", "Astana"],
          correctAnswer: "Tbilisi",
        },
        {
          question: "Hlavné mesto Nemecka je:",
          options: ["Berlín", "Viedeň", "Bratislava", "Varšava"],
          correctAnswer: "Berlín",
        },
        {
          question: "Aké je hlavné mesto Grécka?",
          options: ["Atény", "Rím", "Lisabon", "Madrid"],
          correctAnswer: "Atény",
        },
        {
          question: "Ktoré mesto je hlavným mestom Maďarska?",
          options: ["Budapešť", "Bratislava", "Varšava", "Praha"],
          correctAnswer: "Budapešť",
        },
        {
          question: "Hlavné mesto Islandu je:",
          options: ["Reykjavík", "Oslo", "Štokholm", "Helsinki"],
          correctAnswer: "Reykjavík",
        },
        {
          question: "Aké je hlavné mesto Írska?",
          options: ["Dublin", "Londýn", "Edinburgh", "Belfast"],
          correctAnswer: "Dublin",
        },
        {
          question: "Ktoré mesto je hlavným mestom Talianska?",
          options: ["Rím", "Miláno", "Neapol", "Florencia"],
          correctAnswer: "Rím",
        },
        {
          question: "Hlavné mesto Kazachstanu je:",
          options: ["Astana", "Almaty", "Baku", "Tbilisi"],
          correctAnswer: "Astana",
        },
        {
          question: "Aké je hlavné mesto Kosova?",
          options: ["Priština", "Sarajevo", "Skopje", "Tirana"],
          correctAnswer: "Priština",
        },
        {
          question: "Ktoré mesto je hlavným mestom Lotyšska?",
          options: ["Riga", "Tallinn", "Vilnius", "Helsinki"],
          correctAnswer: "Riga",
        },
        {
          question: "Hlavné mesto Lichtenštajnska je:",
          options: ["Vaduz", "San Maríno", "Luxemburg", "Andorra la Vella"],
          correctAnswer: "Vaduz",
        },
        {
          question: "Aké je hlavné mesto Litvy?",
          options: ["Vilnius", "Tallinn", "Riga", "Helsinki"],
          correctAnswer: "Vilnius",
        },
        {
          question: "Ktoré mesto je hlavným mestom Luxemburska?",
          options: ["Luxemburg", "Brusel", "Amsterdam", "Paríž"],
          correctAnswer: "Luxemburg",
        },
        {
          question: "Hlavné mesto Malty je:",
          options: ["Valletta", "Nikózia", "Athény", "Rím"],
          correctAnswer: "Valletta",
        },
        {
          question: "Aké je hlavné mesto Moldavska?",
          options: ["Kišiňov", "Kyjev", "Minsk", "Bukurešť"],
          correctAnswer: "Kišiňov",
        },
        {
          question: "Ktoré mesto je hlavným mestom Monaka?",
          options: ["Monako", "Vaduz", "Andorra la Vella", "San Maríno"],
          correctAnswer: "Monako",
        },
        {
          question: "Hlavné mesto Čiernej Hory je:",
          options: ["Podgorica", "Sarajevo", "Skopje", "Priština"],
          correctAnswer: "Podgorica",
        },
        {
          question: "Aké je hlavné mesto Holandska?",
          options: ["Amsterdam", "Brusel", "Luxemburg", "Rotterdam"],
          correctAnswer: "Amsterdam",
        },
        {
          question: "Ktoré mesto je hlavným mestom Severného Macedónska?",
          options: ["Skopje", "Sarajevo", "Podgorica", "Priština"],
          correctAnswer: "Skopje",
        },
        {
          question: "Hlavné mesto Nórska je:",
          options: ["Oslo", "Štokholm", "Kodaň", "Helsinki"],
          correctAnswer: "Oslo",
        },
        {
          question: "Aké je hlavné mesto Poľska?",
          options: ["Varšava", "Bratislava", "Praha", "Budapešť"],
          correctAnswer: "Varšava",
        },
        {
          question: "Ktoré mesto je hlavným mestom Portugalska?",
          options: ["Lisabon", "Madrid", "Paríž", "Brusel"],
          correctAnswer: "Lisabon",
        },
        {
          question: "Hlavné mesto Rumunska je:",
          options: ["Bukurešť", "Sofia", "Záhreb", "Atény"],
          correctAnswer: "Bukurešť",
        },
        {
          question: "Aké je hlavné mesto Ruska?",
          options: ["Moskva", "Kyjev", "Minsk", "Baku"],
          correctAnswer: "Moskva",
        },
        {
          question: "Ktoré mesto je hlavným mestom San Marína?",
          options: ["San Maríno", "Vaduz", "Monako", "Andorra la Vella"],
          correctAnswer: "San Maríno",
        },
        {
          question: "Hlavné mesto Srbska je:",
          options: ["Belehrad", "Sarajevo", "Skopje", "Podgorica"],
          correctAnswer: "Belehrad",
        },
        {
          question: "Aké je hlavné mesto Slovenska?",
          options: ["Bratislava", "Praha", "Budapešť", "Varšava"],
          correctAnswer: "Bratislava",
        },
        {
          question: "Ktoré mesto je hlavným mestom Slovinska?",
          options: ["Ľubľana", "Záhreb", "Sofia", "Sarajevo"],
          correctAnswer: "Ľubľana",
        },
        {
          question: "Hlavné mesto Španielska je:",
          options: ["Madrid", "Barcelona", "Lisabon", "Paríž"],
          correctAnswer: "Madrid",
        },
        {
          question: "Aké je hlavné mesto Švédska?",
          options: ["Štokholm", "Oslo", "Kodaň", "Helsinki"],
          correctAnswer: "Štokholm",
        },
        {
          question: "Ktoré mesto je hlavným mestom Švajčiarska?",
          options: ["Bern", "Ženeva", "Zürich", "Lausanne"],
          correctAnswer: "Bern",
        },
        {
          question: "Hlavné mesto Turecka je:",
          options: ["Ankara", "Istanbul", "Sofia", "Atény"],
          correctAnswer: "Ankara",
        },
        {
          question: "Aké je hlavné mesto Ukrajiny?",
          options: ["Kyjev", "Minsk", "Moskva", "Baku"],
          correctAnswer: "Kyjev",
        },
        {
          question: "Ktoré mesto je hlavným mestom Spojeného kráľovstva?",
          options: ["Londýn", "Edinburgh", "Dublin", "Cardiff"],
          correctAnswer: "Londýn",
        },
        {
          question: "Hlavné mesto Vatikánu je:",
          options: ["Vatikán", "Rím", "Monako", "San Maríno"],
          correctAnswer: "Vatikán",
        },
      ],
      backgroundImage: require("./categories/geo/hlavne-mesta-europy.webp"),
    },

    {
      name: "Najvyššie štíty štátov Európy",
      questions: [
        {
          question: "Aký je najvyšší vrch Slovenska?",
          options: ["Kriváň", "Gerlachovský štít", "Lomnický štít", "Rysy"],
          correctAnswer: "Gerlachovský štít",
        },
        {
          question: "Aký je najvyšší vrch Švajčiarska?",
          options: ["Matterhorn", "Monte Rosa", "Dufourspitze", "Eiger"],
          correctAnswer: "Dufourspitze",
        },
        {
          question: "Aký je najvyšší vrch Francúzska?",
          options: [
            "Mont Blanc",
            "Pic du Midi",
            "Aiguille du Midi",
            "Grande Casse",
          ],
          correctAnswer: "Mont Blanc",
        },
        {
          question: "Aký je najvyšší vrch Talianska?",
          options: ["Gran Paradiso", "Matterhorn", "Monte Rosa", "Mont Blanc"],
          correctAnswer: "Mont Blanc",
        },
        {
          question: "Aký je najvyšší vrch Rakúska?",
          options: [
            "Grossvenediger",
            "Zugspitze",
            "Grossglockner",
            "Hochkönig",
          ],
          correctAnswer: "Grossglockner",
        },
        {
          question: "Aký je najvyšší vrch Nemecka?",
          options: ["Zugspitze", "Watzmann", "Brocken", "Feldberg"],
          correctAnswer: "Zugspitze",
        },
        {
          question: "Aký je najvyšší vrch Nórska?",
          options: ["Galdhøpiggen", "Snøhetta", "Glittertind", "Stetind"],
          correctAnswer: "Galdhøpiggen",
        },
        {
          question: "Aký je najvyšší vrch Španielska?",
          options: ["Pico Aneto", "Mulhacén", "Teide", "Pico de las Nieves"],
          correctAnswer: "Teide",
        },
        {
          question: "Aký je najvyšší vrch Veľkej Británie?",
          options: ["Scafell Pike", "Snowdon", "Ben Nevis", "Slieve Donard"],
          correctAnswer: "Ben Nevis",
        },
        {
          question: "Aký je najvyšší vrch Grécka?",
          options: ["Mytikas", "Athos", "Taygetus", "Olympos"],
          correctAnswer: "Mytikas",
        },
        {
          question: "Aký je najvyšší vrch Rumunska?",
          options: ["Moldoveanu", "Negoiu", "Bucegi", "Parângu Mare"],
          correctAnswer: "Moldoveanu",
        },
        {
          question: "Aký je najvyšší vrch Bulharska?",
          options: ["Musala", "Vihren", "Botev", "Rila"],
          correctAnswer: "Musala",
        },
        {
          question: "Aký je najvyšší vrch Poľska?",
          options: ["Rysy", "Gerlachovský štít", "Babia hora", "Śnieżka"],
          correctAnswer: "Rysy",
        },
        {
          question: "Aký je najvyšší vrch Česka?",
          options: ["Sněžka", "Praděd", "Kriváň", "Lysá hora"],
          correctAnswer: "Sněžka",
        },
        {
          question: "Aký je najvyšší vrch Ukrajiny?",
          options: ["Hoverla", "Petros", "Brebeneskul", "Pop Ivan"],
          correctAnswer: "Hoverla",
        },
      ],
      backgroundImage: require("./categories/geo/geo-najvyssie-stity-europy.jpg"),
    },

    {
      name: "Národné parky Slovenska",
      questions: [
        {
          question: "Ktorý národný park je najväčší na Slovensku?",
          options: [
            "Národný park Nízke Tatry",
            "Národný park Vysoké Tatry",
            "Národný park Slovenský raj",
            "Národný park Veľká Fatra",
          ],
          correctAnswer: "Národný park Nízke Tatry",
        },
        {
          question: "Ktorý národný park sa nachádza pri rieke Dunaj?",
          options: [
            "Národný park Malá Fatra",
            "Národný park Poloniny",
            "Národný park Dunajské luhy",
            "Národný park Muránska planina",
          ],
          correctAnswer: "Národný park Dunajské luhy",
        },
        {
          question:
            "Ktorý národný park je známy svojimi roklinami a vodopádmi?",
          options: [
            "Národný park Slovenský kras",
            "Národný park Muránska planina",
            "Národný park Slovenský raj",
            "Národný park Poloniny",
          ],
          correctAnswer: "Národný park Slovenský raj",
        },
        {
          question: "Ktorý národný park zahŕňa časť pohoria Tatry?",
          options: [
            "Národný park Nízke Tatry",
            "Národný park Vysoké Tatry",
            "Národný park Malá Fatra",
            "Národný park Poloniny",
          ],
          correctAnswer: "Národný park Vysoké Tatry",
        },
        {
          question: "Aký je najmladší národný park na Slovensku?",
          options: [
            "Národný park Slovenský kras",
            "Národný park Poloniny",
            "Národný park Veľká Fatra",
            "Národný park Slovenský raj",
          ],
          correctAnswer: "Národný park Poloniny",
        },
        {
          question: "Ktorý národný park leží na hranici s Poľskom?",
          options: [
            "Národný park Poloniny",
            "Národný park Muránska planina",
            "Národný park Pieniny",
            "Národný park Veľká Fatra",
          ],
          correctAnswer: "Národný park Pieniny",
        },
        {
          question: "Ktorý národný park je známy svojimi krasovými útvarmi?",
          options: [
            "Národný park Slovenský kras",
            "Národný park Vysoké Tatry",
            "Národný park Malá Fatra",
            "Národný park Muránska planina",
          ],
          correctAnswer: "Národný park Slovenský kras",
        },
        {
          question:
            "Ktorý národný park sa rozprestiera na území východného Slovenska?",
          options: [
            "Národný park Poloniny",
            "Národný park Nízke Tatry",
            "Národný park Malá Fatra",
            "Národný park Muránska planina",
          ],
          correctAnswer: "Národný park Poloniny",
        },
        {
          question: "V ktorom národnom parku sa nachádza vrch Kriváň?",
          options: [
            "Národný park Nízke Tatry",
            "Národný park Vysoké Tatry",
            "Národný park Veľká Fatra",
            "Národný park Malá Fatra",
          ],
          correctAnswer: "Národný park Vysoké Tatry",
        },
        {
          question: "Aký národný park zahŕňa časť Muránskej planiny?",
          options: [
            "Národný park Slovenský kras",
            "Národný park Muránska planina",
            "Národný park Malá Fatra",
            "Národný park Pieniny",
          ],
          correctAnswer: "Národný park Muránska planina",
        },
        {
          question:
            "Ktorý národný park sa nachádza v západnej časti Slovenska?",
          options: [
            "Národný park Malá Fatra",
            "Národný park Nízke Tatry",
            "Národný park Pieniny",
            "Národný park Veľká Fatra",
          ],
          correctAnswer: "Národný park Malá Fatra",
        },
        {
          question: "Ktorý národný park je známy ako 'strecha Slovenska'?",
          options: [
            "Národný park Vysoké Tatry",
            "Národný park Nízke Tatry",
            "Národný park Veľká Fatra",
            "Národný park Slovenský kras",
          ],
          correctAnswer: "Národný park Nízke Tatry",
        },
        {
          question:
            "V ktorom národnom parku sa nachádza Dobšinská ľadová jaskyňa?",
          options: [
            "Národný park Slovenský kras",
            "Národný park Slovenský raj",
            "Národný park Poloniny",
            "Národný park Muránska planina",
          ],
          correctAnswer: "Národný park Slovenský raj",
        },
        {
          question:
            "Ktorý národný park sa rozprestiera na území stredného Slovenska?",
          options: [
            "Národný park Nízke Tatry",
            "Národný park Veľká Fatra",
            "Národný park Malá Fatra",
            "Národný park Slovenský raj",
          ],
          correctAnswer: "Národný park Veľká Fatra",
        },
        {
          question: "Ktorý národný park je domovom vzácnych vlkov a medveďov?",
          options: [
            "Národný park Muránska planina",
            "Národný park Poloniny",
            "Národný park Malá Fatra",
            "Národný park Nízke Tatry",
          ],
          correctAnswer: "Národný park Malá Fatra",
        },
      ],
      backgroundImage: require("./categories/geo/geo-parky-sr.jpg"),
    },

    {
      name: "Najdlhšie rieky Európy",
      questions: [
        {
          question: "Aká je najdlhšia rieka v Európe?",
          options: ["Volga", "Rýn", "Dunaj", "Labe"],
          correctAnswer: "Volga",
        },
        {
          question: "Ktorá rieka tečie cez Nemecko a Holandsko?",
          options: ["Elba", "Rýn", "Dunaj", "Tisa"],
          correctAnswer: "Rýn",
        },
        {
          question: "Ktorá rieka je známa ako druhá najdlhšia v Európe?",
          options: ["Dunaj", "Volga", "Rýn", "Loira"],
          correctAnswer: "Dunaj",
        },
        {
          question: "Ktorá rieka preteká cez Maďarsko a Srbsko?",
          options: ["Dunaj", "Volga", "Elba", "Rýn"],
          correctAnswer: "Dunaj",
        },
        {
          question: "Ktorá rieka preteká cez Španielsko a Portugalsko?",
          options: ["Ebro", "Tajo", "Loira", "Seina"],
          correctAnswer: "Tajo",
        },
        {
          question:
            "Ktorá rieka je významná pre krajiny ako Francúzsko a Belgicko?",
          options: ["Rýn", "Loira", "Seina", "Tajo"],
          correctAnswer: "Seina",
        },
        {
          question: "Ktorá rieka preteká cez Anglicko?",
          options: ["Temža", "Severn", "Tajo", "Loira"],
          correctAnswer: "Temža",
        },
        {
          question: "Aká rieka tečie cez Rumunsko a Ukrajinu?",
          options: ["Dunaj", "Rýn", "Volga", "Labe"],
          correctAnswer: "Dunaj",
        },
        {
          question:
            "Ktorá rieka je známa svojou dĺžkou ako tretia najdlhšia v Európe?",
          options: ["Dunaj", "Rýn", "Volga", "Labe"],
          correctAnswer: "Rýn",
        },
        {
          question: "Ktorá rieka tečie cez Nórsko a Švédsko?",
          options: ["Glomma", "Volga", "Dunaj", "Rýn"],
          correctAnswer: "Glomma",
        },
        {
          question: "Aká rieka preteká cez Švajčiarsko a Nemecko?",
          options: ["Rýn", "Dunaj", "Seina", "Tajo"],
          correctAnswer: "Rýn",
        },
        {
          question: "Ktorá rieka je najdlhšia v Taliansku?",
          options: ["Pád", "Tiber", "Arno", "Po"],
          correctAnswer: "Po",
        },
        {
          question: "Ktorá rieka preteká cez Rakúsko a Maďarsko?",
          options: ["Dunaj", "Rýn", "Volga", "Loira"],
          correctAnswer: "Dunaj",
        },
        {
          question: "Ktorá rieka tečie cez Belgicko a Holandsko?",
          options: ["Rýn", "Seina", "Loira", "Maas"],
          correctAnswer: "Maas",
        },
        {
          question:
            "Aká je najdôležitejšia rieka v Rusku, ktorá je najdlhšia v Európe?",
          options: ["Volga", "Dneper", "Don", "Neva"],
          correctAnswer: "Volga",
        },
      ],
      backgroundImage: require("./categories/geo/geo-rieky-europy.jpg"),
    },
  ],
  econ: [
    {
      name: "Hrubý domáci produkt",
      questions: [
        {
          question: "Čo znamená skratka HDP?",
          options: [
            "Hrubý domáci produkt",
            "Hlavný daňový príjem",
            "Hraničný dovozný poplatok",
            "Hospodárska domáca platba",
          ],
          correctAnswer: "Hrubý domáci produkt",
        },
        {
          question: "Ako sa vypočíta HDP?",
          options: [
            "Súčet všetkých výrobkov a služieb v krajine",
            "Súčet všetkých daní zaplatených občanmi",
            "Súčet všetkých dovozov a vývozov krajiny",
            "Hodnota všetkých štátnych investícií",
          ],
          correctAnswer: "Súčet všetkých výrobkov a služieb v krajine",
        },
        {
          question: "Čo z nasledujúcich nie je súčasťou HDP?",
          options: [
            "Štátne výdavky",
            "Import výrobkov",
            "Súkromné investície",
            "Spotreba domácností",
          ],
          correctAnswer: "Import výrobkov",
        },
        {
          question: "Čo vyjadruje reálny HDP?",
          options: [
            "Hodnotu výroby očistenú o infláciu",
            "Hodnotu dovozov a vývozov",
            "Priemerné príjmy v krajine",
            "Štátny rozpočet krajiny",
          ],
          correctAnswer: "Hodnotu výroby očistenú o infláciu",
        },
        {
          question: "Aký vplyv má zvýšenie HDP na ekonomiku?",
          options: [
            "Rast ekonomickej aktivity",
            "Zvýšenie miery nezamestnanosti",
            "Zníženie cien výrobkov",
            "Pokles investícií",
          ],
          correctAnswer: "Rast ekonomickej aktivity",
        },
        {
          question: "Ktorá z týchto metód sa používa na výpočet HDP?",
          options: [
            "Výdavková metóda",
            "Daňová metóda",
            "Zisková metóda",
            "Úroková metóda",
          ],
          correctAnswer: "Výdavková metóda",
        },
        {
          question: "Čo môže spôsobiť pokles HDP?",
          options: [
            "Zníženie spotreby a investícií",
            "Zvýšenie počtu pracovných miest",
            "Rast cien komodít",
            "Posilnenie zahraničnej meny",
          ],
          correctAnswer: "Zníženie spotreby a investícií",
        },
        {
          question: "Čo z nasledujúcich ovplyvňuje rast HDP?",
          options: [
            "Zvýšenie produktivity práce",
            "Zníženie úrokových sadzieb",
            "Zvýšenie dovozov",
            "Pokles spotreby",
          ],
          correctAnswer: "Zvýšenie produktivity práce",
        },
        {
          question:
            "Ktorá organizácia monitoruje a porovnáva HDP medzi krajinami?",
          options: [
            "Svetová banka",
            "Organizácia pre hospodársku spoluprácu a rozvoj (OECD)",
            "NATO",
            "UNICEF",
          ],
          correctAnswer:
            "Organizácia pre hospodársku spoluprácu a rozvoj (OECD)",
        },
        {
          question: "Čo označuje pojem 'HDP na obyvateľa'?",
          options: [
            "Hodnota HDP vydelená počtom obyvateľov",
            "Hodnota investícií na jedného obyvateľa",
            "Hodnota štátneho dlhu",
            "Priemerný plat v krajine",
          ],
          correctAnswer: "Hodnota HDP vydelená počtom obyvateľov",
        },
        {
          question: "Čo môže znížiť hodnotu reálneho HDP?",
          options: [
            "Inflácia",
            "Export",
            "Rast produktivity",
            "Zvýšenie počtu pracovných miest",
          ],
          correctAnswer: "Inflácia",
        },
        {
          question: "Ktorý z nasledujúcich sektorov prispieva k HDP?",
          options: [
            "Poľnohospodárstvo, priemysel a služby",
            "Len verejný sektor",
            "Iba súkromné spoločnosti",
            "Export a import",
          ],
          correctAnswer: "Poľnohospodárstvo, priemysel a služby",
        },
        {
          question: "Aký vzťah má HDP a životná úroveň obyvateľstva?",
          options: [
            "Vyššie HDP často znamená vyššiu životnú úroveň",
            "Nižšie HDP znamená vždy vyššiu životnú úroveň",
            "HDP nemá vplyv na životnú úroveň",
            "HDP ovplyvňuje len štátny rozpočet",
          ],
          correctAnswer: "Vyššie HDP často znamená vyššiu životnú úroveň",
        },
        {
          question: "Aký je rozdiel medzi nominálnym a reálnym HDP?",
          options: [
            "Nominálny HDP nie je očistený o infláciu, reálny je",
            "Reálny HDP zahŕňa len služby, nominálny zahŕňa všetko",
            "Nominálny HDP zahŕňa len dane, reálny zahŕňa investície",
            "Reálny HDP zahŕňa len export a nominálny len import",
          ],
          correctAnswer: "Nominálny HDP nie je očistený o infláciu, reálny je",
        },
        {
          question: "Aké obdobie najčastejšie zahŕňa výpočet HDP?",
          options: ["Rok", "Týždeň", "Dekáda", "Štvrťrok"],
          correctAnswer: "Rok",
        },
      ],
      backgroundImage: require("./categories/econ/econ-hdp.jpg"),
    },

    {
      name: "Všeobecný test z ekonómie",
      questions: [
        {
          question: "Čo je základnou definíciou ekonómie?",
          options: [
            "Štúdium výroby, distribúcie a spotreby tovarov a služieb",
            "Štúdium histórie a kultúry",
            "Štúdium prírodných vied",
            "Štúdium politických systémov",
          ],
          correctAnswer:
            "Štúdium výroby, distribúcie a spotreby tovarov a služieb",
        },
        {
          question: "Čo je to inflácia?",
          options: [
            "Nárast cien tovarov a služieb v ekonomike",
            "Pokles úrokových sadzieb",
            "Rast zamestnanosti",
            "Zníženie štátneho dlhu",
          ],
          correctAnswer: "Nárast cien tovarov a služieb v ekonomike",
        },
        {
          question: "Čo je to HDP?",
          options: [
            "Hrubý domáci produkt",
            "Hlavný devízový podiel",
            "Hlavný dostupný produkt",
            "Hodnota domácej produkcie",
          ],
          correctAnswer: "Hrubý domáci produkt",
        },
        {
          question:
            "Ktorá ekonomická teória tvrdí, že trhy sú najefektívnejšie bez štátnych zásahov?",
          options: [
            "Laissez-faire",
            "Keynesiánska ekonomika",
            "Marxistická ekonomika",
            "Neoklasická ekonomika",
          ],
          correctAnswer: "Laissez-faire",
        },
        {
          question: "Čo je to „trh s dokonalou konkurenciou“?",
          options: [
            "Trh, kde je veľa predávajúcich a kupujúcich, ktorí nemajú kontrolu nad cenami",
            "Trh, kde jeden predávajúci ovláda ceny",
            "Trh, kde sú produkty úplne rovnaké",
            "Trh s obmedzeným počtom účastníkov",
          ],
          correctAnswer:
            "Trh, kde je veľa predávajúcich a kupujúcich, ktorí nemajú kontrolu nad cenami",
        },
        {
          question: "Čo je to makroekonómia?",
          options: [
            "Štúdium ekonomiky ako celku",
            "Štúdium individuálnych trhov",
            "Štúdium podnikov",
            "Štúdium medzinárodného obchodu",
          ],
          correctAnswer: "Štúdium ekonomiky ako celku",
        },
        {
          question: "Aký je hlavný účel centralizovanej centrálnej banky?",
          options: [
            "Regulovať menovú politiku a infláciu",
            "Riadiť obchod s inými krajinami",
            "Vykonávať kontrolu nad daňami",
            "Určovať ceny na trhu",
          ],
          correctAnswer: "Regulovať menovú politiku a infláciu",
        },
        {
          question: "Čo predstavuje pojem „dopyt“ v ekonómii?",
          options: [
            "Množstvo tovaru, ktoré sú spotrebitelia ochotní kúpiť za určitú cenu",
            "Množstvo tovaru, ktoré sú výrobcovia ochotní predávať",
            "Množstvo tovaru, ktoré je dostupné na trhu",
            "Množstvo peňazí v ekonomike",
          ],
          correctAnswer:
            "Množstvo tovaru, ktoré sú spotrebitelia ochotní kúpiť za určitú cenu",
        },
        {
          question: "Čo je to „príjmová elasticita dopytu“?",
          options: [
            "Miera, do akej sa dopyt mení v závislosti od zmien v príjme",
            "Miera zmeny ceny v závislosti od zmien dopytu",
            "Miera zmien v nákladoch výroby",
            "Miera zmien v ponuke tovaru",
          ],
          correctAnswer:
            "Miera, do akej sa dopyt mení v závislosti od zmien v príjme",
        },
        {
          question:
            "Ktorá ekonomická politika sa zameriava na stimulovanie ekonomiky prostredníctvom zvýšenia vládnych výdavkov a znižovania daní?",
          options: [
            "Keynesiánska politika",
            "Monetárna politika",
            "Politika štátneho zásahu",
            "Neoklasická politika",
          ],
          correctAnswer: "Keynesiánska politika",
        },
        {
          question: "Čo je to „náklady na príležitosť“?",
          options: [
            "Hodnota najlepšej alternatívy, ktorú treba obetovať, aby sme dosiahli určitý cieľ",
            "Celkové náklady spojené s výrobou tovaru",
            "Náklady na zabezpečenie pracovnej sily",
            "Náklady na prepravu tovaru",
          ],
          correctAnswer:
            "Hodnota najlepšej alternatívy, ktorú treba obetovať, aby sme dosiahli určitý cieľ",
        },
        {
          question: "Čo je to „deflácia“?",
          options: [
            "Pokles cien tovarov a služieb",
            "Nárast úrokových sadzieb",
            "Rast zamestnanosti",
            "Zníženie HDP",
          ],
          correctAnswer: "Pokles cien tovarov a služieb",
        },
        {
          question:
            "Ktorý pojem sa používa na označenie celkovej hodnoty všetkých finálnych tovarov a služieb vyrobených v krajine za určité obdobie?",
          options: [
            "Hrubý domáci produkt (HDP)",
            "Čistý národný produkt (ČNP)",
            "Hrubý národný produkt (HNP)",
            "Čistý domáci produkt (ČDP)",
          ],
          correctAnswer: "Hrubý domáci produkt (HDP)",
        },
        {
          question:
            "Ktorý typ trhu je charakterizovaný malým počtom veľkých predávajúcich, ktorí majú výrazný vplyv na ceny?",
          options: [
            "Oligopol",
            "Monopol",
            "Dokonalá konkurencia",
            "Monopolná konkurencia",
          ],
          correctAnswer: "Oligopol",
        },
        {
          question: "Čo je to „rozpočtový deficit“?",
          options: [
            "Situácia, keď výdavky vlády prevyšujú jej príjmy",
            "Situácia, keď štátne príjmy prevyšujú výdavky",
            "Situácia, keď sú verejné výdavky vyrovnané",
            "Situácia, keď štátne príjmy a výdavky sú v rovnováhe",
          ],
          correctAnswer: "Situácia, keď výdavky vlády prevyšujú jej príjmy",
        },
      ],
      backgroundImage: require("../src/categories/econ/econ-vseo-test.jpg"),
    },
  ],
  human: [
    {
      name: "Čo vieš o pľúcach",
      questions: [
        {
          question: "Ktorý orgán je hlavný pre dýchanie v ľudskom tele?",
          options: ["Srdce", "Pľúca", "Pečeň", "Mozog"],
          correctAnswer: "Pľúca",
        },
        {
          question: "Aký je hlavný proces, ktorý prebieha v pľúcach?",
          options: [
            "Krvný obeh",
            "Výmena plynov",
            "Trávenie",
            "Ochrana proti infekciám",
          ],
          correctAnswer: "Výmena plynov",
        },
        {
          question: "Ako sa volá trubica, ktorou vzduch prúdi do pľúc?",
          options: ["Hltan", "Priedušnica", "Pažerák", "Nos"],
          correctAnswer: "Priedušnica",
        },
        {
          question: "Ktorý plyn vdychujeme počas dýchania?",
          options: ["Dusík", "Kyslík", "Oxid uhličitý", "Vodík"],
          correctAnswer: "Kyslík",
        },
        {
          question: "Ktorý plyn vydychujeme z pľúc?",
          options: ["Oxid uhličitý", "Kyslík", "Dusík", "Argón"],
          correctAnswer: "Oxid uhličitý",
        },
        {
          question: "Koľko lalokov má pravé pľúce?",
          options: ["Dva", "Tri", "Štyri", "Jeden"],
          correctAnswer: "Tri",
        },
        {
          question: "Ako sa nazýva sval, ktorý pomáha pri dýchaní?",
          options: ["Bránica", "Biceps", "Srdcový sval", "Šijové svaly"],
          correctAnswer: "Bránica",
        },
        {
          question:
            "Ako sa nazývajú najmenšie časti pľúc, kde prebieha výmena plynov?",
          options: ["Bronchy", "Alveoly", "Bronchioly", "Priedušky"],
          correctAnswer: "Alveoly",
        },
        {
          question: "Ktoré pľúca sú zvyčajne menšie?",
          options: ["Ľavé", "Pravé", "Obe sú rovnaké", "Závisí od pohlavia"],
          correctAnswer: "Ľavé",
        },
        {
          question: "Ktorá časť tela riadi dýchanie?",
          options: ["Mozog", "Srdce", "Pečeň", "Pankreas"],
          correctAnswer: "Mozog",
        },
        {
          question:
            "Aký je priemerný počet dychov za minútu u dospelého človeka v kľude?",
          options: ["12 až 16", "20 až 25", "5 až 10", "30 až 35"],
          correctAnswer: "12 až 16",
        },
        {
          question: "Čo spôsobuje astmatický záchvat?",
          options: [
            "Zúženie priedušiek",
            "Poškodenie alveol",
            "Zastavenie srdca",
            "Zvýšený príjem kyslíka",
          ],
          correctAnswer: "Zúženie priedušiek",
        },
        {
          question:
            "Ktoré ochorenie je charakteristické trvalým poškodením pľúcnych alveol?",
          options: ["Astma", "Emfyzém", "Zápal pľúc", "Bronchitída"],
          correctAnswer: "Emfyzém",
        },
        {
          question:
            "Ako sa nazýva ochorenie, ktoré spôsobuje infekcia v pľúcach?",
          options: [
            "Zápal pľúc",
            "Rakovina pľúc",
            "Astma",
            "Chronická bronchitída",
          ],
          correctAnswer: "Zápal pľúc",
        },
        {
          question: "Aký účinok má fajčenie na pľúca?",
          options: [
            "Zlepšuje kapacitu pľúc",
            "Poškodzuje alveoly",
            "Znižuje tvorbu hlienu",
            "Zvyšuje množstvo kyslíka v krvi",
          ],
          correctAnswer: "Poškodzuje alveoly",
        },
      ],
      backgroundImage: require("./categories/human/human-lungs.jpg"),
    },

    {
      name: "Čo vieš o mozgu",
      questions: [
        {
          question:
            "Ktorá časť mozgu je zodpovedná za koordináciu pohybov a rovnováhu?",
          options: ["Cerebrum", "Mozgový kmeň", "Cerebellum", "Hypotalamus"],
          correctAnswer: "Cerebellum",
        },
        {
          question:
            "Ktorá časť mozgu riadi základné životné funkcie, ako je dýchanie a tep srdca?",
          options: ["Hypotalamus", "Cerebrum", "Mozgový kmeň", "Mozoček"],
          correctAnswer: "Mozgový kmeň",
        },
        {
          question:
            "Ktorý lalok mozgu je zodpovedný za spracovanie zrakových informácií?",
          options: [
            "Týlny lalok",
            "Čelový lalok",
            "Temenný lalok",
            "Spánkový lalok",
          ],
          correctAnswer: "Týlny lalok",
        },
        {
          question: "Aká je úloha hipokampu v mozgu?",
          options: [
            "Regulácia emócií",
            "Kontrola svalových pohybov",
            "Tvorba nových spomienok",
            "Spracovanie zrakových podnetov",
          ],
          correctAnswer: "Tvorba nových spomienok",
        },
        {
          question: "Čo je to synapsia?",
          options: [
            "Priestor medzi dvoma neurónmi",
            "Oblasť v mozgu zodpovedná za motorické funkcie",
            "Chemická látka v mozgu",
            "Zhluk neurónov v mozgu",
          ],
          correctAnswer: "Priestor medzi dvoma neurónmi",
        },
        {
          question: "Ktorý typ buniek v mozgu prenáša elektrické signály?",
          options: ["Neuroglie", "Neuróny", "Astrocyty", "Oligodendrocyty"],
          correctAnswer: "Neuróny",
        },
        {
          question:
            "Ktorá časť mozgu je zodpovedná za reguláciu teploty tela, hladu a žízne?",
          options: ["Cerebellum", "Mozgový kmeň", "Hypotalamus", "Týlny lalok"],
          correctAnswer: "Hypotalamus",
        },
        {
          question:
            "Ktorá hemisféra mozgu je spojená s kreativitou a umeleckými schopnosťami?",
          options: [
            "Ľavá hemisféra",
            "Pravá hemisféra",
            "Obe hemisféry rovnako",
            "Žiadna z hemisfér",
          ],
          correctAnswer: "Pravá hemisféra",
        },
        {
          question: "Čo je úlohou amygdaly v mozgu?",
          options: [
            "Spracovanie emócií",
            "Koordinácia pohybov",
            "Tvorba nových spomienok",
            "Regulácia teploty tela",
          ],
          correctAnswer: "Spracovanie emócií",
        },
        {
          question:
            "Ktorá chemická látka v mozgu je spojená s pocitmi šťastia a odmeny?",
          options: ["Dopamín", "Serotonín", "Adrenalín", "GABA"],
          correctAnswer: "Dopamín",
        },
        {
          question:
            "Ako sa nazýva ochranná vrstva, ktorá pokrýva axóny neurónov a zvyšuje rýchlosť prenosu signálov?",
          options: ["Dendrity", "Myelínová pošva", "Synapsia", "Nucleus"],
          correctAnswer: "Myelínová pošva",
        },
        {
          question:
            "Ktorá časť mozgu hrá hlavnú úlohu pri regulácii motorických funkcií?",
          options: [
            "Čelový lalok",
            "Mozoček",
            "Spánkový lalok",
            "Temenný lalok",
          ],
          correctAnswer: "Čelový lalok",
        },
        {
          question:
            "Ktorý lalok je primárne spojený so spracovaním sluchových informácií?",
          options: [
            "Čelový lalok",
            "Týlny lalok",
            "Spánkový lalok",
            "Temenný lalok",
          ],
          correctAnswer: "Spánkový lalok",
        },
        {
          question: "Akú funkciu má temenný lalok mozgu?",
          options: [
            "Spracováva zrakové informácie",
            "Koordinuje pohyb",
            "Reguluje emócie",
            "Spracováva hmatové podnety",
          ],
          correctAnswer: "Spracováva hmatové podnety",
        },
        {
          question:
            "Ktorá časť mozgu je zodpovedná za plánovanie a rozhodovanie?",
          options: [
            "Hypotalamus",
            "Týlny lalok",
            "Čelový lalok",
            "Spánkový lalok",
          ],
          correctAnswer: "Čelový lalok",
        },
      ],
      backgroundImage: require("./categories/human/human-brain.jpg"),
    },

    {
      name: "Čo vieš o srdci",
      questions: [
        {
          question: "Ktorá časť srdca pumpuje okysličenú krv do tela?",
          options: [
            "Ľavá predsieň",
            "Pravá predsieň",
            "Ľavá komora",
            "Pravá komora",
          ],
          correctAnswer: "Ľavá komora",
        },
        {
          question: "Koľko komôr má srdce?",
          options: ["2 komory", "3 komory", "4 komory", "5 komôr"],
          correctAnswer: "4 komory",
        },
        {
          question: "Ktorá krvná cieva prináša okysličenú krv do srdca?",
          options: ["Vena cava", "Aorta", "Pľúcna tepna", "Pľúcna žila"],
          correctAnswer: "Pľúcna žila",
        },
        {
          question: "Čo je úlohou sínusového uzla v srdci?",
          options: [
            "Reguluje krvný tlak",
            "Vytvára elektrické impulzy",
            "Riadí prietok krvi do pľúc",
            "Zabezpečuje okysličenie krvi",
          ],
          correctAnswer: "Vytvára elektrické impulzy",
        },
        {
          question: "Ako sa nazýva vonkajšia vrstva srdca?",
          options: ["Endokard", "Perikard", "Myokard", "Epikard"],
          correctAnswer: "Perikard",
        },
        {
          question: "Aká je hlavná funkcia chlopní v srdci?",
          options: [
            "Zabraňujú spätnému toku krvi",
            "Poháňajú krv cez tepny",
            "Regulujú krvný tlak",
            "Zabraňujú úniku kyslíka",
          ],
          correctAnswer: "Zabraňujú spätnému toku krvi",
        },
        {
          question: "Ktorá časť srdca pumpuje odkysličenú krv do pľúc?",
          options: [
            "Pravá komora",
            "Ľavá predsieň",
            "Pravá predsieň",
            "Ľavá komora",
          ],
          correctAnswer: "Pravá komora",
        },
        {
          question: "Ako sa nazýva najväčšia tepna v tele?",
          options: ["Koronárna tepna", "Aorta", "Vena cava", "Pľúcna tepna"],
          correctAnswer: "Aorta",
        },
        {
          question:
            "Aký je normálny rozsah tepovej frekvencie u dospelého človeka?",
          options: [
            "60-100 úderov za minútu",
            "30-50 úderov za minútu",
            "120-150 úderov za minútu",
            "40-60 úderov za minútu",
          ],
          correctAnswer: "60-100 úderov za minútu",
        },
        {
          question:
            "Ako sa nazýva proces, pri ktorom srdce pumpuje krv do tela?",
          options: ["Cirkulácia", "Peristaltika", "Ventilácia", "Respirácia"],
          correctAnswer: "Cirkulácia",
        },
        {
          question: "Čo je to ischemická choroba srdca?",
          options: [
            "Zápalová choroba srdca",
            "Nedostatočné zásobovanie srdca kyslíkom",
            "Nedostatočný prietok krvi v pľúcach",
            "Zastavenie srdcového tepu",
          ],
          correctAnswer: "Nedostatočné zásobovanie srdca kyslíkom",
        },
        {
          question:
            "Ktorá krvná cieva prenáša odkysličenú krv zo srdca do pľúc?",
          options: ["Aorta", "Koronárna tepna", "Pľúcna tepna", "Vena cava"],
          correctAnswer: "Pľúcna tepna",
        },
        {
          question: "Čo reguluje krvný tlak v srdci?",
          options: ["Chlopne", "Myokard", "Sínusový uzol", "Tepny"],
          correctAnswer: "Chlopne",
        },
        {
          question:
            "Ktorý typ chlopne sa nachádza medzi ľavou komorou a aortou?",
          options: [
            "Aortálna chlopňa",
            "Mitrálna chlopňa",
            "Trikuspidálna chlopňa",
            "Pulmonálna chlopňa",
          ],
          correctAnswer: "Aortálna chlopňa",
        },
        {
          question: "Ako sa volá lekár špecializujúci sa na choroby srdca?",
          options: ["Neurológ", "Endokrinológ", "Kardiológ", "Onkológ"],
          correctAnswer: "Kardiológ",
        },
      ],
      backgroundImage: require("./categories/human/human-heart.jpg"),
    },

    {
      name: "Všeobecné vedomosti o ľudskom tele",
      questions: [
        {
          question: "Koľko kostí má dospelý človek v tele?",
          options: ["206", "212", "198", "220"],
          correctAnswer: "206",
        },
        {
          question: "Ktorý orgán je najväčší vnútorný orgán v ľudskom tele?",
          options: ["Pečeň", "Mozog", "Pľúca", "Srdce"],
          correctAnswer: "Pečeň",
        },
        {
          question: "Aká je priemerná telesná teplota človeka?",
          options: ["36,5°C", "37°C", "36°C", "37,5°C"],
          correctAnswer: "37°C",
        },
        {
          question: "Ktorý sval je najväčší v ľudskom tele?",
          options: [
            "Sedací sval (gluteus maximus)",
            "Stehenný sval (quadriceps)",
            "Lýtko (gastrocnemius)",
            "Triceps",
          ],
          correctAnswer: "Sedací sval (gluteus maximus)",
        },
        {
          question: "Koľko litrov krvi má priemerný dospelý človek?",
          options: ["5 litrov", "3 litre", "6 litrov", "4 litre"],
          correctAnswer: "5 litrov",
        },
        {
          question: "Ktorý orgán je zodpovedný za filtráciu krvi v tele?",
          options: ["Obličky", "Pečeň", "Srdce", "Pľúca"],
          correctAnswer: "Obličky",
        },
        {
          question: "Ktorý zmysel je najcitlivejší v ľudskom tele?",
          options: ["Čuch", "Zrak", "Sluch", "Hmat"],
          correctAnswer: "Čuch",
        },
        {
          question: "Ako sa volá kostra, ktorá chráni mozog?",
          options: ["Lebka", "Chrbtica", "Hrudník", "Rebrá"],
          correctAnswer: "Lebka",
        },
        {
          question: "Aký je hlavný stavebný prvok ľudskej pokožky?",
          options: ["Kolagén", "Keratin", "Elastín", "Melanín"],
          correctAnswer: "Kolagén",
        },
        {
          question: "Koľko zubov má dospelý človek v plnej sade zubov?",
          options: ["32", "28", "30", "34"],
          correctAnswer: "32",
        },
        {
          question: "Ktorá časť mozgu je zodpovedná za pamäť?",
          options: ["Hipokampus", "Cerebrum", "Cerebellum", "Mozgový kmeň"],
          correctAnswer: "Hipokampus",
        },
        {
          question: "Ktorý nerv je najdlhší v ľudskom tele?",
          options: [
            "Sedací nerv",
            "Blúdivý nerv",
            "Zrakový nerv",
            "Ulnárny nerv",
          ],
          correctAnswer: "Sedací nerv",
        },
        {
          question: "Ktorý orgán je zodpovedný za produkciu inzulínu?",
          options: ["Pankreas", "Pečeň", "Obličky", "Slezina"],
          correctAnswer: "Pankreas",
        },
        {
          question:
            "Ako sa nazýva hlavná arteriálna cieva, ktorá vychádza zo srdca?",
          options: ["Aorta", "Koronárne tepny", "Pľúcna tepna", "Žila"],
          correctAnswer: "Aorta",
        },
        {
          question:
            "Ktorý vitamín sa produkuje v ľudskej pokožke pri vystavení slnečnému žiareniu?",
          options: ["Vitamín D", "Vitamín A", "Vitamín C", "Vitamín K"],
          correctAnswer: "Vitamín D",
        },
      ],
      backgroundImage: require("../src/categories/human/human-vseo.jpg"),
    },

    {
      name: "Test o COVID-19",
      questions: [
        {
          question: "Čo je COVID-19?",
          options: [
            "Genetická porucha",
            "Autoimunitné ochorenie",
            "Typ baktérie",
            "Vírus spôsobujúci ochorenie dýchacích ciest",
          ],
          correctAnswer: "Vírus spôsobujúci ochorenie dýchacích ciest",
        },
        {
          question: "Aký je hlavný spôsob prenosu vírusu SARS-CoV-2?",
          options: [
            "Potravinami",
            "Kvapôčkami šírenými vzduchom",
            "Bodnutím hmyzom",
            "Kontaminovanou vodou",
          ],
          correctAnswer: "Kvapôčkami šírenými vzduchom",
        },
        {
          question: "Aké sú bežné príznaky COVID-19?",
          options: [
            "Horúčka, kašeľ, dýchavičnosť",
            "Vyrážky na koži",
            "Bolesť v dolnej časti chrbta",
            "Zlomeniny kostí",
          ],
          correctAnswer: "Horúčka, kašeľ, dýchavičnosť",
        },
        {
          question: "Aké sú preventívne opatrenia proti šíreniu COVID-19?",
          options: [
            "Zvýšená konzumácia vitamínu C",
            "Časté umývanie rúk a nosenie rúšok",
            "Nosenie slnečných okuliarov",
            "Pitný režim",
          ],
          correctAnswer: "Časté umývanie rúk a nosenie rúšok",
        },
        {
          question: "Ako dlho môže trvať inkubačná doba pre COVID-19?",
          options: ["1 až 3 hodiny", "30 dní", "2 až 14 dní", "7 až 10 dní"],
          correctAnswer: "2 až 14 dní",
        },
        {
          question:
            "Ktoré z týchto opatrení sa používa na zníženie šírenia COVID-19?",
          options: [
            "Povinné očkovanie proti všetkým vírusom",
            "Fyzické cvičenie",
            "Nosenie kontaktných šošoviek",
            "Karanténa a izolácia",
          ],
          correctAnswer: "Karanténa a izolácia",
        },
        {
          question: "Ktorý typ vakcíny bol vyvinutý proti COVID-19?",
          options: [
            "Vakcína proti žltačke",
            "Vakcína proti osýpkam",
            "mRNA vakcína",
            "Vakcína proti tetanu",
          ],
          correctAnswer: "mRNA vakcína",
        },
        {
          question:
            "Ktoré z nasledujúcich ochorení má najpodobnejšie príznaky ako COVID-19?",
          options: ["Chrípka", "Diabetes", "Ochorenie srdca", "Lupus"],
          correctAnswer: "Chrípka",
        },
        {
          question: "Ktorý orgánový systém je najviac zasiahnutý COVID-19?",
          options: [
            "Tráviaci systém",
            "Dýchací systém",
            "Endokrinný systém",
            "Nervový systém",
          ],
          correctAnswer: "Dýchací systém",
        },
        {
          question: "Aké sú závažné komplikácie COVID-19?",
          options: [
            "Problémy so zrakom",
            "Vyrážky na koži",
            "Bolesť v kĺboch",
            "Zápal pľúc a zlyhanie dýchania",
          ],
          correctAnswer: "Zápal pľúc a zlyhanie dýchania",
        },
        {
          question:
            "Ktorá skupina ľudí je najviac ohrozená závažným priebehom COVID-19?",
          options: [
            "Mladí zdraví dospelí",
            "Deti do 5 rokov",
            "Športovci",
            "Starší ľudia a ľudia s chronickými ochoreniami",
          ],
          correctAnswer: "Starší ľudia a ľudia s chronickými ochoreniami",
        },
        {
          question:
            "Ktorý z nasledujúcich príznakov nie je typický pre COVID-19?",
          options: [
            "Strata čuchu a chuti",
            "Bolesti hlavy",
            "Vysoká horúčka",
            "Vyrážky na koži",
          ],
          correctAnswer: "Vyrážky na koži",
        },
        {
          question:
            "Ako sa nazýva nový variant vírusu SARS-CoV-2, ktorý vznikol v roku 2021?",
          options: ["Omikron", "Alfa", "Beta", "Gama"],
          correctAnswer: "Omikron",
        },
        {
          question: "Ktorá metóda sa používa na diagnostikovanie COVID-19?",
          options: ["PCR test", "Röntgen", "Ultrazvuk", "Krvné testy"],
          correctAnswer: "PCR test",
        },
        {
          question:
            "Ktorý spôsob liečby je používaný pri ťažkom priebehu COVID-19?",
          options: [
            "Podávanie kyslíka a mechanická ventilácia",
            "Fyzikálna terapia",
            "Chirurgický zákrok",
            "Ožarovanie",
          ],
          correctAnswer: "Podávanie kyslíka a mechanická ventilácia",
        },
      ],
      backgroundImage: require("./categories/human/human-covid19.webp"),
    },

    {
      name: "Pitný režim",
      questions: [
        {
          question:
            "Koľko litrov vody by mal priemerne vypiť dospelý človek denne?",
          options: ["4 litre", "1 liter", "2-3 litre", "5 litrov"],
          correctAnswer: "2-3 litre",
        },
        {
          question: "Aký vplyv má nedostatok vody na ľudský organizmus?",
          options: [
            "Zlepšená koncentrácia",
            "Únava a bolesti hlavy",
            "Zvýšená energia",
            "Znížený pocit hladu",
          ],
          correctAnswer: "Únava a bolesti hlavy",
        },
        {
          question:
            "Aký nápoj by mal byť hlavným zdrojom tekutín v pitnom režime?",
          options: ["Ovocné šťavy", "Voda", "Káva", "Sladené nápoje"],
          correctAnswer: "Voda",
        },
        {
          question: "Ktoré faktory zvyšujú potrebu príjmu tekutín?",
          options: [
            "Sedavý životný štýl",
            "Čítanie knihy",
            "Fyzická aktivita a teplé počasie",
            "Studené počasie",
          ],
          correctAnswer: "Fyzická aktivita a teplé počasie",
        },
        {
          question: "Ako často by mal človek piť počas dňa?",
          options: [
            "Raz za hodinu",
            "Priebežne počas celého dňa",
            "Trikrát denne",
            "Iba keď cíti smäd",
          ],
          correctAnswer: "Priebežne počas celého dňa",
        },
        {
          question:
            "Aký nápoj je nevhodný pri dodržiavaní správneho pitného režimu?",
          options: [
            "Minerálna voda",
            "Voda",
            "Bylinkový čaj",
            "Sladené nápoje",
          ],
          correctAnswer: "Sladené nápoje",
        },
        {
          question: "Čo môže byť výsledkom nadmerného príjmu tekutín?",
          options: [
            "Vysoký krvný tlak",
            "Hyponatriémia (nízka hladina sodíka)",
            "Zvýšená hydratácia",
            "Vylepšená imunita",
          ],
          correctAnswer: "Hyponatriémia (nízka hladina sodíka)",
        },
        {
          question: "Aká je optimálna teplota pitnej vody?",
          options: ["Vriaca", "Izbová teplota", "Ľadovo studená", "Horúca"],
          correctAnswer: "Izbová teplota",
        },
        {
          question: "Ktorý z týchto nápojov prispieva k dehydratácii?",
          options: ["Alkohol", "Voda", "Zelený čaj", "Kokosová voda"],
          correctAnswer: "Alkohol",
        },
        {
          question:
            "Aký je správny postup pri dopĺňaní tekutín po fyzickej aktivite?",
          options: [
            "Čakanie až do pocitu smädu",
            "Napiť sa naraz čo najviac vody",
            "Pitie malých množstiev vody postupne",
            "Vynechať pitie po cvičení",
          ],
          correctAnswer: "Pitie malých množstiev vody postupne",
        },
        {
          question:
            "Ktorý z týchto nápojov je vhodný pre udržanie správneho pitného režimu počas dňa?",
          options: [
            "Bylinkový čaj",
            "Energetický nápoj",
            "Sýtená limonáda",
            "Cola",
          ],
          correctAnswer: "Bylinkový čaj",
        },
        {
          question: "Ako ovplyvňuje pitný režim pokožku?",
          options: [
            "Spôsobuje suchosť pokožky",
            "Zlepšuje hydratáciu a elasticitu pokožky",
            "Nemá žiadny vplyv na pokožku",
            "Zvyšuje tvorbu akné",
          ],
          correctAnswer: "Zlepšuje hydratáciu a elasticitu pokožky",
        },
        {
          question: "Prečo je dôležité piť pred jedlom?",
          options: [
            "Pomáha pri trávení a znižuje chuť do jedla",
            "Podporuje spaľovanie tukov",
            "Znižuje chuť do jedla",
            "Zvyšuje pocit sýtosti",
          ],
          correctAnswer: "Pomáha pri trávení a znižuje chuť do jedla",
        },
        {
          question: "Ktoré z nasledujúcich tvrdení je pravdivé?",
          options: [
            "Najlepšie je vypiť všetku potrebnú vodu naraz",
            "Tekutiny je dobré prijímať v malých dávkach počas celého dňa",
            "Tekutiny treba prijímať iba počas jedla",
            "Počas horúcich dní nie je potrebné zvýšiť príjem tekutín",
          ],
          correctAnswer:
            "Tekutiny je dobré prijímať v malých dávkach počas celého dňa",
        },
        {
          question: "Čo je príznakom dehydratácie?",
          options: [
            "Suchá pokožka a pery",
            "Nadmerné potenie",
            "Zvýšená energia",
            "Znížená potreba piť",
          ],
          correctAnswer: "Suchá pokožka a pery",
        },
      ],
      backgroundImage: require("./categories/human/human-pitny-rezim.jpg"),
    },

    {
      name: "Alkohol a jeho účinky na ľudské telo",
      questions: [
        {
          question:
            "Aký je hlavný účinok alkoholu na centrálny nervový systém?",
          options: [
            "Zvyšuje koncentráciu",
            "Spomaľuje funkcie mozgu",
            "Urýchľuje reflexy",
            "Zlepšuje pamäť",
          ],
          correctAnswer: "Spomaľuje funkcie mozgu",
        },
        {
          question: "Ako alkohol ovplyvňuje reakčný čas človeka?",
          options: [
            "Zlepšuje ho",
            "Zhoršuje ho",
            "Zvyšuje bdelosť",
            "Nemá žiadny vplyv",
          ],
          correctAnswer: "Zhoršuje ho",
        },
        {
          question: "Ktorý orgán je najviac zaťažený pri spracovaní alkoholu?",
          options: ["Obličky", "Pankreas", "Srdce", "Pečeň"],
          correctAnswer: "Pečeň",
        },
        {
          question: "Aký vplyv má dlhodobá konzumácia alkoholu na pečeň?",
          options: [
            "Zvyšuje tvorbu žlče",
            "Nemá žiadny vplyv",
            "Môže viesť k cirhóze",
            "Zlepšuje funkciu pečene",
          ],
          correctAnswer: "Môže viesť k cirhóze",
        },
        {
          question:
            "Čo sa stane s hladinou alkoholu v krvi po vypití jedného štandardného nápoja?",
          options: [
            "Hladina alkoholu v krvi sa zvýši",
            "Hladina alkoholu v krvi sa zníži",
            "Závisí to od rýchlosti metabolizmu",
            "Nemá vplyv na hladinu alkoholu v krvi",
          ],
          correctAnswer: "Hladina alkoholu v krvi sa zvýši",
        },
        {
          question: "Ako môže nadmerné pitie alkoholu ovplyvniť srdce?",
          options: [
            "Zvyšuje riziko srdcových ochorení",
            "Znižuje krvný tlak",
            "Zlepšuje cirkuláciu krvi",
            "Zvyšuje srdcovú výkonnosť",
          ],
          correctAnswer: "Zvyšuje riziko srdcových ochorení",
        },
        {
          question: "Aké sú krátkodobé účinky alkoholu na správanie človeka?",
          options: [
            "Môže spôsobiť agresivitu a zníženú schopnosť rozhodovania",
            "Zlepšuje náladu a reakčné schopnosti",
            "Zlepšuje schopnosť riešiť problémy",
            "Zvyšuje koncentráciu",
          ],
          correctAnswer:
            "Môže spôsobiť agresivitu a zníženú schopnosť rozhodovania",
        },
        {
          question: "Aký je účinok alkoholu na hydratáciu tela?",
          options: [
            "Zlepšuje hydratáciu",
            "Môže spôsobiť dehydratáciu",
            "Nemá vplyv na hydratáciu",
            "Zvyšuje produkciu tekutín",
          ],
          correctAnswer: "Môže spôsobiť dehydratáciu",
        },
        {
          question: "Aké sú riziká konzumácie alkoholu počas tehotenstva?",
          options: [
            "Znižuje riziko komplikácií",
            "Zlepšuje zdravie matky a dieťaťa",
            "Nemá žiadny vplyv na tehotenstvo",
            "Môže spôsobiť fetálny alkoholový syndróm",
          ],
          correctAnswer: "Môže spôsobiť fetálny alkoholový syndróm",
        },
        {
          question: "Ako dlho trvá, kým pečeň spracuje jednu dávku alkoholu?",
          options: ["Približne 1 hodinu", "15 minút", "2 hodiny", "30 minút"],
          correctAnswer: "Približne 1 hodinu",
        },
        {
          question: "Ako alkohol ovplyvňuje schopnosť spánku?",
          options: [
            "Zlepšuje hlboký spánok",
            "Narušuje kvalitu spánku",
            "Nemá vplyv na spánok",
            "Skracuje dobu potrebnú na zaspanie",
          ],
          correctAnswer: "Narušuje kvalitu spánku",
        },
        {
          question: "Čo spôsobuje alkohol v žalúdku?",
          options: [
            "Zvyšuje tvorbu kyselín a podráždenie sliznice",
            "Podporuje vstrebávanie živín",
            "Zlepšenie trávenia",
            "Znižuje tvorbu kyselín",
          ],
          correctAnswer: "Zvyšuje tvorbu kyselín a podráždenie sliznice",
        },
        {
          question: "Aký účinok má alkohol na náladu?",
          options: [
            "Môže spôsobiť depresiu a úzkosť",
            "Zvyšuje sebavedomie a zlepšuje koncentráciu",
            "Nemá žiadny vplyv na náladu",
            "Zlepšuje náladu",
          ],
          correctAnswer: "Môže spôsobiť depresiu a úzkosť",
        },
        {
          question: "Ako alkohol ovplyvňuje motoriku človeka?",
          options: [
            "Zvyšuje rýchlosť pohybov",
            "Zlepšuje pohybové schopnosti",
            "Zhoršuje koordináciu a rovnováhu",
            "Nemá vplyv na motoriku",
          ],
          correctAnswer: "Zhoršuje koordináciu a rovnováhu",
        },
        {
          question:
            "Ktorá z nasledujúcich situácií je riziková pri konzumácii alkoholu?",
          options: [
            "Odpočinok doma",
            "Prechádzka v prírode",
            "Pozeranie televízie",
            "Riadenie vozidla",
          ],
          correctAnswer: "Riadenie vozidla",
        },
      ],
      backgroundImage: require("./categories/human/human-alcohol.jpg"),
    },

    {
      name: "Alkoholizmus",
      questions: [
        {
          question: "Čo je to alkoholizmus?",
          options: [
            "Dočasná preferencia alkoholu pred inými nápojmi",
            "Neschopnosť piť alkohol",
            "Zvýšená tolerancia na alkohol",
            "Chronická závislosť na alkohole",
          ],
          correctAnswer: "Chronická závislosť na alkohole",
        },
        {
          question: "Aké sú hlavné rizikové faktory pre vznik alkoholizmu?",
          options: [
            "Vyhýbanie sa alkoholu",
            "Genetická predispozícia a spoločenský tlak",
            "Nízka úroveň stresu",
            "Zdravý životný štýl",
          ],
          correctAnswer: "Genetická predispozícia a spoločenský tlak",
        },
        {
          question:
            "Ktorý z nasledujúcich príznakov môže byť prejavom alkoholizmu?",
          options: [
            "Pravidelné cvičenie",
            "Silná túžba po alkohole",
            "Dobrá nálada",
            "Občasná konzumácia alkoholu",
          ],
          correctAnswer: "Silná túžba po alkohole",
        },
        {
          question:
            "Aké zdravotné problémy môže spôsobiť dlhodobý alkoholizmus?",
          options: [
            "Zlepšenie pamäti",
            "Zlepšenie funkcie imunitného systému",
            "Zvýšená fyzická kondícia",
            "Poškodenie pečene, srdca a mozgu",
          ],
          correctAnswer: "Poškodenie pečene, srdca a mozgu",
        },
        {
          question: "Aká je úloha rodiny pri liečbe alkoholizmu?",
          options: [
            "Podpora konzumácie alkoholu",
            "Podpora a pomoc pri zotavení",
            "Ignorovanie problému",
            "Izolácia pacienta",
          ],
          correctAnswer: "Podpora a pomoc pri zotavení",
        },
        {
          question: "Aká je jedna z hlavných metód liečby alkoholizmu?",
          options: [
            "Psychoterapia a podpora",
            "Zlepšenie stravovacích návykov",
            "Zvýšená konzumácia alkoholu",
            "Zníženie množstva spánku",
          ],
          correctAnswer: "Psychoterapia a podpora",
        },
        {
          question: "Aký je vplyv alkoholizmu na sociálne vzťahy?",
          options: [
            "Nemá žiadny vplyv na vzťahy",
            "Môže viesť k izolácii a konfliktom",
            "Zvyšuje počet sociálnych kontaktov",
            "Zlepšuje vzťahy s rodinou a priateľmi",
          ],
          correctAnswer: "Môže viesť k izolácii a konfliktom",
        },
        {
          question:
            "Aké sú typické symptómy abstinenčného syndrómu pri alkoholizme?",
          options: [
            "Zlepšenie nálady",
            "Tremor, úzkosť a nespavosť",
            "Zlepšenie koncentrácie",
            "Zvýšená energia",
          ],
          correctAnswer: "Tremor, úzkosť a nespavosť",
        },
        {
          question: "Čo môže byť jedným z prvých krokov v liečbe alkoholizmu?",
          options: [
            "Zvýšená konzumácia alkoholu",
            "Ignorovanie problému",
            "Priznanie problému a hľadanie pomoci",
            "Podpora zo strany priateľov, ktorí tiež pijú",
          ],
          correctAnswer: "Priznanie problému a hľadanie pomoci",
        },
        {
          question: "Aký je vzťah medzi alkoholizmom a duševnými poruchami?",
          options: [
            "Neexistuje žiadny vzťah",
            "Alkoholizmus zlepšuje duševné zdravie",
            "Alkoholizmus lieči depresiu",
            "Alkoholizmus môže zhoršiť duševné poruchy",
          ],
          correctAnswer: "Alkoholizmus môže zhoršiť duševné poruchy",
        },
        {
          question:
            "Ako môže nadmerná konzumácia alkoholu ovplyvniť pracovný výkon?",
          options: [
            "Zníženie produktivity a časté absencie",
            "Zlepšenie pracovného výkonu",
            "Zvyšuje kreativitu a efektivitu",
            "Nemá vplyv na pracovný výkon",
          ],
          correctAnswer: "Zníženie produktivity a časté absencie",
        },
        {
          question: "Aké sú možné dôsledky neliečeného alkoholizmu?",
          options: [
            "Fyzické a psychické problémy, sociálna izolácia",
            "Zlepšenie zdravotného stavu",
            "Zvýšenie finančnej situácie",
            "Zvýšenie spoločenského statusu",
          ],
          correctAnswer: "Fyzické a psychické problémy, sociálna izolácia",
        },
        {
          question:
            "Ktorý z nasledujúcich faktorov môže prispieť k vzniku alkoholizmu?",
          options: [
            "Vyhýbanie sa alkoholu",
            "Silná podpora zo strany rodiny",
            "Zdravý životný štýl",
            "Problémy v rodine, stres a dostupnosť alkoholu",
          ],
          correctAnswer: "Problémy v rodine, stres a dostupnosť alkoholu",
        },
        {
          question: "Ako môže spoločnosť pomôcť znížiť výskyt alkoholizmu?",
          options: [
            "Ignorovanie problému",
            "Vzdelávanie a prevencia",
            "Zníženie vekovej hranice pre konzumáciu alkoholu",
            "Podpora pitia alkoholu",
          ],
          correctAnswer: "Vzdelávanie a prevencia",
        },
        {
          question:
            "Ktorá z nasledujúcich možností môže byť dôsledkom dlhodobého alkoholizmu?",
          options: [
            "Zvýšenie pracovnej výkonnosti",
            "Fetálny alkoholový syndróm",
            "Zvýšenie fyzickej kondície",
            "Zlepšenie zdravia",
          ],
          correctAnswer: "Fetálny alkoholový syndróm",
        },
      ],
      backgroundImage: require("./categories/human/human-alcoholism.jpg"),
    },

    {
      name: "Marihuana a jej účinky na ľudské telo",
      questions: [
        {
          question: "Čo je hlavná psychoaktívna látka v marihuane?",
          options: [
            "Adrenalín",
            "Serotonín",
            "CBD (Kanabidiol)",
            "THC (Tetrahydrokanabinol)",
          ],
          correctAnswer: "THC (Tetrahydrokanabinol)",
        },
        {
          question: "Aký je krátkodobý účinok marihuany na mozog?",
          options: [
            "Zvýšená fyzická výkonnosť",
            "Zlepšenie krátkodobej pamäte",
            "Zlepšenie pozornosti",
            "Zhoršenie koordinácie a koncentrácie",
          ],
          correctAnswer: "Zhoršenie koordinácie a koncentrácie",
        },
        {
          question:
            "Ako môže dlhodobé užívanie marihuany ovplyvniť psychické zdravie?",
          options: [
            "Možnosť vzniku úzkosti a depresie",
            "Znížené riziko psychických porúch",
            "Zvýšenie odolnosti voči stresu",
            "Zlepšenie duševnej stability",
          ],
          correctAnswer: "Možnosť vzniku úzkosti a depresie",
        },
        {
          question:
            "Ktorý z nasledujúcich príznakov je spojený s užívaním marihuany?",
          options: [
            "Zvýšená ostražitosť",
            "Zlepšenie koncentrácie",
            "Zvýšenie energie",
            "Eufória a uvoľnenie",
          ],
          correctAnswer: "Eufória a uvoľnenie",
        },
        {
          question: "Ako môže marihuana ovplyvniť kardiovaskulárny systém?",
          options: [
            "Zlepšuje prekrvenie tela",
            "Znižuje srdcovú frekvenciu",
            "Zvyšuje srdcovú frekvenciu a krvný tlak",
            "Nemá žiadny vplyv na srdce",
          ],
          correctAnswer: "Zvyšuje srdcovú frekvenciu a krvný tlak",
        },
        {
          question:
            "Aký vplyv môže mať marihuana na respiračný systém pri dlhodobom užívaní?",
          options: [
            "Zlepšuje kapacitu pľúc",
            "Zvyšuje riziko respiračných ochorení",
            "Znižuje riziko rakoviny pľúc",
            "Nemá žiadny vplyv na pľúca",
          ],
          correctAnswer: "Zvyšuje riziko respiračných ochorení",
        },
        {
          question: "Ako marihuana ovplyvňuje chuť do jedla?",
          options: [
            "Znižuje chuť do jedla",
            "Zvyšuje chuť do jedla",
            "Spôsobuje nechutenstvo",
            "Nemá žiadny vplyv na chuť do jedla",
          ],
          correctAnswer: "Zvyšuje chuť do jedla",
        },
        {
          question: "Čo je to kanabidiol (CBD)?",
          options: [
            "Hlavný dôvod závislosti na marihuane",
            "Látka používaná iba v syntetických drogách",
            "Psychoaktívna látka spôsobujúca eufóriu",
            "Kanabinoid s liečivými vlastnosťami",
          ],
          correctAnswer: "Kanabinoid s liečivými vlastnosťami",
        },
        {
          question: "Aký je právny status marihuany v mnohých krajinách?",
          options: [
            "Zakázaná alebo legalizovaná na lekárske účely",
            "Voľne predajná v lekárňach",
            "Legálna len na rekreačné účely",
            "Úplne legálna",
          ],
          correctAnswer: "Zakázaná alebo legalizovaná na lekárske účely",
        },
        {
          question: "Ktorý z nasledujúcich účinkov môže marihuana spôsobiť?",
          options: [
            "Zlepšenie sociálnych vzťahov",
            "Zlepšenie výkonu v práci",
            "Zvýšenie kognitívnych schopností",
            "Zhoršenie motorických zručností",
          ],
          correctAnswer: "Zhoršenie motorických zručností",
        },
        {
          question: "Ako môže marihuana ovplyvniť spánok?",
          options: [
            "Môže narušiť spánkový cyklus",
            "Nemá žiadny vplyv na spánok",
            "Zlepšuje kvalitu spánku",
            "Zvyšuje dĺžku hlbokého spánku",
          ],
          correctAnswer: "Môže narušiť spánkový cyklus",
        },
        {
          question: "Aký je hlavný rozdiel medzi THC a CBD?",
          options: [
            "THC je psychoaktívne, CBD nie",
            "CBD spôsobuje závislosť, THC nie",
            "CBD je psychoaktívne, THC nie",
            "THC sa používa na liečbu, CBD nie",
          ],
          correctAnswer: "THC je psychoaktívne, CBD nie",
        },
        {
          question:
            "Ako môže marihuana ovplyvniť duševné zdravie mladých ľudí?",
          options: [
            "Zlepšuje kognitívne funkcie",
            "Zvyšuje pozornosť a koncentráciu",
            "Zvyšuje riziko rozvoja psychických porúch",
            "Znižuje riziko psychických porúch",
          ],
          correctAnswer: "Zvyšuje riziko rozvoja psychických porúch",
        },
        {
          question: "Čo je to tolerance vo vzťahu k užívaniu marihuany?",
          options: [
            "Zníženie účinkov po dlhodobom užívaní",
            "Úplná imunita voči marihuane",
            "Odolnosť voči psychoaktívnym účinkom",
            "Zvýšená citlivosť na účinky marihuany",
          ],
          correctAnswer: "Zníženie účinkov po dlhodobom užívaní",
        },
        {
          question:
            "Aký je potenciálny vplyv marihuany na kognitívne schopnosti?",
          options: [
            "Zlepšenie učenia",
            "Zlepšenie pamäti",
            "Zhoršenie pamäti a pozornosti",
            "Zvýšenie analytických schopností",
          ],
          correctAnswer: "Zhoršenie pamäti a pozornosti",
        },
      ],
      backgroundImage: require("./categories/human/human-canabis.jpg"),
    },

    {
      name: "Cigarety a ich účinky na ľudské telo",
      questions: [
        {
          question:
            "Aké látky sú hlavné zložky tabakového dymu, ktoré sú škodlivé pre zdravie?",
          options: [
            "Kyslík a vodík",
            "Nikotín a decht",
            "Oxid uhličitý a metán",
            "Zinok a meď",
          ],
          correctAnswer: "Nikotín a decht",
        },
        {
          question:
            "Ktoré z nasledujúcich ochorení je najčastejšie spojené s fajčením?",
          options: [
            "Artritída",
            "Hypertension",
            "Diabetes mellitus",
            "Chronická obštrukčná choroba pľúc (CHOPN)",
          ],
          correctAnswer: "Chronická obštrukčná choroba pľúc (CHOPN)",
        },
        {
          question:
            "Aký je hlavný účinok nikotínu na centrálny nervový systém?",
          options: [
            "Zvýšenie hladiny kyslíka v krvi",
            "Zvýšenie telesnej teploty",
            "Stimulácia a zvýšenie krvného tlaku",
            "Zníženie krvného tlaku a depresia",
          ],
          correctAnswer: "Stimulácia a zvýšenie krvného tlaku",
        },
        {
          question: "Ako fajčenie ovplyvňuje zdravie srdca?",
          options: [
            "Zvýšenie rizika srdcových ochorení a infarktu",
            "Zníženie rizika arytmií a angíny",
            "Zlepšenie prekrvenia srdca a znižovanie krvného tlaku",
            "Nemá žiadny vplyv na srdcové zdravie",
          ],
          correctAnswer: "Zvýšenie rizika srdcových ochorení a infarktu",
        },
        {
          question: "Čo je decht a aký má vplyv na zdravie?",
          options: [
            "Prídavná látka, ktorá zvyšuje horľavosť cigarety",
            "Látka, ktorá pôsobí ako antioxidant",
            "Zmes chemických látok, ktorá spôsobuje poškodenie pľúc",
            "Zložka, ktorá zlepšuje chuť cigariet",
          ],
          correctAnswer:
            "Zmes chemických látok, ktorá spôsobuje poškodenie pľúc",
        },
        {
          question:
            "Ktoré z nasledujúcich sú znaky chronickej obštrukčnej choroby pľúc?",
          options: [
            "Kožné vyrážky, suchosť v ústach, problémy s trávením",
            "Zvýšenie telesnej hmotnosti, únava, vysoký krvný tlak",
            "Znížená chuť do jedla, bolesť hlavy, poruchy spánku",
            "Kašeľ, dýchavičnosť, hlien",
          ],
          correctAnswer: "Kašeľ, dýchavičnosť, hlien",
        },
        {
          question: "Aké sú účinky fajčenia na pleť?",
          options: [
            "Zvýšenie elasticity pleti",
            "Zlepšenie farebného tónu pleti",
            "Predčasné starnutie a vrásky",
            "Zníženie výskytu akné",
          ],
          correctAnswer: "Predčasné starnutie a vrásky",
        },
        {
          question: "Aký je vplyv pasívneho fajčenia na zdravie?",
          options: [
            "Zvyšuje riziko respiračných ochorení a rakoviny pľúc",
            "Nemá vplyv na imunitný systém",
            "Zvyšuje produkciu bielych krviniek a zlepšuje ochranu proti infekciám",
            "Zlepšuje obranyschopnosť organizmu a podporuje regeneráciu",
          ],
          correctAnswer: "Zvyšuje riziko respiračných ochorení a rakoviny pľúc",
        },
        {
          question: "Aké sú najčastejšie dôvody, prečo ľudia začínajú fajčiť?",
          options: [
            "Zdravotné prínosy, zníženie únavy",
            "Ekonomické výhody, lepšia chuť jedla",
            "Zvýšenie duševnej výkonnosti, lepšia kvalita spánku",
            "Sociálny tlak, zvedavosť, stres",
          ],
          correctAnswer: "Sociálny tlak, zvedavosť, stres",
        },
        {
          question: "Aké sú hlavné výhody prestania fajčenia?",
          options: [
            "Zvýšenie hmotnosti, zníženie mentálnej clarity",
            "Zlepšenie ústnej hygieny a prevencia zubného kameňa",
            "Zlepšenie dýchacích funkcií, zníženie rizika ochorení",
            "Náklady na cigarety, zníženie finančnej stability",
          ],
          correctAnswer:
            "Zlepšenie dýchacích funkcií, zníženie rizika ochorení",
        },
        {
          question:
            "Ktorá látka v cigaretách je známa svojím karcinogénnym účinkom?",
          options: ["Kalcitónin", "Železo", "Vitamín C", "Formaldehyd"],
          correctAnswer: "Formaldehyd",
        },
        {
          question: "Aké sú dlhodobé účinky fajčenia na pľúca?",
          options: [
            "Zlepšenie funkcie pľúc a zníženie dýchavičnosti",
            "Zvýšená citlivosť zubov a zníženie výskytu ďasnových zápalov",
            "Zvýšené riziko zubného kazu, zápal ďasien a zlý dych",
            "Chronická obštrukčná choroba pľúc (CHOPN) a rakovina pľúc",
          ],
          correctAnswer:
            "Chronická obštrukčná choroba pľúc (CHOPN) a rakovina pľúc",
        },
        {
          question:
            "Ako fajčenie ovplyvňuje schopnosť tela bojovať proti infekciám?",
          options: [
            "Znižuje imunitnú odpoveď a zvyšuje náchylnosť na infekcie",
            "Nemá vplyv na imunitný systém",
            "Zvyšuje produkciu bielych krviniek a zlepšuje ochranu proti infekciám",
            "Zlepšuje obranyschopnosť organizmu a podporuje regeneráciu",
          ],
          correctAnswer:
            "Znižuje imunitnú odpoveď a zvyšuje náchylnosť na infekcie",
        },
        {
          question: "Aké sú účinky fajčenia na zubné zdravie?",
          options: [
            "Zlepšenie ústnej hygieny a prevencia zubného kameňa",
            "Zvýšená citlivosť zubov a zníženie výskytu ďasnových zápalov",
            "Zvýšené riziko zubného kazu, zápal ďasien a zlý dych",
            "Zlepšenie pevnosti zubov a zníženie rizika kazov",
          ],
          correctAnswer: "Zvýšené riziko zubného kazu, zápal ďasien a zlý dych",
        },
      ],
      backgroundImage: require("./categories/human/human-cigarette.jpg"),
    },
  ],
  tech: [
    {
      name: "Ako funguje mikroskop",
      questions: [
        {
          question: "Aký je hlavný účel mikroskopu?",
          options: [
            "Zväčšiť malé objekty",
            "Znížiť osvetlenie",
            "Zachytávať pohyb",
            "Zmerať rýchlosť svetla",
          ],
          correctAnswer: "Zväčšiť malé objekty",
        },
        {
          question:
            "Aký druh šošovky sa používa v optickom mikroskope na zväčšenie obrazu?",
          options: [
            "Konvexná šošovka",
            "Konkávna šošovka",
            "Cylindrická šošovka",
            "Planárna šošovka",
          ],
          correctAnswer: "Konvexná šošovka",
        },
        {
          question: "Čo robí okulár v mikroskope?",
          options: [
            "Zväčšuje obraz pre oko pozorovateľa",
            "Zachytáva svetlo z objektu",
            "Osvetľuje vzorku",
            "Zaostruje šošovky",
          ],
          correctAnswer: "Zväčšuje obraz pre oko pozorovateľa",
        },
        {
          question: "Čo je to objektív mikroskopu?",
          options: [
            "Šošovka, ktorá zväčšuje objekt",
            "Svetelný zdroj",
            "Platforma pre vzorku",
            "Rám na uchopenie mikroskopu",
          ],
          correctAnswer: "Šošovka, ktorá zväčšuje objekt",
        },
        {
          question: "Akú funkciu plní revolverová hlava mikroskopu?",
          options: [
            "Drží a umožňuje výmenu objektívov",
            "Osvetľuje vzorku",
            "Umožňuje otáčanie vzorky",
            "Nastavuje zameranie šošoviek",
          ],
          correctAnswer: "Drží a umožňuje výmenu objektívov",
        },
        {
          question: "Čo je úlohou kondenzora v mikroskope?",
          options: [
            "Zosilňuje a sústreďuje svetlo na vzorku",
            "Zväčšuje obraz",
            "Zníži intenzitu svetla",
            "Zachytáva obraz vzorky",
          ],
          correctAnswer: "Zosilňuje a sústreďuje svetlo na vzorku",
        },
        {
          question: "Prečo je dôležité správne zaostrenie v mikroskope?",
          options: [
            "Na získanie ostrého a jasného obrazu",
            "Na ochranu vzorky pred poškodením",
            "Na reguláciu svetelnej intenzity",
            "Na úpravu kontrastu obrazu",
          ],
          correctAnswer: "Na získanie ostrého a jasného obrazu",
        },
        {
          question:
            "Aký je rozdiel medzi svetelným mikroskopom a elektrónovým mikroskopom?",
          options: [
            "Svetelný používa svetlo, elektrónový elektróny",
            "Elektrónový používa šošovky, svetelný zrkadlá",
            "Svetelný zväčšuje viac ako elektrónový",
            "Elektrónový používa svetlo, svetelný magnetické polia",
          ],
          correctAnswer: "Svetelný používa svetlo, elektrónový elektróny",
        },
        {
          question: "Na čo sa používa jemné zaostrovanie mikroskopu?",
          options: [
            "Presné zaostrenie obrazu",
            "Zväčšenie obrazu",
            "Nastavenie intenzity osvetlenia",
            "Otočenie vzorky",
          ],
          correctAnswer: "Presné zaostrenie obrazu",
        },
        {
          question: "Akú úlohu má svetelný zdroj v mikroskope?",
          options: [
            "Osvetľuje vzorku",
            "Zaostruje obraz",
            "Zväčšuje objekt",
            "Filtruje nežiaduce spektrá svetla",
          ],
          correctAnswer: "Osvetľuje vzorku",
        },
        {
          question: "Ktorá časť mikroskopu slúži na držanie a pohyb vzorky?",
          options: ["Stolík", "Okulár", "Objektív", "Kondenzor"],
          correctAnswer: "Stolík",
        },
        {
          question:
            "Aká je hlavná výhoda elektrónového mikroskopu oproti svetelnému mikroskopu?",
          options: [
            "Väčšie rozlíšenie",
            "Lepšie osvetlenie",
            "Jednoduchšia obsluha",
            "Nižšie náklady",
          ],
          correctAnswer: "Väčšie rozlíšenie",
        },
        {
          question: "Čo spôsobí, ak je obraz pod mikroskopom rozmazaný?",
          options: [
            "Zlé zaostrenie",
            "Príliš veľa svetla",
            "Príliš malý objektív",
            "Nesprávne usporiadanie šošoviek",
          ],
          correctAnswer: "Zlé zaostrenie",
        },
        {
          question: "Aký je účel veľkého zaostrovacieho kolieska?",
          options: [
            "Hrubé zaostrenie obrazu",
            "Otočenie vzorky",
            "Nastavenie intenzity svetla",
            "Výmena objektívov",
          ],
          correctAnswer: "Hrubé zaostrenie obrazu",
        },
        {
          question: "Aký faktor ovplyvňuje maximálne zväčšenie mikroskopu?",
          options: [
            "Kvalita šošoviek",
            "Veľkosť stolíka",
            "Počet vzoriek",
            "Rýchlosť svetelného zdroja",
          ],
          correctAnswer: "Kvalita šošoviek",
        },
      ],
      backgroundImage: require("./categories/tech/tech-mikroskop.jpg"),
    },

    {
      name: "Ako funguje teleskop",
      questions: [
        {
          question: "Aký je hlavný účel teleskopu?",
          options: [
            "Zväčšovať vzdialené objekty",
            "Zachytávať slnečné svetlo",
            "Zmerať rýchlosť hviezd",
            "Fotografovať objekty v blízkosti Zeme",
          ],
          correctAnswer: "Zväčšovať vzdialené objekty",
        },
        {
          question: "Aký typ šošovky sa používa v refrakčnom teleskope?",
          options: [
            "Konvexná šošovka",
            "Konkávna šošovka",
            "Planárna šošovka",
            "Parabolická šošovka",
          ],
          correctAnswer: "Konvexná šošovka",
        },
        {
          question: "Čo je úlohou zrkadla v reflektore?",
          options: [
            "Odraziť a zaostriť svetlo",
            "Rozptyľovať svetlo",
            "Zmeniť farbu svetla",
            "Absorbovať zbytočné svetlo",
          ],
          correctAnswer: "Odraziť a zaostriť svetlo",
        },
        {
          question: "Akú funkciu má okulár v teleskope?",
          options: [
            "Zväčšuje obraz zachytený objektívom",
            "Osvetľuje pozorovaný objekt",
            "Zachytáva svetlo z hviezd",
            "Filtruje neviditeľné spektrá",
          ],
          correctAnswer: "Zväčšuje obraz zachytený objektívom",
        },
        {
          question:
            "Čo spôsobuje rozmazanie obrazu pri pozorovaní cez teleskop?",
          options: [
            "Atmosférická turbulencia",
            "Slabé svetelné podmienky",
            "Nedostatočné zaostrenie šošovky",
            "Rýchly pohyb objektu",
          ],
          correctAnswer: "Atmosférická turbulencia",
        },
        {
          question: "Čo je to ohnisková vzdialenosť teleskopu?",
          options: [
            "Vzdialenosť od šošovky k bodu zaostrenia",
            "Priemer objektívu",
            "Dĺžka okuláru",
            "Vzdialenosť od zrkadla k objektívu",
          ],
          correctAnswer: "Vzdialenosť od šošovky k bodu zaostrenia",
        },
        {
          question: "Ktorý teleskop používa sústavu šošoviek na zber svetla?",
          options: ["Refraktor", "Reflektor", "Radioteleskop", "Mikroskop"],
          correctAnswer: "Refraktor",
        },
        {
          question:
            "Aký druh teleskopu využíva rádiové vlny na pozorovanie vesmíru?",
          options: [
            "Radioteleskop",
            "Optický teleskop",
            "Refraktor",
            "Infračervený teleskop",
          ],
          correctAnswer: "Radioteleskop",
        },
        {
          question: "Prečo sa veľké observatóriá stavajú na vrcholkoch hôr?",
          options: [
            "Aby sa minimalizoval vplyv atmosféry",
            "Lepšie prístupné pre vedcov",
            "Kvôli nízkym teplotám",
            "Kvôli ochrane pred vetrom",
          ],
          correctAnswer: "Aby sa minimalizoval vplyv atmosféry",
        },
        {
          question:
            "Ktorá časť teleskopu slúži na zachytenie a sústredenie svetla?",
          options: [
            "Objektív alebo zrkadlo",
            "Okulár",
            "Montážna sústava",
            "Ohnisko",
          ],
          correctAnswer: "Objektív alebo zrkadlo",
        },
        {
          question: "Čo sa stane, ak je ohnisková vzdialenosť príliš krátka?",
          options: [
            "Obraz bude príliš rozostrený",
            "Teleskop zachytí viac svetla",
            "Svetlo nebude zaostrené správne",
            "Obraz bude príliš tmavý",
          ],
          correctAnswer: "Svetlo nebude zaostrené správne",
        },
        {
          question: "Čo je montážna sústava teleskopu?",
          options: [
            "Systém, ktorý drží a nasmeruje teleskop",
            "Šošovka na zväčšenie obrazu",
            "Zrkadlo na odraz svetla",
            "Súčasť, ktorá filtruje atmosférické rušenie",
          ],
          correctAnswer: "Systém, ktorý drží a nasmeruje teleskop",
        },
        {
          question:
            "Prečo sa používajú adaptívne optické systémy v moderných teleskopoch?",
          options: [
            "Na kompenzáciu atmosférických deformácií",
            "Na zníženie svetelného znečistenia",
            "Na zlepšenie zväčšenia obrazu",
            "Na ochranu teleskopu pred vetrom",
          ],
          correctAnswer: "Na kompenzáciu atmosférických deformácií",
        },
        {
          question: "Ktorý faktor ovplyvňuje zber svetla v teleskope?",
          options: [
            "Priemer objektívu alebo zrkadla",
            "Dĺžka okuláru",
            "Rýchlosť rotácie Zeme",
            "Uhol pozorovania",
          ],
          correctAnswer: "Priemer objektívu alebo zrkadla",
        },
        {
          question:
            "Ktorý teleskop je najvhodnejší na pozorovanie hlbokého vesmíru?",
          options: [
            "Veľký reflektor",
            "Malý refraktor",
            "Ručný ďalekohľad",
            "Radioteleskop",
          ],
          correctAnswer: "Veľký reflektor",
        },
      ],
      backgroundImage: require("./categories/tech/tech-teleskop.jpg"),
    },

    {
      name: "Ako funguje benzínový spaľovací motor",
      questions: [
        {
          question: "Aká je hlavná funkcia karburátora v benzínovom motore?",
          options: [
            "Zmiešava palivo so vzduchom",
            "Zapaľuje zmes paliva a vzduchu",
            "Otvára výfukový ventil",
            "Riadi vstupný ventil",
          ],
          correctAnswer: "Zmiešava palivo so vzduchom",
        },
        {
          question: "Čo je výsledkom spaľovania v benzínovom motore?",
          options: [
            "Výfukové plyny a teplo",
            "Chlad a tlak",
            "Elektrická energia",
            "Mechanická práca a svetlo",
          ],
          correctAnswer: "Výfukové plyny a teplo",
        },
        {
          question:
            "Aký je hlavný rozdiel medzi benzínovým a dieselovým motorom?",
          options: [
            "Spôsob zapaľovania zmesi paliva",
            "Počet piestov",
            "Tlak v spaľovacej komore",
            "Účinnosť chladenia",
          ],
          correctAnswer: "Spôsob zapaľovania zmesi paliva",
        },
        {
          question: "Aká časť motora zapaľuje zmes paliva a vzduchu?",
          options: [
            "Zapaľovacia sviečka",
            "Piest",
            "Vačkový hriadeľ",
            "Kľukový hriadeľ",
          ],
          correctAnswer: "Zapaľovacia sviečka",
        },
        {
          question: "Čo je kompresný zdvih v štvortaktnom motore?",
          options: [
            "Stlačenie zmesi paliva a vzduchu",
            "Vstrekovanie paliva",
            "Otvorenie výfukového ventilu",
            "Nasávanie vzduchu",
          ],
          correctAnswer: "Stlačenie zmesi paliva a vzduchu",
        },
        {
          question: "Čo sa stane pri výbuchu v spaľovacej komore?",
          options: [
            "Vytvára sa tlak na piest",
            "Otvorí sa nasávací ventil",
            "Piest sa pohne nahor",
            "Vstrekne sa viac paliva",
          ],
          correctAnswer: "Vytvára sa tlak na piest",
        },
        {
          question: "Akú funkciu plní kľukový hriadeľ?",
          options: [
            "Premieňa pohyb piestu na rotačný pohyb",
            "Otáča vačkový hriadeľ",
            "Otvára a zatvára ventily",
            "Reguluje vstrekovanie paliva",
          ],
          correctAnswer: "Premieňa pohyb piestu na rotačný pohyb",
        },
        {
          question: "Aká je funkcia chladiaceho systému motora?",
          options: [
            "Zabraňuje prehriatiu motora",
            "Zvyšuje tlak v spaľovacej komore",
            "Ohrieva palivovú zmes",
            "Čistí výfukové plyny",
          ],
          correctAnswer: "Zabraňuje prehriatiu motora",
        },
        {
          question: "Čo je potrebné pre správnu funkciu štvortaktného motora?",
          options: [
            "Nasávací, kompresný, pracovný a výfukový zdvih",
            "Iba nasávací a kompresný zdvih",
            "Dva cykly - nasávací a pracovný",
            "Tri cykly - kompresný, pracovný a výfukový",
          ],
          correctAnswer: "Nasávací, kompresný, pracovný a výfukový zdvih",
        },
        {
          question:
            "Ktorá časť motora je zodpovedná za otváranie a zatváranie ventilov?",
          options: [
            "Vačkový hriadeľ",
            "Piest",
            "Kľukový hriadeľ",
            "Zapaľovacia sviečka",
          ],
          correctAnswer: "Vačkový hriadeľ",
        },
        {
          question: "Čo je úlohou výfukového ventilu?",
          options: [
            "Uvoľniť výfukové plyny z motora",
            "Vstrekovať palivo",
            "Kompresovať vzduch",
            "Nasávať vzduch do motora",
          ],
          correctAnswer: "Uvoľniť výfukové plyny z motora",
        },
        {
          question:
            "Čo sa stane, ak zapaľovacia sviečka nezapáli palivovú zmes?",
          options: [
            "Motor nevyprodukuje výkon",
            "Piest sa zastaví v najvyššej polohe",
            "Nasávací ventil sa otvorí",
            "Palivo sa nebude miešať so vzduchom",
          ],
          correctAnswer: "Motor nevyprodukuje výkon",
        },
        {
          question: "Čo je úlohou piestu v benzínovom motore?",
          options: [
            "Premieňa tlak na mechanický pohyb",
            "Riadi činnosť ventilov",
            "Reguluje zmes paliva a vzduchu",
            "Znižuje teplotu v spaľovacej komore",
          ],
          correctAnswer: "Premieňa tlak na mechanický pohyb",
        },
        {
          question: "Aký proces prebieha v motore počas pracovného zdvihu?",
          options: [
            "Zmes paliva a vzduchu sa zapáli a rozťahuje",
            "Nasáva sa vzduch do motora",
            "Výfukové plyny sa vypúšťajú",
            "Piest sa pohybuje smerom nahor",
          ],
          correctAnswer: "Zmes paliva a vzduchu sa zapáli a rozťahuje",
        },
        {
          question: "Čo sa deje počas výfukového zdvihu?",
          options: [
            "Výfukové plyny sa vypúšťajú z motora",
            "Vstrekne sa palivo",
            "Nasáva sa čerstvý vzduch",
            "Zmes paliva sa zmieša so vzduchom",
          ],
          correctAnswer: "Výfukové plyny sa vypúšťajú z motora",
        },
      ],
      backgroundImage: require("./categories/tech/tech-benzinovy-motor.jpg"),
    },

    {
      name: "Test: Ako funguje tlačiareň",
      questions: [
        {
          question: "Čo je hlavnou funkciou tlačiarne?",
          options: [
            "Tlačiť dokumenty",
            "Skenovať obrázky",
            "Uchovávať dáta",
            "Pripojiť sa na internet",
          ],
          correctAnswer: "Tlačiť dokumenty",
        },
        {
          question: "Aké typy tlačiarní existujú?",
          options: [
            "Inkjet a laserové",
            "Bluetooth a Wi-Fi",
            "Webové a mobilné",
            "Káblové a bezdrôtové",
          ],
          correctAnswer: "Inkjet a laserové",
        },
        {
          question: "Aký typ tlačiarne využíva atramentové kazety?",
          options: ["Inkjet", "Laserová", "Tepelná", "Maticová"],
          correctAnswer: "Inkjet",
        },
        {
          question: "Ako funguje laserová tlačiareň?",
          options: [
            "Používa laser na vytvorenie obrazu na fotocitlivom valci",
            "Vytvára obraz pomocou atramentu",
            "Používa teplo na tlačenie obrázkov",
            "Používa ihly na vytvorenie obrazu",
          ],
          correctAnswer:
            "Používa laser na vytvorenie obrazu na fotocitlivom valci",
        },
        {
          question: "Čo je to fotocitlivý valec v laserovej tlačiarni?",
          options: [
            "Komponent, na ktorý sa vytvára obraz pomocou laseru",
            "Časť tlačiarne, ktorá uchováva atrament",
            "Snímač, ktorý zistí, či je papier vložený",
            "Diel, ktorý tlačí atrament na papier",
          ],
          correctAnswer: "Komponent, na ktorý sa vytvára obraz pomocou laseru",
        },
        {
          question: "Čo je to toner v laserovej tlačiarni?",
          options: [
            "Prášková substancia, ktorá sa používa na tlač",
            "Kvapalina používaná na čistenie tlačiarne",
            "Elektronické zariadenie na uchovávanie dát",
            "Papier určený na špeciálnu tlač",
          ],
          correctAnswer: "Prášková substancia, ktorá sa používa na tlač",
        },
        {
          question: "Čo je hlavný rozdiel medzi inkjet a laserovou tlačiarňou?",
          options: [
            "Inkjet tlačiarne používajú atrament, zatiaľ čo laserové používajú toner",
            "Laserové tlačiarne sú menšie",
            "Inkjet tlačiarne sú rýchlejšie",
            "Laserové tlačiarne sú hlučnejšie",
          ],
          correctAnswer:
            "Inkjet tlačiarne používajú atrament, zatiaľ čo laserové používajú toner",
        },
        {
          question: "Čo je to duplexná tlač?",
          options: [
            "Tlač obojstranne na papier",
            "Tlač na rôzne formáty papiera",
            "Tlač viacerých kópií jedného dokumentu",
            "Tlač s rôznymi farbami",
          ],
          correctAnswer: "Tlač obojstranne na papier",
        },
        {
          question: "Aký je úlohou vyhrievaného valca v laserovej tlačiarni?",
          options: [
            "Fixovať toner na papier",
            "Vytvárať obraz pomocou laseru",
            "Zabezpečiť, aby bol papier rovný",
            "Čistiť tlačiarenské hlavy",
          ],
          correctAnswer: "Fixovať toner na papier",
        },
        {
          question: "Čo je to matica ihiel v maticovej tlačiarni?",
          options: [
            "Súbor ihiel, ktoré vytvárajú obrázok na papieri",
            "Súčasť, ktorá zahrieva papier",
            "Typ toneru používaný v tlačiarni",
            "Typ papiera, ktorý sa používa",
          ],
          correctAnswer: "Súbor ihiel, ktoré vytvárajú obrázok na papieri",
        },
        {
          question: "Ako funguje atramentová tlačiareň?",
          options: [
            "Vystrekne atrament cez trysky na papier",
            "Používa laser na vytvorenie obrázku",
            "Vytvára obraz pomocou ihiel",
            "Tlačí na papier pomocou tepla",
          ],
          correctAnswer: "Vystrekne atrament cez trysky na papier",
        },
        {
          question: "Čo je to rozlíšenie tlače?",
          options: [
            "Miera detailov v tlačenom obrázku",
            "Rýchlosť tlače",
            "Veľkosť tlačiarne",
            "Kapacita atramentových kaziet",
          ],
          correctAnswer: "Miera detailov v tlačenom obrázku",
        },
        {
          question: "Čo je to kapacita zásobníka papiera?",
          options: [
            "Počet listov papiera, ktoré tlačiareň dokáže uložiť",
            "Rýchlosť tlače",
            "Typ papiera, ktorý tlačiareň používa",
            "Farba tlače",
          ],
          correctAnswer: "Počet listov papiera, ktoré tlačiareň dokáže uložiť",
        },
        {
          question: "Aké sú bežné typy papiera používané v tlačiarňach?",
          options: [
            "A4, A3, Letter",
            "USB, Ethernet, Wi-Fi",
            "Inkjet, Laser",
            "Toner, Atrament",
          ],
          correctAnswer: "A4, A3, Letter",
        },
        {
          question: "Aké technológie sa používajú na bezdrôtovú tlač?",
          options: [
            "Wi-Fi a Bluetooth",
            "USB a Ethernet",
            "NFC a HDMI",
            "SD a microSD",
          ],
          correctAnswer: "Wi-Fi a Bluetooth",
        },
        {
          question:
            "Ako sa zvyčajne monitoruje stav atramentových kaziet v tlačiarni?",
          options: [
            "Pomocou indikátora na obrazovke tlačiarne",
            "Pomocou USB kábla",
            "Pomocou zvukového signálu",
            "Pomocou farebného papiera",
          ],
          correctAnswer: "Pomocou indikátora na obrazovke tlačiarne",
        },
        {
          question: "Čo je to termálna tlačiareň?",
          options: [
            "Tlačiareň, ktorá využíva teplo na vytvorenie obrázku na papieri",
            "Tlačiareň, ktorá používa atrament",
            "Tlačiareň s maticou ihiel",
            "Tlačiareň, ktorá funguje na báze laseru",
          ],
          correctAnswer:
            "Tlačiareň, ktorá využíva teplo na vytvorenie obrázku na papieri",
        },
        {
          question: "Aký typ tlačiarne je najvhodnejší na tlač fotografií?",
          options: ["Inkjet", "Laserová", "Maticová", "Termálna"],
          correctAnswer: "Inkjet",
        },
        {
          question: "Čo je to ICC profil v tlači?",
          options: [
            "Špecifikácia farieb pre presnú tlač",
            "Typ toneru",
            "Model tlačiarne",
            "Formát papiera",
          ],
          correctAnswer: "Špecifikácia farieb pre presnú tlač",
        },
        {
          question: "Aké sú výhody laserových tlačiarní oproti atramentovým?",
          options: [
            "Rýchlosť tlače a náklady na stránku",
            "Kvalita farebnej tlače",
            "Schopnosť tlačiť na rôzne typy papiera",
            "Možnosť skenovania",
          ],
          correctAnswer: "Rýchlosť tlače a náklady na stránku",
        },
        {
          question: "Čo je to kalibrácia tlačiarne?",
          options: [
            "Proces prispôsobenia tlačiarne na dosiahnutie presných farieb",
            "Čistenie tlačiarne",
            "Vymieňanie atramentových kaziet",
            "Nastavenie pripojenia na Wi-Fi",
          ],
          correctAnswer:
            "Proces prispôsobenia tlačiarne na dosiahnutie presných farieb",
        },
        {
          question: "Čo je to print spooler v operačnom systéme?",
          options: [
            "Služba na spravovanie tlačových úloh",
            "Softvér na úpravu obrázkov",
            "Hardware na zvýšenie rýchlosti tlače",
            "Typ toneru",
          ],
          correctAnswer: "Služba na spravovanie tlačových úloh",
        },
        {
          question: "Aká je funkcia papierového podávača v tlačiarni?",
          options: [
            "Automatické podávanie papiera do tlačového mechanizmu",
            "Tepelná úprava papiera",
            "Zasúvanie atramentových kaziet",
            "Skenovanie dokumentov",
          ],
          correctAnswer:
            "Automatické podávanie papiera do tlačového mechanizmu",
        },
        {
          question: "Aký je účel tlačového papiera v tlačiarni?",
          options: [
            "Poskytuje podklad na tlač",
            "Chráni tlačiareň pred prachom",
            "Skladá sa z atramentu",
            "Umožňuje pripojenie na internet",
          ],
          correctAnswer: "Poskytuje podklad na tlač",
        },
        {
          question: "Čo je to driver tlačiarne?",
          options: [
            "Softvér, ktorý umožňuje počítaču komunikovať s tlačiarňou",
            "Fyzická súčasť tlačiarne",
            "Typ papiera",
            "Súčasť atramentovej kazety",
          ],
          correctAnswer:
            "Softvér, ktorý umožňuje počítaču komunikovať s tlačiarňou",
        },
        {
          question: "Čo je to “printer queue”?",
          options: [
            "Zoznam dokumentov čakajúcich na tlač",
            "Zoznam všetkých tlačiarní v sieti",
            "Zoznam chýb tlačiarne",
            "Zoznam nastavení tlačiarne",
          ],
          correctAnswer: "Zoznam dokumentov čakajúcich na tlač",
        },
        {
          question: "Ako funguje bezdrôtová tlač?",
          options: [
            "Pomocou Wi-Fi alebo Bluetooth pripojenia",
            "Pomocou USB kábla",
            "Pomocou káblového pripojenia",
            "Pomocou pripojenia cez ethernetový kábel",
          ],
          correctAnswer: "Pomocou Wi-Fi alebo Bluetooth pripojenia",
        },
        {
          question:
            "Aký typ tlačiarne je najčastejšie používaný v kanceláriách?",
          options: ["Laserová", "Inkjet", "Termálna", "Maticová"],
          correctAnswer: "Laserová",
        },
        {
          question: "Ako často je potrebné čistiť tlačiarenské hlavy?",
          options: [
            "Pri zníženej kvalite tlače alebo po určitých počtoch strán",
            "Každý týždeň",
            "Každý mesiac",
            "Každé tri mesiace",
          ],
          correctAnswer:
            "Pri zníženej kvalite tlače alebo po určitých počtoch strán",
        },
        {
          question: "Čo znamená dpi v kontexte tlače?",
          options: [
            "Počet bodov na palec",
            "Počet strán za minútu",
            "Veľkosť tlačiarne",
            "Kapacita atramentových kaziet",
          ],
          correctAnswer: "Počet bodov na palec",
        },
        {
          question: "Aký je účel zásobníka na papier v tlačiarni?",
          options: [
            "Uložiť papier pred tlačením",
            "Zlepšiť kvalitu tlače",
            "Zabezpečiť pripojenie na internet",
            "Uchovávať atramentové kazety",
          ],
          correctAnswer: "Uložiť papier pred tlačením",
        },
        {
          question:
            "Aký je hlavný účel fusingovej jednotky v laserovej tlačiarni?",
          options: [
            "Fixovať toner na papier pomocou tepla",
            "Vytvárať obraz pomocou laseru",
            "Čistiť tlačiarenské hlavy",
            "Zabezpečiť pripojenie na Wi-Fi",
          ],
          correctAnswer: "Fixovať toner na papier pomocou tepla",
        },
        {
          question: "Čo je to print head v inkjet tlačiarni?",
          options: [
            "Časť, ktorá vystrekne atrament na papier",
            "Časť, ktorá uchováva papier",
            "Súčasť, ktorá fixuje toner",
            "Snímač pre detekciu papiera",
          ],
          correctAnswer: "Časť, ktorá vystrekne atrament na papier",
        },
        {
          question: "Aké sú bežné problémy s inkjet tlačiarňami?",
          options: [
            "Zasychanie atramentu a upchatie hláv",
            "Nízka kapacita zásobníka papiera",
            "Rýchla spotreba tonera",
            "Problémy s pripojením na Wi-Fi",
          ],
          correctAnswer: "Zasychanie atramentu a upchatie hláv",
        },
        {
          question: "Čo znamená network printer?",
          options: [
            "Tlačiareň, ktorá je pripojená k sieti a môže byť zdieľaná medzi viacerými používateľmi",
            "Tlačiareň pripojená len cez USB",
            "Tlačiareň, ktorá nemá žiadne pripojenie",
            "Tlačiareň s integrovaným skenerom",
          ],
          correctAnswer:
            "Tlačiareň, ktorá je pripojená k sieti a môže byť zdieľaná medzi viacerými používateľmi",
        },
        {
          question: "Aké sú výhody laserovej tlače?",
          options: [
            "Rýchlosť a nízke náklady na stránku",
            "Vyššia kvalita farebnej tlače",
            "Schopnosť tlačiť na rôzne typy papiera",
            "Možnosť skenovania",
          ],
          correctAnswer: "Rýchlosť a nízke náklady na stránku",
        },
        {
          question: "Čo je to print density?",
          options: [
            "Miera intenzity tlače",
            "Počet strán za minútu",
            "Veľkosť tlačiarne",
            "Kapacita atramentových kaziet",
          ],
          correctAnswer: "Miera intenzity tlače",
        },
        {
          question:
            "Ako sa volá proces, pri ktorom tlačiareň prenáša obraz na papier?",
          options: ["Tlač", "Skenovanie", "Kopírovanie", "Digitalizácia"],
          correctAnswer: "Tlač",
        },
        {
          question: "Aký je účel duplexnej tlače?",
          options: [
            "Tlačiť obojstranne na papier",
            "Zvýšiť rýchlosť tlače",
            "Zmenšiť veľkosť dokumentu",
            "Zvýšiť kapacitu zásobníka papiera",
          ],
          correctAnswer: "Tlačiť obojstranne na papier",
        },
        {
          question: "Čo je to print cartridge?",
          options: [
            "Tlačová kazeta, ktorá obsahuje atrament alebo toner",
            "Súčasť, ktorá uchováva papier",
            "Typ softvéru pre tlačiareň",
            "Súčasť na pripojenie k počítaču",
          ],
          correctAnswer: "Tlačová kazeta, ktorá obsahuje atrament alebo toner",
        },
        {
          question: "Čo je automatic document feeder (ADF)?",
          options: [
            "Súčasť, ktorá automaticky podáva viacero strán na tlač",
            "Súčasť na manuálne podávanie papiera",
            "Typ skenera",
            "Súčasť, ktorá čistí tlačiarenské hlavy",
          ],
          correctAnswer:
            "Súčasť, ktorá automaticky podáva viacero strán na tlač",
        },
        {
          question: "Aké faktory ovplyvňujú rýchlosť tlače?",
          options: [
            "Rozlíšenie tlače, typ tlačiarne a veľkosť dokumentu",
            "Farba papiera a typ atramentu",
            "Kapacita zásobníka papiera",
            "Druh použitého softvéru",
          ],
          correctAnswer: "Rozlíšenie tlače, typ tlačiarne a veľkosť dokumentu",
        },
        {
          question: "Čo je to print queue management?",
          options: [
            "Správa tlačových úloh čakajúcich na tlač",
            "Súčasť na údržbu tlačiarne",
            "Súčasť na čistenie tlačiarenských hláv",
            "Súčasť, ktorá monitoruje kapacitu atramentových kaziet",
          ],
          correctAnswer: "Správa tlačových úloh čakajúcich na tlač",
        },
        {
          question: "Čo je to print resolution?",
          options: [
            "Počet bodov na palec (dpi) určujúci kvalitu tlače",
            "Kapacita zásobníka papiera",
            "Rýchlosť tlače",
            "Veľkosť tlačiarne",
          ],
          correctAnswer: "Počet bodov na palec (dpi) určujúci kvalitu tlače",
        },
        {
          question: "Aký je účel kalibrácie tlačiarne?",
          options: [
            "Upraviť nastavenia farieb a kvality tlače",
            "Zvýšiť rýchlosť tlače",
            "Zlepšiť kapacitu zásobníka papiera",
            "Vymeniť atramentové kazety",
          ],
          correctAnswer: "Upraviť nastavenia farieb a kvality tlače",
        },
        {
          question: "Aké faktory ovplyvňujú kvalitu tlače?",
          options: [
            "Rozlíšenie, typ papiera a kvalita atramentu alebo toneru",
            "Farba papiera a kapacita zásobníka",
            "Veľkosť tlačiarne a pripojenie na internet",
            "Typ operačného systému",
          ],
          correctAnswer:
            "Rozlíšenie, typ papiera a kvalita atramentu alebo toneru",
        },
        {
          question: "Čo znamená print speed?",
          options: [
            "Počet strán, ktoré tlačiareň vytlačí za minútu",
            "Veľkosť tlačiarne",
            "Kapacita zásobníka papiera",
            "Typ papiera používaného v tlačiarni",
          ],
          correctAnswer: "Počet strán, ktoré tlačiareň vytlačí za minútu",
        },
        {
          question:
            "Ako sa nazýva proces, pri ktorom sa toner prenáša na papier?",
          options: ["Fusing", "Spooling", "Cleaning", "Calibration"],
          correctAnswer: "Fusing",
        },
        {
          question: "Aký je rozdiel medzi farebnou a čiernobielou tlačiarňou?",
          options: [
            "Farebná tlačiareň tlačí v rôznych farbách, čiernobiela len v čiernej",
            "Farebná tlačiareň je lacnejšia",
            "Čiernobiela tlačiareň je rýchlejšia",
            "Farebná tlačiareň používa len atrament, čiernobiela toner",
          ],
          correctAnswer:
            "Farebná tlačiareň tlačí v rôznych farbách, čiernobiela len v čiernej",
        },
        {
          question:
            "Ako sa nazýva technológia, ktorá umožňuje tlač bezdrôtovo z mobilného zariadenia?",
          options: ["Wi-Fi Direct", "Bluetooth Classic", "NFC", "Ethernet"],
          correctAnswer: "Wi-Fi Direct",
        },
        {
          question: "Čo je to print driver?",
          options: [
            "Softvér, ktorý umožňuje počítaču komunikovať s tlačiarňou",
            "Fyzická súčasť tlačiarne",
            "Typ papiera",
            "Typ toneru",
          ],
          correctAnswer:
            "Softvér, ktorý umožňuje počítaču komunikovať s tlačiarňou",
        },
        {
          question: "Čo je to inkjet cartridge?",
          options: [
            "Kazeta obsahujúca atrament pre inkjet tlačiareň",
            "Typ papiera používaný v tlačiarni",
            "Súčasť laserovej tlačiarne",
            "Súčasť, ktorá zabezpečuje pripojenie na internet",
          ],
          correctAnswer: "Kazeta obsahujúca atrament pre inkjet tlačiareň",
        },
        {
          question:
            "Aký typ papiera je najvhodnejší pre profesionálnu tlač fotografií?",
          options: [
            "Fotografický papier",
            "Recyklovaný papier",
            "Kopírovací papier",
            "Stolový papier",
          ],
          correctAnswer: "Fotografický papier",
        },
        {
          question:
            "Ako sa nazýva proces, pri ktorom tlačiareň vytvára obraz na papieri?",
          options: ["Tlač", "Skenovanie", "Kopírovanie", "Fotonápis"],
          correctAnswer: "Tlač",
        },
        {
          question: "Aké typy kaziet sa používajú v laserových tlačiarňach?",
          options: [
            "Tonerové kazety",
            "Atramentové kazety",
            "Snímačové kazety",
            "Čistiace kazety",
          ],
          correctAnswer: "Tonerové kazety",
        },
        {
          question: "Čo je to print resolution (rozlíšenie tlače)?",
          options: [
            "Miera detailov, ktoré tlačiareň dokáže vytvoriť na papieri",
            "Rýchlosť tlače",
            "Kapacita atramentových kaziet",
            "Typ papiera používaného v tlačiarni",
          ],
          correctAnswer:
            "Miera detailov, ktoré tlačiareň dokáže vytvoriť na papieri",
        },
      ],
      backgroundImage: require("./categories/tech/tech-tlaciaren.jpg"),
    },

    {
      name: "Ako funguje radar",
      questions: [
        {
          question: "Aký princíp je základom fungovania radaru?",
          options: [
            "Odraz rádových vĺn od objektov",
            "Detekcia magnetických polí",
            "Snímanie infračerveného žiarenia",
            "Merenie ultrazvukových vĺn",
          ],
          correctAnswer: "Odraz rádových vĺn od objektov",
        },
        {
          question: "Čo je to radarový signál?",
          options: [
            "Rádové vlny emitované radarom",
            "Optické svetlo používané na detekciu",
            "Zvukové vlny premerané v prostredí",
            "Magnetické pole vytvorené radarom",
          ],
          correctAnswer: "Rádové vlny emitované radarom",
        },
        {
          question: "Aká je funkcia radaru pri meraní vzdialenosti?",
          options: [
            "Meranie času, kedy sa signál odrazí a vráti späť",
            "Merenie intenzity signálu",
            "Detekcia smeru signálu",
            "Merenie frekvencie signálu",
          ],
          correctAnswer: "Meranie času, kedy sa signál odrazí a vráti späť",
        },
        {
          question: "Čo je to Dopplerov efekt v kontexte radaru?",
          options: [
            "Zmena frekvencie signálu v dôsledku pohybu objektu",
            "Vysoká intenzita radarového signálu",
            "Pokles citlivosti radaru pri dlhých vzdialenostiach",
            "Nárast rýchlosti signálu v atmosfére",
          ],
          correctAnswer: "Zmena frekvencie signálu v dôsledku pohybu objektu",
        },
        {
          question: "Aké sú hlavné aplikácie radarov?",
          options: [
            "Letecká navigácia a meteorológia",
            "Tvorba mapových podkladov",
            "Výroba automobilov",
            "Sledovanie pohybu tekutín",
          ],
          correctAnswer: "Letecká navigácia a meteorológia",
        },
        {
          question: "Čo je to radarová obrazovka?",
          options: [
            "Displej, ktorý zobrazuje odrazené radarové signály",
            "Optické zariadenie na záznam signálov",
            "Zvukové zariadenie pre sledovanie objektov",
            "Magnetická doska na uchovávanie údajov",
          ],
          correctAnswer: "Displej, ktorý zobrazuje odrazené radarové signály",
        },
        {
          question: "Ktorá časť radaru vysiela signály?",
          options: ["Anténa", "Kamera", "Procesor", "Mikrofón"],
          correctAnswer: "Anténa",
        },
        {
          question: "Čo je to radarový impulz?",
          options: [
            "Krátky burst rádiových vĺn vysielaný radarom",
            "Kontinuálne rádiové vlny",
            "Optický signál na detekciu",
            "Zvuková vlna emitovaná radarom",
          ],
          correctAnswer: "Krátky burst rádiových vĺn vysielaný radarom",
        },
        {
          question:
            "Ako sa nazýva technológia, ktorá využíva radar na zisťovanie vzdialenosti objektov?",
          options: ["Lidar", "Sonar", "Rádar", "Optický senzor"],
          correctAnswer: "Radar",
        },
        {
          question: "Aký je účel dopplerovského radaru?",
          options: [
            "Meranie rýchlosti pohybujúcich sa objektov",
            "Získavanie geografických informácií",
            "Meranie teploty vzduchu",
            "Detekcia smeru vetra",
          ],
          correctAnswer: "Meranie rýchlosti pohybujúcich sa objektov",
        },
        {
          question:
            "Ako radar detekuje objekty v noci alebo za zlých poveternostných podmienok?",
          options: [
            "Pomocou rádiových vĺn, ktoré sú nezávislé od viditeľnosti",
            "Použitím infračervených senzorov",
            "Využitím optických kamier",
            "Pomocou ultrazvukových snímačov",
          ],
          correctAnswer:
            "Pomocou rádiových vĺn, ktoré sú nezávislé od viditeľnosti",
        },
        {
          question: "Aké faktory môžu ovplyvniť presnosť radaru?",
          options: [
            "Atmosférické podmienky a veľkosť objektu",
            "Farba objektu a jeho teplota",
            "Zvukové vlny a intenzita svetla",
            "Chemické zloženie vzduchu",
          ],
          correctAnswer: "Atmosférické podmienky a veľkosť objektu",
        },
        {
          question: "Ako radar zistí prítomnosť objektu?",
          options: [
            "Analýzou odrazených signálov",
            "Meraním tepla vyžarovaného objektom",
            "Snímaním viditeľného svetla",
            "Analýzou zvukových vĺn",
          ],
          correctAnswer: "Analýzou odrazených signálov",
        },
        {
          question: "Čo je to radarová mapa?",
          options: [
            "Obraz založený na radarových signáloch zobrazujúci polohu a veľkosť objektov",
            "Mapa zobrazujúca teplotné rozloženie",
            "Geografická mapa zobrazujúca topografické údaje",
            "Mapa svetla a tieni v oblasti",
          ],
          correctAnswer:
            "Obraz založený na radarových signáloch zobrazujúci polohu a veľkosť objektov",
        },
        {
          question:
            "Aké sú výhody radarového systému v porovnaní s optickými senzorom?",
          options: [
            "Schopnosť pracovať za zlých poveternostných podmienok a v noci",
            "Nižšie náklady na údržbu",
            "Vyššia rýchlosť spracovania údajov",
            "Menšia spotreba energie",
          ],
          correctAnswer:
            "Schopnosť pracovať za zlých poveternostných podmienok a v noci",
        },
      ],
      backgroundImage: require("./categories/tech/tech-radar.jpg"),
    },

    {
      name: "Ako funguje mikrovlnka",
      questions: [
        {
          question: "Aký princíp je základom fungovania mikrovlnnej rúry?",
          options: [
            "Mikrovlnné žiarenie",
            "Infrapany",
            "Ultrazvuk",
            "Rádiové vlny",
          ],
          correctAnswer: "Mikrovlnné žiarenie",
        },
        {
          question: "Aká je hlavná funkcia magnetronu v mikrovlnke?",
          options: [
            "Generovanie mikrovlnného žiarenia",
            "Zohriatie vzduchu",
            "Vytváranie svetla",
            "Vstupná kontrola",
          ],
          correctAnswer: "Generovanie mikrovlnného žiarenia",
        },
        {
          question: "Aký typ nádoby je najlepšie používať v mikrovlnke?",
          options: [
            "Sklenené alebo keramické nádoby",
            "Plastové nádoby",
            "Kovové nádoby",
            "Papierové nádoby",
          ],
          correctAnswer: "Sklenené alebo keramické nádoby",
        },
        {
          question:
            "Aký je hlavný dôvod, prečo by sme nemali používať kovové nádoby v mikrovlnke?",
          options: [
            "Kov môže spôsobovať iskrenie",
            "Kov sa rýchlo zahrieva",
            "Kov môže poškodiť mikrovlnku",
            "Kov sa nevhodne rozťahuje",
          ],
          correctAnswer: "Kov môže spôsobovať iskrenie",
        },
        {
          question:
            "Ako sa nazýva proces, pri ktorom sa mikrovlnná rúra zastaví, keď sa otvorí dvere?",
          options: [
            "Bezpečnostný mechanizmus",
            "Chladiaci systém",
            "Ovládací panel",
            "Indikátor pohybu",
          ],
          correctAnswer: "Bezpečnostný mechanizmus",
        },
        {
          question: "Čo je to turntable v mikrovlnke?",
          options: [
            "Otočný tanier na rovnomerné zahrievanie",
            "Teplomer na meranie teploty",
            "Ventilátor na cirkuláciu vzduchu",
            "Rúra na sušenie potravín",
          ],
          correctAnswer: "Otočný tanier na rovnomerné zahrievanie",
        },
        {
          question: "Aké potraviny sú nevhodné na zahrievanie v mikrovlnke?",
          options: [
            "Vajcia v škrupine",
            "Nasekaná zelenina",
            "Mäso",
            "Polievka",
          ],
          correctAnswer: "Vajcia v škrupine",
        },
        {
          question:
            "Aké nastavenie mikrovlnnej rúry sa používa na rozmrazovanie potravín?",
          options: [
            "Režim rozmrazovania",
            "Rýchle zahriatie",
            "Plný výkon",
            "Parné varenie",
          ],
          correctAnswer: "Režim rozmrazovania",
        },
        {
          question: "Čo je to mikrovlnný bezpečnostný štít?",
          options: [
            "Ochranný panel proti úniku mikrovĺn",
            "Rúra na mikrovlnnú sterilizáciu",
            "Nádoba na mikrovlnné varenie",
            "Označenie na zabezpečenie kvality",
          ],
          correctAnswer: "Ochranný panel proti úniku mikrovĺn",
        },
        {
          question:
            "Ktorý komponent mikrovlnky zabezpečuje, aby sa mikrovlnné žiarenie neunikalo von?",
          options: [
            "Dvierka s kovovým sieťovým panelom",
            "Ventilátor",
            "Osvetľovacia žiarovka",
            "Tepelný senzor",
          ],
          correctAnswer: "Dvierka s kovovým sieťovým panelom",
        },
        {
          question:
            "Ako ovplyvňuje mikrovlnné žiarenie molekuly vody v potravinách?",
          options: [
            "Vytvára teplo vibráciou molekúl vody",
            "Spôsobuje chemickú reakciu",
            "Zvyšuje hustotu molekúl vody",
            "Molekuly vody sa ochladzujú",
          ],
          correctAnswer: "Vytvára teplo vibráciou molekúl vody",
        },
        {
          question: "Aký je najčastejší spôsob čistenia mikrovlnky?",
          options: [
            "Otrieť vlhkou handrou a mydlom",
            "Použiť špeciálny čistiaci prostriedok",
            "Namočiť do vody",
            "Použiť horúci vzduch",
          ],
          correctAnswer: "Otrieť vlhkou handrou a mydlom",
        },
        {
          question:
            "Aké je maximálne množstvo času, ktoré by malo byť nastavené na mikrovlnke pri varení?",
          options: [
            "Podľa pokynov na obale potravín",
            "30 minút",
            "1 hodina",
            "2 hodiny",
          ],
          correctAnswer: "Podľa pokynov na obale potravín",
        },
        {
          question:
            "Ako sa nazýva proces, pri ktorom sa mikrovlnné rúry používajú na ohrev tekutín?",
          options: [
            "Mikrovlnné ohrev",
            "Parné varenie",
            "Infračervené ohrev",
            "Rádiové ohrev",
          ],
          correctAnswer: "Mikrovlnné ohrev",
        },
        {
          question:
            "Aký je najväčší bezpečnostný problém pri používaní mikrovlnky?",
          options: [
            "Riziko popálenín",
            "Vysoká spotreba elektriny",
            "Nesprávne nastavenie času",
            "Nedostatok rôznych režimov",
          ],
          correctAnswer: "Riziko popálenín",
        },
      ],
      backgroundImage: require("./categories/tech/tech-mikrovlnka.jpg"),
    },

    {
      name: "Všeobecný test o iPhone",
      questions: [
        {
          question: "Kedy bol predstavený prvý iPhone?",
          options: ["2005", "2007", "2009", "2010"],
          correctAnswer: "2007",
        },
        {
          question:
            "Ktorý model iPhone bol prvýkrát vybavený funkciou Face ID?",
          options: ["iPhone 11", "iPhone 8", "iPhone X", "iPhone 7"],
          correctAnswer: "iPhone X",
        },
        {
          question: "Ktorý z nasledujúcich systémov Apple nepoužíva na iPhone?",
          options: ["macOS", "iOS", "tvOS", "watchOS"],
          correctAnswer: "macOS",
        },
        {
          question:
            "Ktorý z týchto materiálov bol použitý na zadnej strane iPhone 4?",
          options: ["Plast", "Sklo", "Hliník", "Oceľ"],
          correctAnswer: "Sklo",
        },
        {
          question:
            "Ktorý model iPhone bol prvý, ktorý nepoužíval 3,5 mm jack na slúchadlá?",
          options: ["iPhone 5", "iPhone 7", "iPhone 6", "iPhone SE"],
          correctAnswer: "iPhone 7",
        },
        {
          question:
            "Ktorá aplikácia slúži na videohovory medzi používateľmi Apple zariadení?",
          options: ["WhatsApp", "FaceTime", "Skype", "Zoom"],
          correctAnswer: "FaceTime",
        },
        {
          question:
            "Ktorý iPhone bol prvýkrát vybavený trojitým zadným fotoaparátom?",
          options: ["iPhone X", "iPhone 11 Pro", "iPhone 8 Plus", "iPhone 12"],
          correctAnswer: "iPhone 11 Pro",
        },
        {
          question: "Čo znamená 'i' v názve iPhone?",
          options: ["Inteligentný", "Individuálny", "Internet", "Inovatívny"],
          correctAnswer: "Internet",
        },
        {
          question: "Ktorý model iPhone bol prvý, ktorý mal OLED displej?",
          options: ["iPhone 7", "iPhone 12", "iPhone X", "iPhone 11"],
          correctAnswer: "iPhone X",
        },
        {
          question: "Ktorá technológia umožňuje bezdrôtové nabíjanie iPhone?",
          options: ["USB-C", "Bluetooth", "Qi", "Lightning"],
          correctAnswer: "Qi",
        },
        {
          question: "Aká je maximálna kapacita úložiska iPhone 13 Pro?",
          options: ["1 TB", "512 GB", "256 GB", "128 GB"],
          correctAnswer: "1 TB",
        },
        {
          question: "Ktorý z nasledujúcich iPhone modelov neexistuje?",
          options: ["iPhone SE", "iPhone 9", "iPhone 6S", "iPhone 12 Mini"],
          correctAnswer: "iPhone 9",
        },
        {
          question:
            "Ktorá funkcia umožňuje iPhone odosielať a prijímať súbory rýchlo cez Wi-Fi?",
          options: ["Bluetooth", "AirDrop", "NFC", "Hotspot"],
          correctAnswer: "AirDrop",
        },
        {
          question:
            "Ktorý model iPhone bol prvýkrát vybavený dvojitým zadným fotoaparátom?",
          options: ["iPhone 7 Plus", "iPhone 6S", "iPhone 8", "iPhone X"],
          correctAnswer: "iPhone 7 Plus",
        },
        {
          question: "Ktorý z nasledujúcich modelov iPhone je vodotesný?",
          options: ["iPhone 6", "iPhone 7", "iPhone 5S", "iPhone SE (2020)"],
          correctAnswer: "iPhone 7",
        },
      ],
      backgroundImage: require("./categories/tech/tech-iphone.jpeg"),
    },
  ],
  science: [
    {
      name: "Všeobecný test z vedy",
      questions: [
        {
          question: "Aký je chemický symbol pre vodu?",
          options: ["H2O", "CO2", "O2", "H2"],
          correctAnswer: "H2O",
        },
        {
          question: "Ktorá planéta je známa ako Červená planéta?",
          options: ["Jupiter", "Mars", "Venuša", "Saturn"],
          correctAnswer: "Mars",
        },
        {
          question: "Ktorý fyzik je známy svojou teóriou relativity?",
          options: [
            "Galileo Galilei",
            "Isaac Newton",
            "Albert Einstein",
            "Nikola Tesla",
          ],
          correctAnswer: "Albert Einstein",
        },
        {
          question: "Čo je to fotosyntéza?",
          options: [
            "Forma genetickej mutácie",
            "Proces, ktorým rastliny premieňajú svetlo na energiu",
            "Typ chemickej reakcie",
            "Proces, ktorým organizmy dýchajú",
          ],
          correctAnswer: "Proces, ktorým rastliny premieňajú svetlo na energiu",
        },
        {
          question: "Aká je hlavná zložka atmosféry Zeme?",
          options: ["Vodík", "Kyslík", "Dusík", "Oxid uhličitý"],
          correctAnswer: "Dusík",
        },
        {
          question: "Čo je to gravitačná sila?",
          options: [
            "Sila, ktorá priťahuje objekty k sebe na základe ich hmotnosti",
            "Sila spôsobená magnetickým poľom Zeme",
            "Sila, ktorá odpudzuje objekty od seba",
            "Sila, ktorá ovláda pohyb elektrónov",
          ],
          correctAnswer:
            "Sila, ktorá priťahuje objekty k sebe na základe ich hmotnosti",
        },
        {
          question: "Ktorý prvok je najrozšírenejší vo vesmíre?",
          options: ["Hélium", "Uhlík", "Kyslík", "Vodík"],
          correctAnswer: "Vodík",
        },
        {
          question: "Aký typ energie je uložený v potrave?",
          options: [
            "Kinetická energia",
            "Tepelná energia",
            "Chemická energia",
            "Elektrická energia",
          ],
          correctAnswer: "Chemická energia",
        },
        {
          question: "Čo je to DNA?",
          options: [
            "Typ bunky v ľudskom tele",
            "Štruktúra v mozgu",
            "Druh proteínu v krvi",
            "Molekula, ktorá uchováva genetickú informáciu v bunkách",
          ],
          correctAnswer:
            "Molekula, ktorá uchováva genetickú informáciu v bunkách",
        },
        {
          question: "Aký je najväčší orgán v ľudskom tele?",
          options: ["Pľúca", "Mozog", "Koža", "Pečeň"],
          correctAnswer: "Koža",
        },
        {
          question: "Aký je štandardný bod varu vody pri normálnom tlaku?",
          options: ["100°C", "50°C", "212°F", "0°C"],
          correctAnswer: "100°C",
        },
        {
          question: "Čo je to tektonická doska?",
          options: [
            "Oblasť s vysokou vulkanickou aktivitou",
            "Vrstva atmosféry Zeme",
            "Veľký kus zemskej kôry, ktorý sa pohybuje po povrchu Zeme",
            "Časť oceánu s vysokou salinitou",
          ],
          correctAnswer:
            "Veľký kus zemskej kôry, ktorý sa pohybuje po povrchu Zeme",
        },
        {
          question: "Ktorá forma energie sa považuje za obnoviteľnú?",
          options: [
            "Fosílne palivá",
            "Slnečná energia",
            "Zemný plyn",
            "Jadrová energia",
          ],
          correctAnswer: "Slnečná energia",
        },
        {
          question: "Čo spôsobuje skleníkový efekt?",
          options: [
            "Pokles slnečného žiarenia",
            "Vulkanické erupcie",
            "Nadmerné množstvo oxidu uhličitého a iných plynov v atmosfére",
            "Zvýšené množstvo kyslíka vo vzduchu",
          ],
          correctAnswer:
            "Nadmerné množstvo oxidu uhličitého a iných plynov v atmosfére",
        },
        {
          question: "Čo je kvantová mechanika?",
          options: [
            "Technika na meranie elektrickej energie",
            "Teória o pôvode vesmíru",
            "Štúdium fyzikálnych zákonov na subatomárnej úrovni",
            "Typ chemickej reakcie v jadrách atómov",
          ],
          correctAnswer: "Štúdium fyzikálnych zákonov na subatomárnej úrovni",
        },
      ],
      backgroundImage: require("./categories/science/vseo-test-1.jpg"),
    },

    {
      name: "Vesmír - planéty slnečnej sústavy",
      questions: [
        {
          question: "Ktorá planéta je najväčšia v slnečnej sústave?",
          options: ["Mars", "Jupiter", "Zem", "Saturn"],
          correctAnswer: "Jupiter",
        },
        {
          question: "Ktorá planéta je najbližšie k Slnku?",
          options: ["Mars", "Merkúr", "Zem", "Venuša"],
          correctAnswer: "Merkúr",
        },
        {
          question:
            "Na ktorej planéte sa nachádza najvyššia sopka v slnečnej sústave?",
          options: ["Mars", "Saturn", "Zem", "Jupiter"],
          correctAnswer: "Mars",
        },
        {
          question: "Ktorá planéta má najväčší počet mesiacov?",
          options: ["Saturn", "Neptún", "Uran", "Jupiter"],
          correctAnswer: "Saturn",
        },
        {
          question: "Ktorá planéta má prstence zložené z ľadu a prachu?",
          options: ["Saturn", "Mars", "Merkúr", "Neptún"],
          correctAnswer: "Saturn",
        },
        {
          question: 'Ktorá planéta je známa ako "červená planéta"?',
          options: ["Mars", "Venuša", "Jupiter", "Neptún"],
          correctAnswer: "Mars",
        },
        {
          question: "Na ktorej planéte sa nachádza Veľká červená škvrna?",
          options: ["Mars", "Zem", "Saturn", "Jupiter"],
          correctAnswer: "Jupiter",
        },
        {
          question: "Ktorá planéta je najďalej od Slnka?",
          options: ["Neptún", "Jupiter", "Saturn", "Uran"],
          correctAnswer: "Neptún",
        },
        {
          question: "Ktorá planéta je najväčšou zo Zemských planét?",
          options: ["Zem", "Mars", "Merkúr", "Venuša"],
          correctAnswer: "Zem",
        },
        {
          question:
            "Na ktorej planéte je atmosféra tvorená prevažne oxidom uhličitým?",
          options: ["Mars", "Zem", "Venuša", "Merkúr"],
          correctAnswer: "Venuša",
        },
        {
          question: "Ktorá planéta je najmenšia v slnečnej sústave?",
          options: ["Merkúr", "Pluto", "Mars", "Venuša"],
          correctAnswer: "Merkúr",
        },
        {
          question:
            "Na ktorej planéte sú najväčšie teplotné rozdiely medzi dňom a nocou?",
          options: ["Merkúr", "Venuša", "Mars", "Zem"],
          correctAnswer: "Merkúr",
        },
        {
          question:
            "Na ktorej planéte sú pozorované najväčšie búrky v slnečnej sústave?",
          options: ["Zem", "Mars", "Saturn", "Jupiter"],
          correctAnswer: "Jupiter",
        },
        {
          question:
            "Ktorá planéta je známa pre svoje výrazné modré zafarbenie?",
          options: ["Neptún", "Saturn", "Venuša", "Uran"],
          correctAnswer: "Neptún",
        },
        {
          question:
            "Ktorá planéta má naklonenú os rotácie tak, že rotuje takmer bokom?",
          options: ["Uran", "Mars", "Jupiter", "Saturn"],
          correctAnswer: "Uran",
        },
      ],
      backgroundImage: require("./categories/science/science-vesmir-planety.jpg"),
    },
  ],
  hist: [
    {
      name: "Slovenské národné povstanie",
      questions: [
        {
          question: "Kedy začalo Slovenské národné povstanie?",
          options: [
            "29. august 1944",
            "1. september 1944",
            "15. august 1944",
            "1. október 1944",
          ],
          correctAnswer: "29. august 1944",
        },
        {
          question:
            "Kto bol hlavným veliteľom povstaleckých síl počas Slovenského národného povstania?",
          options: [
            "Generál Jozef Turčány",
            "Generál Rudolf Viest",
            "Generál Milan Rastislav Štefánik",
            "Generál Ludvík Svoboda",
          ],
          correctAnswer: "Generál Rudolf Viest",
        },
        {
          question: "Ktoré mesto bolo hlavným centrom povstania?",
          options: ["Banská Bystrica", "Bratislava", "Košice", "Žilina"],
          correctAnswer: "Banská Bystrica",
        },
        {
          question: "Aký bol hlavný cieľ Slovenského národného povstania?",
          options: [
            "Oslobodenie Slovenska od nacistickej okupácie",
            "Zriadenie novej republiky",
            "Získanie nezávislosti pre Slovensko",
            "Zmena politického systému v Československu",
          ],
          correctAnswer: "Oslobodenie Slovenska od nacistickej okupácie",
        },
        {
          question: "Aké boli hlavné sily, ktoré sa postavili proti povstaniu?",
          options: [
            "Nacistická armáda a slovenské kolaborantské jednotky",
            "Sovietska armáda a americké sily",
            "Britské a francúzske vojská",
            "Maďarské a rumunské vojská",
          ],
          correctAnswer: "Nacistická armáda a slovenské kolaborantské jednotky",
        },
        {
          question: "Kedy sa skončilo Slovenské národné povstanie?",
          options: [
            "28. október 1944",
            "27. október 1944",
            "6. november 1944",
            "30. september 1944",
          ],
          correctAnswer: "28. október 1944",
        },
        {
          question:
            "Aké následky malo Slovenské národné povstanie pre Slovensko?",
          options: [
            "Zintenzívnenie represálií a okupácie nacistami",
            "Rýchle oslobodenie Slovenska",
            "Zriadenie nového štátu",
            "Okamžité ukončenie druhej svetovej vojny",
          ],
          correctAnswer: "Zintenzívnenie represálií a okupácie nacistami",
        },
        {
          question: "Akú úlohu zohrala partizánska činnosť počas povstania?",
          options: [
            "Podpora povstaleckých síl a boj proti okupantom",
            "Vyjednávanie s nacistami o kapitulácii",
            "Organizovanie diplomatických rokovaní",
            "Vytváranie kultúrnych a vzdelávacích programov",
          ],
          correctAnswer: "Podpora povstaleckých síl a boj proti okupantom",
        },
        {
          question: "Ktoré krajiny poskytli podporu povstaleckým silám?",
          options: [
            "Sovietska únia a Spojené štáty",
            "Británia a Francúzsko",
            "Nemecko a Taliansko",
            "Maďarsko a Rumunsko",
          ],
          correctAnswer: "Sovietska únia a Spojené štáty",
        },
        {
          question:
            "Kto bol predsedom Slovenskej národnej rady počas povstania?",
          options: [
            "Karol Šmidke",
            "Jozef Tiso",
            "Milan Rastislav Štefánik",
            "Eduard Beneš",
          ],
          correctAnswer: "Karol Šmidke",
        },
        {
          question:
            "Ktorý historický dokument je spojený so Slovenským národným povstaním?",
          options: [
            "Deklarácia slovenského národa",
            "Slovenská ústava",
            "Manifest povstania",
            "Pakt medzi Slovenskom a Nemeckom",
          ],
          correctAnswer: "Manifest povstania",
        },
        {
          question: "Aký bol postoj slovenského štátu k povstaniu?",
          options: [
            "Podpora nacistických síl a potlačenie povstania",
            "Neutrálny postoj",
            "Podpora povstania",
            "Zdržanlivý postoj",
          ],
          correctAnswer: "Podpora nacistických síl a potlačenie povstania",
        },
        {
          question: "Aké územie bolo najviac postihnuté bojmi počas povstania?",
          options: [
            "Stredné Slovensko",
            "Východné Slovensko",
            "Západné Slovensko",
            "Južné Slovensko",
          ],
          correctAnswer: "Stredné Slovensko",
        },
        {
          question: "Akú rolu zohrali ženy v Slovenskom národnom povstaní?",
          options: [
            "Pomáhali ako zdravotné sestry a logistické podporovateľky",
            "Viedli bojové jednotky",
            "Vykonávali diplomatické misie",
            "Boli zodpovedné za strategické plánovanie",
          ],
          correctAnswer:
            "Pomáhali ako zdravotné sestry a logistické podporovateľky",
        },
        {
          question:
            "Ako sa povstanie odrazilo v kultúrnych a historických spomienkach?",
          options: [
            "Stalo sa symbolom boja za slobodu a odporu proti totalitarizmu",
            "Bolo považované za neúspešný pokus o revolúciu",
            "Bolo ignorované v historických prameňoch",
            "Získalo negatívny obraz v historiografii",
          ],
          correctAnswer:
            "Stalo sa symbolom boja za slobodu a odporu proti totalitarizmu",
        },
      ],
      backgroundImage: require("./categories/hist/hist-snp.jpg"),
    },

    {
      name: "Nežná revolúcia na Slovensku",
      questions: [
        {
          question: "V ktorom roku sa začala Nežná revolúcia na Slovensku?",
          options: ["1989", "1990", "1988", "1991"],
          correctAnswer: "1989",
        },
        {
          question: "Aké boli hlavné ciele Nežnej revolúcie?",
          options: [
            "Demokratické reformy a koniec komunistického režimu",
            "Zjednotenie Československa s Nemeckom",
            "Zlepšenie hospodárstva",
            "Posilnenie komunistickej vlády",
          ],
          correctAnswer: "Demokratické reformy a koniec komunistického režimu",
        },
        {
          question:
            "Ktoré mesto sa stalo centrom Nežnej revolúcie na Slovensku?",
          options: ["Bratislava", "Košice", "Praha", "Banská Bystrica"],
          correctAnswer: "Bratislava",
        },
        {
          question:
            "Ktorá udalosť symbolizovala začiatok Nežnej revolúcie v Československu?",
          options: [
            "Študentská demonštrácia v Prahe 17. novembra 1989",
            "Pád Berlínskeho múru",
            "Vypustenie Sputniku",
            "Prijatie Charty 77",
          ],
          correctAnswer: "Študentská demonštrácia v Prahe 17. novembra 1989",
        },
        {
          question: "Ako sa inak nazýva Nežná revolúcia?",
          options: [
            "Sametová revolúcia",
            "Zamatová revolúcia",
            "Farebná revolúcia",
            "Tichá revolúcia",
          ],
          correctAnswer: "Zamatová revolúcia",
        },
        {
          question: "Čo symbolizoval klúčený zvuk počas demonštrácií?",
          options: [
            "Otváranie dverí k slobode",
            "Zvonenie na poplach",
            "Protest proti vláde",
            "Zavretie komunistického režimu",
          ],
          correctAnswer: "Otváranie dverí k slobode",
        },
        {
          question:
            "Ktorá politická strana bola pri moci pred Nežnou revolúciou?",
          options: [
            "Komunistická strana Československa",
            "Občianske fórum",
            "KDH",
            "SDĽ",
          ],
          correctAnswer: "Komunistická strana Československa",
        },
        {
          question:
            "Ako sa nazýval hlavný študentský protestný orgán počas Nežnej revolúcie?",
          options: [
            "Štrajkový výbor študentov",
            "Občianske fórum",
            "Študentská rada",
            "Verejnosť proti násiliu",
          ],
          correctAnswer: "Štrajkový výbor študentov",
        },
        {
          question:
            "Ktorý z nasledujúcich symbolov bol často používaný počas Nežnej revolúcie?",
          options: [
            "Váhy",
            "Mierová holubica",
            "Vlajka Československa",
            "Zvonenie klúčmi",
          ],
          correctAnswer: "Zvonenie klúčmi",
        },
        {
          question:
            "Aký typ protestov bol charakteristický pre Nežnú revolúciu?",
          options: [
            "Nenásilné a pokojné protesty",
            "Ozbrojené povstania",
            "Sociálne štrajky",
            "Hospodárske blokády",
          ],
          correctAnswer: "Nenásilné a pokojné protesty",
        },
        {
          question:
            "Kedy sa uskutočnili prvé slobodné voľby po Nežnej revolúcii?",
          options: ["1990", "1989", "1992", "1991"],
          correctAnswer: "1990",
        },
        {
          question:
            "Aká organizácia vznikla počas Nežnej revolúcie a hrala dôležitú úlohu v jej priebehu?",
          options: [
            "Verejnosť proti násiliu",
            "Občianske fórum",
            "Helsinský výbor",
            "Charta 77",
          ],
          correctAnswer: "Verejnosť proti násiliu",
        },
        {
          question:
            "Ktorý z nasledujúcich sloganov bol populárny počas Nežnej revolúcie?",
          options: [
            "Máme holé ruce",
            "Za lepší zajtrajšok",
            "Proti násiliu a neslobode",
            "Pravda a láska zvíťazí",
          ],
          correctAnswer: "Máme holé ruce",
        },
        {
          question: "Kedy došlo k pádu komunistického režimu v Československu?",
          options: [
            "December 1989",
            "November 1989",
            "Január 1990",
            "Október 1989",
          ],
          correctAnswer: "December 1989",
        },
        {
          question:
            "Ako sa volala dohoda, ktorá uzavrela obdobie komunistickej vlády?",
          options: [
            "Gentleman's Agreement",
            "Dohoda o moci",
            "Vyšehradská dohoda",
            "Demokratická dohoda",
          ],
          correctAnswer: "Gentleman's Agreement",
        },
      ],
      backgroundImage: require("./categories/hist/hist-nezna-revolucia.jpg"),
    },

    {
      name: "Druhá svetová vojna",
      questions: [
        {
          question: "Kedy začala Druhá svetová vojna?",
          options: [
            "7. december 1941",
            "1. september 1939",
            "6. júna 1944",
            "8. máj 1945",
          ],
          correctAnswer: "1. september 1939",
        },
        {
          question: "Ktorá krajina vstúpila do vojny po útoku na Pearl Harbor?",
          options: ["Japonsko", "Veľká Británia", "USA", "Nemecko"],
          correctAnswer: "USA",
        },
        {
          question: "Ktoré krajiny tvorili osové mocnosti?",
          options: [
            "Veľká Británia, Francúzsko, Sovietsky zväz",
            "Nemecko, Taliansko, Japonsko",
            "Holandsko, Belgicko, Dánsko",
            "USA, Kanada, Austrália",
          ],
          correctAnswer: "Nemecko, Taliansko, Japonsko",
        },
        {
          question:
            "Aký bol názov operácie, ktorá znamenala začiatok invázie do Normandie?",
          options: [
            "Operácia Torch",
            "Operácia Overlord",
            "Operácia Barbarossa",
            "Operácia Market Garden",
          ],
          correctAnswer: "Operácia Overlord",
        },
        {
          question: "Kedy skončila Druhá svetová vojna v Európe?",
          options: [
            "1. september 1939",
            "8. máj 1945",
            "2. september 1945",
            "7. december 1941",
          ],
          correctAnswer: "8. máj 1945",
        },
        {
          question:
            "Kto bol vodcom nacistického Nemecka počas Druhej svetovej vojny?",
          options: [
            "Benito Mussolini",
            "Joseph Stalin",
            "Adolf Hitler",
            "Winston Churchill",
          ],
          correctAnswer: "Adolf Hitler",
        },
        {
          question:
            "Ktorá konferencia v roku 1945 rozhodla o povojnovom usporiadaní Európy?",
          options: ["Postupim", "Teherán", "Jalta", "Paris"],
          correctAnswer: "Jalta",
        },
        {
          question:
            "Ktorá krajina utrpela najväčšie škody počas bombardovania v Bitke o Britániu?",
          options: ["Polsko", "Holandsko", "Veľká Británia", "Francúzsko"],
          correctAnswer: "Veľká Británia",
        },
        {
          question:
            "Aký bol názov vojenského plánu Nemecka na dobytie Sovietskeho zväzu?",
          options: [
            "Operácia Valkýra",
            "Operácia Dynamo",
            "Operácia Citadel",
            "Operácia Barbarossa",
          ],
          correctAnswer: "Operácia Barbarossa",
        },
        {
          question: "Kedy a kde bola podpísaná kapitulácia Japonska?",
          options: [
            "8. máj 1945 v Berlíne",
            "7. december 1941 v Pearl Harbor",
            "2. september 1945 v Tokiu",
            "6. júna 1944 v Normandii",
          ],
          correctAnswer: "2. september 1945 v Tokiu",
        },
        {
          question:
            "Ktorý americký prezident viedol krajinu počas väčšiny Druhej svetovej vojny?",
          options: [
            "Harry S. Truman",
            "Franklin D. Roosevelt",
            "John F. Kennedy",
            "Dwight D. Eisenhower",
          ],
          correctAnswer: "Franklin D. Roosevelt",
        },
        {
          question:
            "Ktorá krajina bola hlavnou nepriateľskou silou v Tichom oceáne?",
          options: ["Čína", "Japonsko", "USA", "Nemecko"],
          correctAnswer: "Japonsko",
        },
        {
          question:
            "Aký bol názov medzinárodného vojenského tribunálu, ktorý súdil vojnových zločincov po vojne?",
          options: [
            "Londýnsky tribunál",
            "Hágský tribunál",
            "Norimberský tribunál",
            "Parížsky tribunál",
          ],
          correctAnswer: "Norimberský tribunál",
        },
        {
          question:
            "Ktorá bitka sa považuje za rozhodujúcu v boji o Stalingrad?",
          options: [
            "Bitka pri Midway",
            "Bitka pri El Alamein",
            "Bitka pri Kursku",
            "Bitka o Stalingrad",
          ],
          correctAnswer: "Bitka o Stalingrad",
        },
        {
          question: "Aké boli hlavné príčiny Druhej svetovej vojny?",
          options: [
            "Národnostný a ideologický konflikt, agresívna expanzia, nespravodlivé zmluvy",
            "Ekonomické krízy, štátne prevraty, geopolitické konflikty",
            "Obchodné vojny, koloniálne konflikty, náboženské rozdiely",
            "Technologický pokrok, vojenské aliancie, vnútorné problémy",
          ],
          correctAnswer:
            "Národnostný a ideologický konflikt, agresívna expanzia, nespravodlivé zmluvy",
        },
      ],
      backgroundImage: require("./categories/hist/hist-druha-svet-vojna.jpg"),
    },

    {
      name: "Černobyľská katastrofa",
      questions: [
        {
          question: "Kedy došlo k havárii v Černobyle?",
          options: [
            "15. júla 1987",
            "10. januára 1985",
            "26. apríla 1986",
            "12. marca 1989",
          ],
          correctAnswer: "26. apríla 1986",
        },
        {
          question: "V ktorom reaktore došlo k výbuchu v Černobyle?",
          options: [
            "Reaktor číslo 1",
            "Reaktor číslo 4",
            "Reaktor číslo 2",
            "Reaktor číslo 3",
          ],
          correctAnswer: "Reaktor číslo 4",
        },
        {
          question: "Aké mesto bolo evakuované po havárii?",
          options: ["Charkov", "Kyjev", "Pripiať", "Minsk"],
          correctAnswer: "Pripiať",
        },
        {
          question: "Aký bol hlavný dôvod havárie v Černobyle?",
          options: [
            "Teroristický útok",
            "Chyba v dizajne reaktora",
            "Zemetrasenie",
            "Prírodná katastrofa",
          ],
          correctAnswer: "Chyba v dizajne reaktora",
        },
        {
          question: "Aká rádioaktívna látka bola najviac uvoľnená do ovzdušia?",
          options: ["Plutónium-239", "Jód-131", "Urán-235", "Kobalt-60"],
          correctAnswer: "Jód-131",
        },
        {
          question:
            "Koľko reaktorov bolo pôvodne plánovaných pre Černobyľskú elektráreň?",
          options: ["4", "8", "5", "6"],
          correctAnswer: "6",
        },
        {
          question: "Ako dlho trvala evakuácia mesta Pripiať?",
          options: ["3 dni", "2 dni", "1 týždeň", "1 deň"],
          correctAnswer: "1 deň",
        },
        {
          question: "Kto bol prezidentom ZSSR počas havárie?",
          options: [
            "Jurij Andropov",
            "Michail Gorbačov",
            "Nikita Chruščov",
            "Leonid Brežnev",
          ],
          correctAnswer: "Michail Gorbačov",
        },
        {
          question: "Koľko kilometrov od Černobyľu sa nachádza Kyjev?",
          options: ["200 km", "50 km", "150 km", "100 km"],
          correctAnswer: "100 km",
        },
        {
          question: "Kedy bol uzatvorený posledný reaktor v Černobyle?",
          options: ["Rok 1988", "Rok 1996", "Rok 2000", "Rok 1991"],
          correctAnswer: "Rok 2000",
        },
        {
          question: "Aký je oficiálny názov Černobyľskej jadrovej elektrárne?",
          options: [
            "Ukrajinská jadrová stanica",
            "Černobyľská jadrová stanica",
            "V.I. Leninova jadrová elektráreň",
            "Pripiaťská jadrová elektráreň",
          ],
          correctAnswer: "V.I. Leninova jadrová elektráreň",
        },
        {
          question:
            "Ktorá krajina je dnes zodpovedná za správu Černobyľskej zóny?",
          options: ["Kazachstan", "Bielorusko", "Rusko", "Ukrajina"],
          correctAnswer: "Ukrajina",
        },
        {
          question:
            "Aká bola primárna funkcia sarkofágu postaveného nad reaktorom 4?",
          options: [
            "Monitorovať jadrové palivo",
            "Poskytnúť turistickú atrakciu",
            "Ochrániť reaktor pred ďalším výbuchom",
            "Izolovať rádioaktívny materiál",
          ],
          correctAnswer: "Izolovať rádioaktívny materiál",
        },
        {
          question:
            "Ktoré zviera sa stalo symbolom oblasti Černobyľu po havárii?",
          options: ["Líška", "Medvede", "Jelene", "Vlci"],
          correctAnswer: "Vlci",
        },
        {
          question: "Kedy bola vytvorená ochranná zóna okolo Černobyľu?",
          options: ["1986", "1990", "1988", "1987"],
          correctAnswer: "1986",
        },
      ],
      backgroundImage: require("./categories/hist/hist-cernobyl.jpg"),
    },

    {
      name: "Maďari na Slovensku",
      questions: [
        {
          question:
            "Aký podiel na celkovom počte obyvateľov Slovenska tvorí maďarská menšina?",
          options: ["10-15%", "5-10%", "15-20%", "2-5%"],
          correctAnswer: "5-10%",
        },
        {
          question:
            "V ktorých regiónoch Slovenska žije najväčší počet Maďarov?",
          options: [
            "Na severe Slovenska",
            "Na juhu Slovenska",
            "Na západe Slovenska",
            "Na východe Slovenska",
          ],
          correctAnswer: "Na juhu Slovenska",
        },
        {
          question:
            "Ktoré mesto na juhu Slovenska je považované za centrum maďarskej menšiny?",
          options: ["Komárno", "Košice", "Bratislava", "Nitra"],
          correctAnswer: "Komárno",
        },
        {
          question:
            "Aká historická udalosť v roku 1920 prispela k tomu, že časť Maďarov sa ocitla na Slovensku?",
          options: [
            "Podpis Trianonskej zmluvy",
            "Vznik Československa",
            "Viedenská arbitráž",
            "Pád Rakúsko-Uhorska",
          ],
          correctAnswer: "Podpis Trianonskej zmluvy",
        },
        {
          question:
            "Aké opatrenia podporujú maďarskú kultúru a jazyk na Slovensku?",
          options: [
            "Dvojjazyčné školy a médiá",
            "Úplná autonómia",
            "Zákaz používania slovenčiny",
            "Oddelené územné celky",
          ],
          correctAnswer: "Dvojjazyčné školy a médiá",
        },
        {
          question:
            "V ktorých oblastiach Slovenska je dvojjazyčnosť bežnou praxou?",
          options: [
            "Na severe Slovenska",
            "Na juhu Slovenska",
            "Na východe Slovenska",
            "V strednom Slovensku",
          ],
          correctAnswer: "Na juhu Slovenska",
        },
        {
          question:
            "Ktorý dokument po druhej svetovej vojne krátko zmenil hranice medzi Maďarskom a Slovenskom?",
          options: [
            "Mníchovská dohoda",
            "Viedenská arbitráž",
            "Trianonská zmluva",
            "Saintgermainská zmluva",
          ],
          correctAnswer: "Viedenská arbitráž",
        },
        {
          question:
            "Aká je oficiálna politická strana zastupujúca záujmy maďarskej menšiny na Slovensku?",
          options: [
            "Smer-SD",
            "SMK - Strana maďarskej komunity",
            "Progresívne Slovensko",
            "KDH",
          ],
          correctAnswer: "SMK - Strana maďarskej komunity",
        },
        {
          question:
            "Ktoré dve mestá na Slovensku sú známe veľkou maďarskou komunitou?",
          options: [
            "Košice a Prešov",
            "Dunajská Streda a Komárno",
            "Bratislava a Nitra",
            "Banská Bystrica a Zvolen",
          ],
          correctAnswer: "Dunajská Streda a Komárno",
        },
        {
          question:
            "Ktorý dokument oficiálne potvrdil nové hranice medzi Československom a Maďarskom v roku 1920?",
          options: [
            "Trianonská zmluva",
            "Versaillská zmluva",
            "Mníchovská dohoda",
            "Viedenská arbitráž",
          ],
          correctAnswer: "Trianonská zmluva",
        },
        {
          question:
            "Aký je hlavným dôvodom, prečo maďarská menšina na Slovensku stále zachováva svoju kultúru a jazyk?",
          options: [
            "Ekonomická nezávislosť",
            "Dlhá história v regióne a podpora dvojjazyčného vzdelávania",
            "Oddelené územné celky",
            "Nízka integrácia do slovenskej spoločnosti",
          ],
          correctAnswer:
            "Dlhá história v regióne a podpora dvojjazyčného vzdelávania",
        },
        {
          question:
            "Ktoré mesto je domovom najväčšej maďarskej komunity na Slovensku?",
          options: ["Košice", "Komárno", "Trnava", "Žilina"],
          correctAnswer: "Komárno",
        },
        {
          question:
            "Ktorý dokument zmenil hranice medzi Československom a Maďarskom v rokoch 1938-1939?",
          options: [
            "Trianonská zmluva",
            "Versaillská zmluva",
            "Viedenská arbitráž",
            "Mníchovská dohoda",
          ],
          correctAnswer: "Viedenská arbitráž",
        },
        {
          question:
            "Aký jazyk sa používa na oficiálnych úradoch v regiónoch, kde žije viac ako 20% maďarskej menšiny?",
          options: [
            "Iba slovenčina",
            "Iba maďarčina",
            "Slovenčina a maďarčina",
            "Angličtina",
          ],
          correctAnswer: "Slovenčina a maďarčina",
        },
        {
          question:
            "Ktorá oblasť Slovenska je známa vysokou koncentráciou obyvateľov maďarskej národnosti?",
          options: [
            "Podtatranská oblasť",
            "Malá Fatra",
            "Podunajská nížina",
            "Považie",
          ],
          correctAnswer: "Podunajská nížina",
        },
      ],
      backgroundImage: require("./categories/hist/hist-madari-na-sr.png"),
    },
  ],
  math: [
    {
      name: "Zlomky",
      questions: [
        {
          question: "Aký je výsledok operácie 1/2 + 1/3?",
          options: ["5/6", "2/5", "1/5", "3/5"],
          correctAnswer: "5/6",
        },
        {
          question: "Aký je výsledok operácie 3/4 - 1/2?",
          options: ["1/4", "1/2", "3/2", "1/8"],
          correctAnswer: "1/4",
        },
        {
          question: "Aký je súčin zlomkov 2/3 a 3/5?",
          options: ["6/15", "4/5", "2/5", "6/5"],
          correctAnswer: "6/15",
        },
        {
          question: "Zjednoduš zlomok 15/20.",
          options: ["3/4", "4/5", "1/4", "5/6"],
          correctAnswer: "3/4",
        },
        {
          question: "Aký je výsledok operácie 5/6 ÷ 2/3?",
          options: ["5/4", "4/5", "10/9", "9/10"],
          correctAnswer: "5/4",
        },
        {
          question: "Aký je rozdiel medzi zlomkami 7/8 a 1/4?",
          options: ["5/8", "6/8", "1/2", "7/12"],
          correctAnswer: "5/8",
        },
        {
          question: "Ktorý zo zlomkov je väčší: 2/5 alebo 3/7?",
          options: ["2/5", "3/7", "Sú rovnaké", "Nedá sa určiť"],
          correctAnswer: "2/5",
        },
        {
          question: "Aký je súčet zlomkov 4/9 a 5/9?",
          options: ["9/9", "1/9", "8/9", "5/18"],
          correctAnswer: "9/9",
        },
        {
          question: "Preveď zlomok 7/4 na zmiešané číslo.",
          options: ["1 3/4", "2 1/4", "1 1/4", "1 2/4"],
          correctAnswer: "1 3/4",
        },
        {
          question: "Koľko je 2/3 z čísla 12?",
          options: ["8", "6", "9", "10"],
          correctAnswer: "8",
        },
        {
          question: "Aký je najmenší spoločný menovateľ zlomkov 3/4 a 5/6?",
          options: ["12", "24", "6", "8"],
          correctAnswer: "12",
        },
        {
          question: "Zjednoduš zlomok 18/24.",
          options: ["3/4", "2/3", "4/5", "5/6"],
          correctAnswer: "3/4",
        },
        {
          question: "Aký je podiel zlomkov 4/9 ÷ 2/3?",
          options: ["2/3", "6/9", "5/9", "3/4"],
          correctAnswer: "2/3",
        },
        {
          question: "Aký je rozdiel medzi zlomkami 5/6 a 1/3?",
          options: ["1/2", "1/3", "2/3", "5/9"],
          correctAnswer: "1/2",
        },
        {
          question: "Aký je súčin zlomkov 1/4 a 4/5?",
          options: ["1/5", "4/9", "2/5", "5/8"],
          correctAnswer: "1/5",
        },
      ],
      backgroundImage: require("./categories/math/math-zlomky.jpg"),
    },

    {
      name: "Výpočty obvodu a obsahu dvojrozmerných objektov",
      questions: [
        {
          question: "Aký je obvod štvorca s dĺžkou strany 5 cm?",
          options: ["20 cm", "15 cm", "25 cm", "30 cm"],
          correctAnswer: "20 cm",
        },
        {
          question: "Aký je obsah kruhu s polomerom 7 cm? (Použi π ≈ 3,14)",
          options: ["153,86 cm²", "143,86 cm²", "163,86 cm²", "133,86 cm²"],
          correctAnswer: "153,86 cm²",
        },
        {
          question: "Aký je obvod obdĺžnika s dĺžkou 8 cm a šírkou 3 cm?",
          options: ["22 cm", "24 cm", "20 cm", "26 cm"],
          correctAnswer: "22 cm",
        },
        {
          question:
            "Aký je obsah trojuholníka so základňou 10 cm a výškou 5 cm?",
          options: ["25 cm²", "30 cm²", "20 cm²", "35 cm²"],
          correctAnswer: "25 cm²",
        },
        {
          question: "Aký je obvod kruhu s priemerom 12 cm? (Použi π ≈ 3,14)",
          options: ["37,68 cm", "36,68 cm", "38,68 cm", "39,68 cm"],
          correctAnswer: "37,68 cm",
        },
        {
          question: "Aký je obsah štvorca s dĺžkou strany 6 cm?",
          options: ["36 cm²", "32 cm²", "30 cm²", "40 cm²"],
          correctAnswer: "36 cm²",
        },
        {
          question:
            "Aký je obvod rovnostranného trojuholníka s dĺžkou strany 9 cm?",
          options: ["27 cm", "24 cm", "30 cm", "18 cm"],
          correctAnswer: "27 cm",
        },
        {
          question: "Aký je obsah obdĺžnika s dĺžkou 7 cm a šírkou 4 cm?",
          options: ["28 cm²", "24 cm²", "26 cm²", "32 cm²"],
          correctAnswer: "28 cm²",
        },
        {
          question: "Aký je obvod obdĺžnika s dĺžkou 10 cm a šírkou 5 cm?",
          options: ["30 cm", "35 cm", "25 cm", "40 cm"],
          correctAnswer: "30 cm",
        },
        {
          question: "Aký je obsah kruhu s priemerom 10 cm? (Použi π ≈ 3,14)",
          options: ["78,5 cm²", "68,5 cm²", "88,5 cm²", "58,5 cm²"],
          correctAnswer: "78,5 cm²",
        },
        {
          question: "Aký je obvod štvorca s dĺžkou strany 9 cm?",
          options: ["36 cm", "40 cm", "32 cm", "34 cm"],
          correctAnswer: "36 cm",
        },
        {
          question:
            "Aký je obsah trojuholníka so základňou 8 cm a výškou 4 cm?",
          options: ["16 cm²", "18 cm²", "20 cm²", "14 cm²"],
          correctAnswer: "16 cm²",
        },
        {
          question: "Aký je obvod kruhu s polomerom 5 cm? (Použi π ≈ 3,14)",
          options: ["31,4 cm", "30,4 cm", "32,4 cm", "33,4 cm"],
          correctAnswer: "31,4 cm",
        },
        {
          question: "Aký je obsah obdĺžnika s dĺžkou 9 cm a šírkou 5 cm?",
          options: ["45 cm²", "40 cm²", "50 cm²", "42 cm²"],
          correctAnswer: "45 cm²",
        },
        {
          question:
            "Aký je obvod rovnostranného trojuholníka s dĺžkou strany 7 cm?",
          options: ["21 cm", "20 cm", "24 cm", "19 cm"],
          correctAnswer: "21 cm",
        },
      ],
      backgroundImage: require("./categories/math/math-2d-objekty.jpg"),
    },

    {
      name: "Slovné úlohy z oblasti malej násobilky pre deti základných škôl",
      questions: [
        {
          question:
            "Janka má 15 jabĺk a jej kamarát Tomáš jej dal ďalších 8 jabĺk. Koľko jabĺk má teraz Janka?",
          options: ["22", "23", "24", "25"],
          correctAnswer: "23",
        },
        {
          question:
            "Ak máš 3 balíčky, každý obsahuje 6 ceruziek, koľko ceruziek máš všetky dohromady?",
          options: ["18", "20", "21", "24"],
          correctAnswer: "18",
        },
        {
          question:
            "Mária prečítala 45 strán knihy a zostáva jej ešte 30 strán. Koľko strán mala kniha na začiatku?",
          options: ["75", "70", "80", "85"],
          correctAnswer: "75",
        },
        {
          question:
            "V záhrade sú 4 riadky kvetov a v každom riadku je 7 kvetov. Koľko kvetov je celkom v záhrade?",
          options: ["21", "28", "35", "40"],
          correctAnswer: "28",
        },
        {
          question:
            "Peter si kúpil 3 balíčky cukríkov. Každý balíček obsahuje 12 cukríkov. Koľko cukríkov má Peter?",
          options: ["24", "30", "36", "40"],
          correctAnswer: "36",
        },
        {
          question:
            "Ak máš 60 minút a chceš rozdeliť čas na 5 rovných častí, koľko minút bude mať jedna časť?",
          options: ["12", "10", "15", "20"],
          correctAnswer: "12",
        },
        {
          question:
            "Jeden zápisník stojí 7 eur. Koľko eur bude stáť 4 zápisníky?",
          options: ["28", "30", "32", "35"],
          correctAnswer: "28",
        },
        {
          question:
            "Môžeš si kúpiť 5 kníh za 3 eurá každá. Koľko eur zaplatíš za všetky knihy?",
          options: ["12", "13", "15", "18"],
          correctAnswer: "15",
        },
        {
          question:
            "Ak máš 20 jablk a rozdelíš ich medzi 4 kamarátov, koľko jabĺk dostane každý kamarát?",
          options: ["4", "5", "6", "7"],
          correctAnswer: "5",
        },
        {
          question:
            "Kúpiš 3 balíčky sušienok. Každý balíček obsahuje 8 sušienok. Koľko sušienok máš celkom?",
          options: ["22", "24", "26", "30"],
          correctAnswer: "24",
        },
        {
          question:
            "V triede je 28 žiakov a každé dieťa dostane 3 pastelky. Koľko pasteliek je celkom potrebné?",
          options: ["78", "84", "90", "96"],
          correctAnswer: "84",
        },
        {
          question:
            "Ak máš 3 zemiaky a každý zemiak váži 200 gramov, koľko gramov vážia všetky zemiaky dohromady?",
          options: ["500", "600", "700", "800"],
          correctAnswer: "600",
        },
        {
          question:
            "V obchode je zľava 20 % na hračky, ktoré pôvodne stáli 25 eur. Koľko bude stáť hračka po zľave?",
          options: ["20", "22", "24", "25"],
          correctAnswer: "20",
        },
        {
          question:
            "Anna má 8 čokoládových tyčiniek. Každá tyčinka je rozdelená na 4 kúsky. Koľko kúskov čokolády má Anna?",
          options: ["24", "28", "32", "36"],
          correctAnswer: "32",
        },
        {
          question:
            "Ak sú v balíčku 9 gumových zvieratiek a v 5 balíčkoch je rovnaký počet zvieratiek, koľko zvieratiek je celkom?",
          options: ["45", "50", "55", "60"],
          correctAnswer: "45",
        },
      ],
      backgroundImage: require("../src/categories/math/slovne-ulohy-1.jpg"),
    },

    {
      name: "Logické myslenie",
      questions: [
        {
          question:
            "Ak všetci A sú B a niektorí B sú C, môžeme povedať, že niektorí A sú C?",
          options: [
            "Nie je možné určiť",
            "Áno",
            "Závisí to od konkrétnych hodnotách",
            "Nie",
          ],
          correctAnswer: "Nie je možné určiť",
        },
        {
          question: "Čo je pravdivé o logickom výraze: 'A alebo (B a C)'?",
          options: [
            "Výraz je pravdivý, ak A je pravdivé alebo aspoň jeden z B alebo C je pravdivý",
            "Výraz je pravdivý, ak A je pravdivé alebo obidve B a C sú pravdivé",
            "Výraz je pravdivý, ak všetky A, B a C sú pravdivé",
            "Výraz je pravdivý, ak B alebo C je pravdivé a A je nepravdivé",
          ],
          correctAnswer:
            "Výraz je pravdivý, ak A je pravdivé alebo obidve B a C sú pravdivé",
        },
        {
          question:
            "Ak je tvrdenie 'Všetci ľudia sú smrteľní' pravdivé, čo môžeme povedať o tvrdení 'Sokrates je smrteľný'?",
          options: [
            "Tvrdenie je nepravdivé, ak Sokrates je človek",
            "Tvrdenie môže byť pravdivé alebo nepravdivé",
            "Tvrdenie je pravdivé, ak Sokrates je človek",
            "Tvrdenie je nezávislé od toho, či Sokrates je človek",
          ],
          correctAnswer: "Tvrdenie je pravdivé, ak Sokrates je človek",
        },
        {
          question:
            "Ako sa nazýva logická chyba, keď sa argumentuje na základe nesprávnych predpokladov?",
          options: [
            "Falošná dilema",
            "Argumentum ad populum",
            "Ad hominem",
            "Falošné porovnanie",
          ],
          correctAnswer: "Falošná dilema",
        },
        {
          question:
            "Aké je správne logické spojenie medzi tvrdeniami 'Ak prší, potom je mokro' a 'Nie je mokro'?",
          options: ["Prší", "Nie je možné určiť", "Mokro", "Neprší"],
          correctAnswer: "Neprší",
        },
        {
          question: "Čo je to kontradikcia v logike?",
          options: [
            "Dve tvrdenia, ktoré sa navzájom vylučujú",
            "Tvrdenie, ktoré je vždy nepravdivé",
            "Tvrdenie, ktoré je vždy pravdivé",
            "Dve tvrdenia, ktoré sú vždy pravdivé spolu",
          ],
          correctAnswer: "Dve tvrdenia, ktoré sa navzájom vylučujú",
        },
        {
          question: "Čo znamená, keď je argument považovaný za neplatný?",
          options: [
            "Nie je založený na logických predpokladoch",
            "Má nesprávne predpoklady a správny záver",
            "Má správne predpoklady, ale nesprávny záver",
            "Nie je možné overiť jeho správnosť",
          ],
          correctAnswer: "Nie je založený na logických predpokladoch",
        },
        {
          question:
            "Ak je všetko A rovné B a všetko B je C, čo môžeme povedať o A a C?",
          options: [
            "Nie je možné určiť vzťah medzi A a C",
            "A je menšie ako C",
            "A je rovné C",
            "A je väčšie ako C",
          ],
          correctAnswer: "A je rovné C",
        },
        {
          question:
            "Ak je tvrdenie 'Niektorí A sú B' pravdivé, čo môžeme povedať o tvrdení 'Nie všetci A sú B'?",
          options: [
            "Tvrdenie je vždy pravdivé",
            "Tvrdenie je nepravdivé",
            "Tvrdenie môže byť pravdivé",
            "Nie je možné určiť pravdivosť",
          ],
          correctAnswer: "Tvrdenie môže byť pravdivé",
        },
        {
          question:
            "Ak je výrok 'Ak P, potom Q' pravdivý a Q je nepravdivé, aký je stav výroku P?",
          options: [
            "P môže byť pravdivé alebo nepravdivé",
            "Nie je možné určiť",
            "P je nepravdivé",
            "P je pravdivé",
          ],
          correctAnswer: "P je nepravdivé",
        },
        {
          question:
            "Ako sa nazýva logická operácia, ktorá kombinuje dve tvrdenia pomocou 'a'?",
          options: ["Konjunkcia", "Disjunkcia", "Ekvivalencia", "Implikácia"],
          correctAnswer: "Konjunkcia",
        },
        {
          question:
            "Ako sa nazýva logická operácia, ktorá vracia pravdu, ak aspoň jedno z dvoch tvrdení je pravdivé?",
          options: ["Negácia", "Disjunkcia", "Implikácia", "Konjunkcia"],
          correctAnswer: "Disjunkcia",
        },
        {
          question: "Čo je to tautológia v logike?",
          options: [
            "Tvrdenie, ktoré je vždy pravdivé",
            "Tvrdenie, ktoré môže byť pravdivé alebo nepravdivé",
            "Tvrdenie, ktoré je pravdivé len v určitých podmienkach",
            "Tvrdenie, ktoré je vždy nepravdivé",
          ],
          correctAnswer: "Tvrdenie, ktoré je vždy pravdivé",
        },
        {
          question: "Čo znamená logická negácia tvrdenia?",
          options: [
            "Vytvorenie nového tvrdenia",
            "Zmienka o inom tvrdení",
            "Zmena pravdivostnej hodnoty tvrdenia",
            "Doplnok k predpokladom",
          ],
          correctAnswer: "Zmena pravdivostnej hodnoty tvrdenia",
        },
        {
          question:
            "Aký je rozdiel medzi induktívnym a deduktívnym uvažovaním?",
          options: [
            "Induktívne uvažovanie začína od špecifických prípadov a vedie k všeobecným záverom, zatiaľ čo deduktívne uvažovanie začína od všeobecných princípov a vedie k špecifickým záverom",
            "Induktívne uvažovanie a deduktívne uvažovanie sú rovnaké",
            "Induktívne uvažovanie je založené na dôkazoch, zatiaľ čo deduktívne uvažovanie je založené na hypotézach",
            "Induktívne uvažovanie začína od všeobecných princípov a vedie k špecifickým záverom, zatiaľ čo deduktívne uvažovanie začína od špecifických prípadov a vedie k všeobecným záverom",
          ],
          correctAnswer:
            "Induktívne uvažovanie začína od špecifických prípadov a vedie k všeobecným záverom, zatiaľ čo deduktívne uvažovanie začína od všeobecných princípov a vedie k špecifickým záverom",
        },
      ],
      backgroundImage: require("./categories/math/math-logical.jpg"),
    },
  ],
  chem: [
    {
      name: "Čo vieš o rope",
      questions: [
        {
          question: "Aký je hlavný zdroj ropy?",
          options: ["Geologické vrstvy sedimentov", "Uhlie", "Plyn", "Voda"],
          correctAnswer: "Geologické vrstvy sedimentov",
        },
        {
          question: "Ktoré krajiny sú najväčšími producentmi ropy?",
          options: [
            "Saudská Arábia a Rusko",
            "USA a Čína",
            "Kanada a Mexiko",
            "Nemecko a Francúzsko",
          ],
          correctAnswer: "Saudská Arábia a Rusko",
        },
        {
          question: "Aká je primárna funkcia ropy v energetike?",
          options: [
            "Výroba palív",
            "Vytváranie elektriny",
            "Chladenie motorov",
            "Vytváranie nových materiálov",
          ],
          correctAnswer: "Výroba palív",
        },
        {
          question: "Čo je to frakčná destilácia?",
          options: [
            "Proces oddelenia rôznych zložiek ropy",
            "Metóda extrakcie zemného plynu",
            "Spôsob čistenia vody",
            "Technika výroby plastov",
          ],
          correctAnswer: "Proces oddelenia rôznych zložiek ropy",
        },
        {
          question: "Ktoré z týchto palív sú vyrobené z ropy?",
          options: [
            "Benzín a nafta",
            "Koks a uhlie",
            "Bioplyn a etanol",
            "Lítium a kobalt",
          ],
          correctAnswer: "Benzín a nafta",
        },
        {
          question: "Aký je najväčší svetový spotrebiteľ ropy?",
          options: ["USA", "Japonsko", "Brazília", "Indie"],
          correctAnswer: "USA",
        },
        {
          question:
            "Ktorý proces je využívaný na znižovanie obsahu síry v rope?",
          options: [
            "Hydrokrakování",
            "Katalytická krakování",
            "Reformovanie",
            "Destilácia",
          ],
          correctAnswer: "Hydrokrakování",
        },
        {
          question: "Čo je to ropa Brent?",
          options: [
            "Typ ropy ťažený v Severnom mori",
            "Typ chemického zloženia ropy",
            "Špeciálny druh nafty",
            "Prírodný plyn",
          ],
          correctAnswer: "Typ ropy ťažený v Severnom mori",
        },
        {
          question: "Aký je hlavný dôvod, prečo je ropa cenená?",
          options: [
            "Je hlavným zdrojom energie",
            "Je ľahko dostupná",
            "Má nízke náklady na ťažbu",
            "Neexistujú alternatívy",
          ],
          correctAnswer: "Je hlavným zdrojom energie",
        },
        {
          question: "Ako sa nazýva obdobie, keď sa ceny ropy výrazne menia?",
          options: ["Volatilita", "Konsolidácia", "Stabilizácia", "Trend"],
          correctAnswer: "Volatilita",
        },
        {
          question: "Čo je to OPEC?",
          options: [
            "Organizácia krajín vyvážajúcich ropu",
            "Spoločnosť pre spracovanie ropy",
            "Globálna environmentálna organizácia",
            "Medzinárodná obchodná komora",
          ],
          correctAnswer: "Organizácia krajín vyvážajúcich ropu",
        },
        {
          question: "Ktorý proces sa používa na zlepšenie kvality nafty?",
          options: [
            "Hydrokrakování",
            "Destilácia",
            "Reformovanie",
            "Filtrácia",
          ],
          correctAnswer: "Hydrokrakování",
        },
        {
          question: "Aký je najväčší svetový obchodný trh s ropou?",
          options: ["New York", "Londýn", "Hongkong", "Tokio"],
          correctAnswer: "Londýn",
        },
        {
          question: "Čo je to ropa WTI?",
          options: [
            "Typ ropy ťažený v USA",
            "Typ chemického zloženia ropy",
            "Špeciálny druh nafty",
            "Typ zemného plynu",
          ],
          correctAnswer: "Typ ropy ťažený v USA",
        },
        {
          question: "Aký je primárny produkt ropnej rafinácie?",
          options: ["Benzín", "Lítium", "Hliník", "Cukor"],
          correctAnswer: "Benzín",
        },
      ],
      backgroundImage: require("./categories/chem/chem-ropa.jpg"),
    },

    {
      name: "Čo vieš o plastoch",
      questions: [
        {
          question: "Aký je základný materiál používaný na výrobu plastov?",
          options: ["Ropa", "Kov", "Sklo", "Drevo"],
          correctAnswer: "Ropa",
        },
        {
          question:
            "Ktorý typ plastu je najbežnejší v jednorazových plastových fľašiach?",
          options: [
            "Polyetylén tereftalát (PET)",
            "Polyvinylchlorid (PVC)",
            "Polyetylén (PE)",
            "Polystyrén (PS)",
          ],
          correctAnswer: "Polyetylén tereftalát (PET)",
        },
        {
          question:
            "Ktorý plast je známy svojou odolnosťou voči chemikáliám a teplote?",
          options: [
            "Polykarbonát (PC)",
            "Polypropylén (PP)",
            "Polyetylén (PE)",
            "Polystyrén (PS)",
          ],
          correctAnswer: "Polykarbonát (PC)",
        },
        {
          question: "Aký je proces recyklácie plastov zvyčajne zahŕňajúci?",
          options: [
            "Mechanické drvenie a tavenie",
            "Chemické rozkladanie",
            "Biologické rozkladanie",
            "Rozpúšťanie v roztokoch",
          ],
          correctAnswer: "Mechanické drvenie a tavenie",
        },
        {
          question:
            "Ktorý typ plastu je často používaný v obale na potraviny a nápoje?",
          options: [
            "Polyetylén (PE)",
            "Polypropylén (PP)",
            "Polystyren (PS)",
            "Polyvinylchlorid (PVC)",
          ],
          correctAnswer: "Polyetylén (PE)",
        },
        {
          question: "Čo je hlavný problém s plastami v životnom prostredí?",
          options: [
            "Dlhá doba rozkladu",
            "Nízké náklady na výrobu",
            "Vysoká recyklovateľnosť",
            "Vysoká biologická odbúrateľnosť",
          ],
          correctAnswer: "Dlhá doba rozkladu",
        },
        {
          question: "Aký plast sa používa na výrobu plastových tašiek?",
          options: [
            "Polyetylén (PE)",
            "Polypropylén (PP)",
            "Polystyren (PS)",
            "Polyvinylchlorid (PVC)",
          ],
          correctAnswer: "Polyetylén (PE)",
        },
        {
          question: "Ktorý plast je známy svojou priesvitnosťou a krehkosťou?",
          options: [
            "Polystyren (PS)",
            "Polykarbonát (PC)",
            "Polypropylén (PP)",
            "Polyetylén (PE)",
          ],
          correctAnswer: "Polystyren (PS)",
        },
        {
          question: "Čo je to mikroplast?",
          options: [
            "Malé plastové častice menšie než 5 mm",
            "Veľké plastové kusy nad 50 cm",
            "Plastové výrobky používané v medicíne",
            "Plastové obaly pre elektroniku",
          ],
          correctAnswer: "Malé plastové častice menšie než 5 mm",
        },
        {
          question:
            "Aký typ plastu je známy svojou flexibilitou a použitím vo výrobných procesoch?",
          options: [
            "Polypropylén (PP)",
            "Polyvinylchlorid (PVC)",
            "Polyetylén (PE)",
            "Polystyren (PS)",
          ],
          correctAnswer: "Polypropylén (PP)",
        },
        {
          question: "Ktorý plast sa najčastejšie používa na výrobu hračiek?",
          options: [
            "Polyvinylchlorid (PVC)",
            "Polyetylén (PE)",
            "Polypropylén (PP)",
            "Polystyren (PS)",
          ],
          correctAnswer: "Polyvinylchlorid (PVC)",
        },
        {
          question: "Čo je primárny zdroj pre výrobu bioplastov?",
          options: [
            "Obnoviteľné suroviny ako kukurica alebo zemiaky",
            "Ropa",
            "Zemný plyn",
            "Kovové rudy",
          ],
          correctAnswer: "Obnoviteľné suroviny ako kukurica alebo zemiaky",
        },
        {
          question:
            "Ako sa nazýva proces, keď sa plastové odpady premieňajú na nové produkty?",
          options: ["Recyklácia", "Kompostovanie", "Spalovanie", "Degradácia"],
          correctAnswer: "Recyklácia",
        },
        {
          question:
            "Ktorý plast je známy svojou použiteľnosťou v automobilovom priemysle?",
          options: [
            "Polykarbonát (PC)",
            "Polystyren (PS)",
            "Polyetylén (PE)",
            "Polypropylén (PP)",
          ],
          correctAnswer: "Polykarbonát (PC)",
        },
        {
          question:
            "Aký plast sa používa v elektronických zariadeniach kvôli svojej odolnosti?",
          options: [
            "Polykarbonát (PC)",
            "Polyvinylchlorid (PVC)",
            "Polystyren (PS)",
            "Polyetylén (PE)",
          ],
          correctAnswer: "Polykarbonát (PC)",
        },
      ],
      backgroundImage: require("./categories/chem/chem-plasty.jpg"),
    },

    {
      name: "Všeobecný test z chémie",
      questions: [
        {
          question: "Čo je chemická reakcia?",
          options: [
            "Zmena látok na nové látky",
            "Rozpad látky na menšie častice",
            "Kombinácia dvoch alebo viacerých látok bez zmeny ich zloženia",
            "Pohyb elektrónov medzi atómami",
          ],
          correctAnswer: "Zmena látok na nové látky",
        },
        {
          question: "Aký je chemický symbol pre kyslík?",
          options: ["K", "H", "O", "C"],
          correctAnswer: "O",
        },
        {
          question: "Čo znamená pH hodnotenie 7?",
          options: [
            "Silne zásadité",
            "Neutrálne",
            "Mierne kyslé",
            "Silne kyslé",
          ],
          correctAnswer: "Neutrálne",
        },
        {
          question: "Ktorý z nasledujúcich prvkov je najľahší?",
          options: ["Hélium", "Uhlík", "Vodík", "Kyslík"],
          correctAnswer: "Vodík",
        },
        {
          question: "Aký je chemický vzorec vody?",
          options: ["H2O", "CO2", "NaCl", "CH4"],
          correctAnswer: "H2O",
        },
        {
          question: "Čo je molekula?",
          options: [
            "Najmenšia častica prvku",
            "Kombinácia dvoch alebo viacerých atómov",
            "Pozitívne nabitá častica",
            "Zlúčenina dvoch prvkov",
          ],
          correctAnswer: "Kombinácia dvoch alebo viacerých atómov",
        },
        {
          question: "Ktorá z nasledujúcich látok je kyselina?",
          options: ["Sódium hydroxid", "Sírová kyselina", "Amoniak", "Voda"],
          correctAnswer: "Sírová kyselina",
        },
        {
          question: "Čo je ión?",
          options: [
            "Častica s kladným alebo záporným nábojom",
            "Neutrálny atóm",
            "Najmenšia jednotka molekuly",
            "Častica s neutrálnym nábojom",
          ],
          correctAnswer: "Častica s kladným alebo záporným nábojom",
        },
        {
          question:
            "Ktorý z nasledujúcich kovov je tekutý pri izbovej teplote?",
          options: ["Zlato", "Kadmium", "Ortuť", "Železo"],
          correctAnswer: "Ortuť",
        },
        {
          question:
            "Čo je produkt chemickej reakcie medzi kyselinou a zásadou?",
          options: [
            "Voda a soľ",
            "Kyslík a vodík",
            "Teplo a svetlo",
            "Oxid uhličitý a voda",
          ],
          correctAnswer: "Voda a soľ",
        },
        {
          question:
            "Ktorý z nasledujúcich prvkov sa nachádza v skupine halogénov?",
          options: ["Chlór", "Dusík", "Síra", "Vápnik"],
          correctAnswer: "Chlór",
        },
        {
          question: "Aký je hlavný prvok v organických zlúčeninách?",
          options: ["Kyslík", "Uhlík", "Dusík", "Vodík"],
          correctAnswer: "Uhlík",
        },
        {
          question: "Čo je katalyzátor?",
          options: [
            "Látka, ktorá urýchľuje chemickú reakciu",
            "Látka, ktorá znižuje rýchlosť reakcie",
            "Kovový prvok",
            "Produkt chemickej reakcie",
          ],
          correctAnswer: "Látka, ktorá urýchľuje chemickú reakciu",
        },
        {
          question: "Ktorá z nasledujúcich zmesí je homogénna?",
          options: [
            "Olej a voda",
            "Zlúčenina vody a soli",
            "Piesok a železné piliny",
            "Drevo a kov",
          ],
          correctAnswer: "Zlúčenina vody a soli",
        },
        {
          question: "Aká je najvyššia možná hodnota pH?",
          options: ["14", "7", "0", "10"],
          correctAnswer: "14",
        },
      ],
      backgroundImage: require("./categories/chem/chem-vseo-1.webp"),
    },

    {
      name: "Test na chemické značky prvkov",
      questions: [
        {
          question: "Aký je chemický symbol pre zlato?",
          options: ["Ag", "Au", "Al", "Zn"],
          correctAnswer: "Au",
        },
        {
          question: "Aký je chemický symbol pre vápnik?",
          options: ["Ca", "Cu", "C", "Cl"],
          correctAnswer: "Ca",
        },
        {
          question: "Aký je chemický symbol pre uhlík?",
          options: ["C", "Ca", "Cu", "Co"],
          correctAnswer: "C",
        },
        {
          question: "Aký je chemický symbol pre železo?",
          options: ["Fe", "F", "I", "Zn"],
          correctAnswer: "Fe",
        },
        {
          question: "Aký je chemický symbol pre síru?",
          options: ["Se", "S", "Si", "Sr"],
          correctAnswer: "S",
        },
        {
          question: "Aký je chemický symbol pre sodík?",
          options: ["Na", "Sn", "N", "Si"],
          correctAnswer: "Na",
        },
        {
          question: "Aký je chemický symbol pre dusík?",
          options: ["Ne", "N", "Ni", "No"],
          correctAnswer: "N",
        },
        {
          question: "Aký je chemický symbol pre kyslík?",
          options: ["O", "O2", "Os", "Ox"],
          correctAnswer: "O",
        },
        {
          question: "Aký je chemický symbol pre hélium?",
          options: ["H", "He", "Ho", "Hf"],
          correctAnswer: "He",
        },
        {
          question: "Aký je chemický symbol pre horčík?",
          options: ["Mn", "Mg", "Hg", "Mo"],
          correctAnswer: "Mg",
        },
        {
          question: "Aký je chemický symbol pre zinok?",
          options: ["Zn", "Zr", "Zn2", "Ze"],
          correctAnswer: "Zn",
        },
        {
          question: "Aký je chemický symbol pre olovo?",
          options: ["Pb", "Pt", "Po", "Pl"],
          correctAnswer: "Pb",
        },
        {
          question: "Aký je chemický symbol pre ortuť?",
          options: ["Hg", "He", "Ho", "Hf"],
          correctAnswer: "Hg",
        },
        {
          question: "Aký je chemický symbol pre hliník?",
          options: ["Al", "Ag", "As", "Au"],
          correctAnswer: "Al",
        },
        {
          question: "Aký je chemický symbol pre chlór?",
          options: ["Cl", "C", "Cr", "Co"],
          correctAnswer: "Cl",
        },
      ],
      backgroundImage: require("./categories/chem/chem-per-table.png"),
    },
  ],
  psych: [
    {
      name: "Všeobecný test zo psychológie",
      questions: [
        {
          question: "Čo je to emócia?",
          options: [
            "Fyzická aktivita, ktorá zlepšuje zdravie",
            "Psychologický stav, ktorý ovplyvňuje naše pocity a správanie",
            "Typ osobnosti, ktorý je známy svojím pokojom",
            "Vnútorný sen, ktorý nás núti niečo robiť",
          ],
          correctAnswer:
            "Psychologický stav, ktorý ovplyvňuje naše pocity a správanie",
        },
        {
          question: "Čo je to psychoterapia?",
          options: [
            "Vyhodnocovanie psychických testov",
            "Cvičenie na zlepšenie fyzickej kondície",
            "Liečba duševných problémov prostredníctvom rozhovorov",
            "Lekárske vyšetrenie na diagnostiku chorôb",
          ],
          correctAnswer:
            "Liečba duševných problémov prostredníctvom rozhovorov",
        },
        {
          question: "Čo je stres?",
          options: [
            "Rovnováha medzi pracovným a osobným životom",
            "Reakcia tela na náročné alebo neznáme situácie",
            "Príjemné emocionálne zážitky",
            "Formálne školenie na zlepšenie pracovného výkonu",
          ],
          correctAnswer: "Reakcia tela na náročné alebo neznáme situácie",
        },
        {
          question: "Čo je kognitívna psychológia?",
          options: [
            "Metóda liečby psychických problémov",
            "Analýza správania v skupinách",
            "Zameranie na biologické faktory duševného zdravia",
            "Štúdium duševných procesov ako myslenie a pamäť",
          ],
          correctAnswer: "Štúdium duševných procesov ako myslenie a pamäť",
        },
        {
          question: "Čo znamená termín 'kognitívny' v psychológii?",
          options: [
            "Zameraný na emocionálne prežívanie",
            "Štúdium sociálnych interakcií",
            "Týkajúci sa myslenia a poznávacích procesov",
            "Týkajúci sa biologických aspektov",
          ],
          correctAnswer: "Týkajúci sa myslenia a poznávacích procesov",
        },
        {
          question: "Čo je to neuroplasticita?",
          options: [
            "Zmena v chemickom zložení mozgu počas starnutia",
            "Schopnosť mozgu kompenzovať poškodenie spôsobené úrazom",
            "Schopnosť mozgu meniť svoju štruktúru a funkciu v reakcii na skúsenosti",
            "Vplyv genetických faktorov na psychické zdravie",
          ],
          correctAnswer:
            "Schopnosť mozgu meniť svoju štruktúru a funkciu v reakcii na skúsenosti",
        },
        {
          question: "Čo je psychologický experiment?",
          options: [
            "Metóda na analýzu vnútorných duševných procesov",
            "Technika na zlepšenie pracovného výkonu",
            "Metóda na skúmanie príčin a následkov správania v kontrolovaných podmienkach",
            "Rovnováha medzi pracovným a osobným životom",
          ],
          correctAnswer:
            "Metóda na skúmanie príčin a následkov správania v kontrolovaných podmienkach",
        },
        {
          question: "Čo je to kognitívne skreslenie?",
          options: [
            "Vyváženie medzi rôznymi psychologickými teóriami",
            "Chyba v myslení, ktorá ovplyvňuje rozhodovanie a úsudok",
            "Zmienka o biologických faktory v správaní",
            "Metóda pre zlepšenie sociálnych zručností",
          ],
          correctAnswer:
            "Chyba v myslení, ktorá ovplyvňuje rozhodovanie a úsudok",
        },
        {
          question: "Čo znamená 'psychologický test'?",
          options: [
            "Nástroj na meranie psychických schopností a charakteristík",
            "Technika na zlepšenie emocionálneho stavu",
            "Zariadenie na monitorovanie fyzického zdravia",
            "Metóda na analýzu sociálnych vzťahov",
          ],
          correctAnswer:
            "Nástroj na meranie psychických schopností a charakteristík",
        },
        {
          question: "Čo je to behaviorizmus?",
          options: [
            "Metóda na analýzu vnútorných duševných procesov",
            "Analýza biologických aspektov psychického zdravia",
            "Teória zameraná na štúdium správania a jeho zmien",
            "Technika pre zlepšenie sociálnych schopností",
          ],
          correctAnswer: "Teória zameraná na štúdium správania a jeho zmien",
        },
        {
          question: "Čo je to stresový faktor?",
          options: [
            "Podnet alebo situácia, ktorá vyvoláva stres",
            "Fyzická aktivita na zlepšenie zdravia",
            "Psychologická technika na zvládanie emócií",
            "Liek používaný na liečbu úzkosti",
          ],
          correctAnswer: "Podnet alebo situácia, ktorá vyvoláva stres",
        },
        {
          question: "Čo je to kognitívna terapia?",
          options: [
            "Metóda na zlepšenie sociálnych zručností",
            "Forma psychoterapie zameraná na zmenu negatívnych myšlienkových vzorcov",
            "Technika na zlepšenie pracovnej efektivity",
            "Metóda na analýzu emócií",
          ],
          correctAnswer:
            "Forma psychoterapie zameraná na zmenu negatívnych myšlienkových vzorcov",
        },
        {
          question: "Čo znamená 'psychologická odolnosť'?",
          options: [
            "Úroveň intelektuálnych schopností",
            "Kapacita pre emocionálne prežívanie",
            "Schopnosť zvládať stres a ťažké situácie",
            "Zručnosti v oblasti sociálnych interakcií",
          ],
          correctAnswer: "Schopnosť zvládať stres a ťažké situácie",
        },
        {
          question: "Čo je to motivácia?",
          options: [
            "Sila, ktorá nás podnecuje k činnosti alebo dosiahnutiu cieľa",
            "Emocionálny stav spojený s relaxáciou",
            "Vnútorná schopnosť na analytické myslenie",
            "Proces, ktorý ovplyvňuje naše fyzické zdravie",
          ],
          correctAnswer:
            "Sila, ktorá nás podnecuje k činnosti alebo dosiahnutiu cieľa",
        },
        {
          question: "Čo je to kognitívny vývoj?",
          options: [
            "Schopnosť adaptovať sa na fyzické zmeny",
            "Proces, počas ktorého sa vyvíjajú mentálne schopnosti ako myslenie a učenie",
            "Schopnosť vyrovnať sa s emocionálnymi ťažkosťami",
            "Rovnováha medzi pracovným a osobným životom",
          ],
          correctAnswer:
            "Proces, počas ktorého sa vyvíjajú mentálne schopnosti ako myslenie a učenie",
        },
      ],
      backgroundImage: require("./categories/psych/psych-vseo-1.jpg"),
    },

    {
      name: "Všeobecný test o psychiatrických diagnózach",
      questions: [
        {
          question: "Čo je schizofrénia?",
          options: [
            "Porucha nálady",
            "Psychotická porucha",
            "Úzkostná porucha",
            "Osobnostná porucha",
          ],
          correctAnswer: "Psychotická porucha",
        },
        {
          question: "Ktorý z nasledujúcich príznakov je typický pre depresiu?",
          options: [
            "Chronická úzkosť",
            "Pretrvávajúca smutná nálada",
            "Halucinácie",
            "Hyperaktivita",
          ],
          correctAnswer: "Pretrvávajúca smutná nálada",
        },
        {
          question: "Čo charakterizuje bipolárnu poruchu?",
          options: [
            "Striedanie obdobia depresie a manie",
            "Trvalá únava",
            "Chronická úzkosť",
            "Nespavosť",
          ],
          correctAnswer: "Striedanie obdobia depresie a manie",
        },
        {
          question:
            "Ako sa nazýva porucha, pri ktorej človek trpí prehnaným strachom z verejných priestorov?",
          options: [
            "Agorafóbia",
            "Klaustrofóbia",
            "Arachnofóbia",
            "Sociálna fóbia",
          ],
          correctAnswer: "Agorafóbia",
        },
        {
          question:
            "Ktorá z nasledujúcich porúch patrí medzi úzkostné poruchy?",
          options: [
            "Generalizovaná úzkostná porucha",
            "Disociatívna porucha identity",
            "Schizofrénia",
            "Bipolárna porucha",
          ],
          correctAnswer: "Generalizovaná úzkostná porucha",
        },
        {
          question:
            "Aký je typický príznak posttraumatickej stresovej poruchy (PTSD)?",
          options: [
            "Nočné mory a flashbacky",
            "Neschopnosť zaspať",
            "Zvýšená energia",
            "Poruchy príjmu potravy",
          ],
          correctAnswer: "Nočné mory a flashbacky",
        },
        {
          question:
            "Ktorá z nasledujúcich porúch sa vyznačuje pretrvávajúcim smutkom a nedostatkom záujmu o každodenné aktivity?",
          options: [
            "Bipolárna porucha",
            "Schizoafektívna porucha",
            "Depresia",
            "Panická porucha",
          ],
          correctAnswer: "Depresia",
        },
        {
          question:
            "Ktorá z nasledujúcich porúch sa prejavuje nekontrolovanými záchvatmi úzkosti?",
          options: [
            "Panická porucha",
            "Obsedantno-kompulzívna porucha",
            "Schizofrénia",
            "Dystýmia",
          ],
          correctAnswer: "Panická porucha",
        },
        {
          question: "Čo je obsedantno-kompulzívna porucha (OCD)?",
          options: [
            "Porucha osobnosti",
            "Psychotická porucha",
            "Úzkostná porucha",
            "Porucha nálady",
          ],
          correctAnswer: "Úzkostná porucha",
        },
        {
          question: "Aký je hlavný príznak disociatívnej poruchy identity?",
          options: [
            "Strata pamäti",
            "Rozdelenie osobnosti na dve alebo viac identít",
            "Chronická únava",
            "Halucinácie",
          ],
          correctAnswer: "Rozdelenie osobnosti na dve alebo viac identít",
        },
        {
          question:
            "Ktorá z nasledujúcich diagnóz je spojená s prehnanou sebaúctou a potrebou obdivu?",
          options: [
            "Narcistická porucha osobnosti",
            "Depresia",
            "Agorafóbia",
            "Generalizovaná úzkostná porucha",
          ],
          correctAnswer: "Narcistická porucha osobnosti",
        },
        {
          question:
            "Ktorá z nasledujúcich porúch zahŕňa pretrvávajúci pocit nedostatočnej hodnoty a nízke sebavedomie?",
          options: [
            "Úzkostná porucha",
            "Schizofrénia",
            "Depresia",
            "Vyhýbavá porucha osobnosti",
          ],
          correctAnswer: "Vyhýbavá porucha osobnosti",
        },
        {
          question:
            "Ktorá z nasledujúcich diagnóz sa prejavuje epizódami prejedania a následného čistenia (vracania)?",
          options: [
            "Anorexia nervosa",
            "Bulímia nervosa",
            "Posttraumatická stresová porucha",
            "Panická porucha",
          ],
          correctAnswer: "Bulímia nervosa",
        },
        {
          question:
            "Ktorý z nasledujúcich príznakov je typický pre poruchu pozornosti s hyperaktivitou (ADHD)?",
          options: [
            "Neschopnosť sústrediť sa",
            "Zvýšená energia",
            "Chronická únava",
            "Nespavosť",
          ],
          correctAnswer: "Neschopnosť sústrediť sa",
        },
        {
          question:
            "Ktorá z nasledujúcich diagnóz sa prejavuje výrazným strachom z toho, že bude človek odmietnutý alebo ponížený?",
          options: [
            "Sociálna fóbia",
            "Generalizovaná úzkostná porucha",
            "Disociatívna porucha identity",
            "Panická porucha",
          ],
          correctAnswer: "Sociálna fóbia",
        },
      ],
      backgroundImage: require("./categories/psych/psych-diagn-1.jpg"),
    },
  ],

  phys: [
    {
      name: "Všeobecný fyzikálny test",
      questions: [
        {
          question: "Aká je jednotka sily v sústave SI?",
          options: ["Watt (W)", "Newton (N)", "Pascal (Pa)", "Joule (J)"],
          correctAnswer: "Newton (N)",
        },
        {
          question: "Čo je to rýchlosť?",
          options: [
            "Zmena teploty za jednotku času",
            "Zmena polohy za jednotku času",
            "Zmena tlaku za jednotku času",
            "Zmena energie za jednotku času",
          ],
          correctAnswer: "Zmena polohy za jednotku času",
        },
        {
          question: "Aký je vzťah medzi hmotnosťou a gravitačnou silou?",
          options: [
            "Gravitačná sila je nezávislá od hmotnosti",
            "Gravitačná sila je priamo úmerná hmotnosti",
            "Gravitačná sila je priamo úmerná rýchlosti",
            "Gravitačná sila je nepriamo úmerná hmotnosti",
          ],
          correctAnswer: "Gravitačná sila je priamo úmerná hmotnosti",
        },
        {
          question: "Čo meria prístroj zvaný barometer?",
          options: [
            "Hmotnosť",
            "Atmosférický tlak",
            "Teplotu",
            "Rýchlosť vetra",
          ],
          correctAnswer: "Atmosférický tlak",
        },
        {
          question: "Aká je hodnota zrýchlenia voľného pádu na Zemi?",
          options: ["9,8 m/s²", "1 m/s²", "5,5 m/s²", "10,5 m/s²"],
          correctAnswer: "9,8 m/s²",
        },
        {
          question: "Čo je to kinetická energia?",
          options: [
            "Energia, ktorá sa získava zo svetla",
            "Energia spojená s pohybom telesa",
            "Energia uložená v chemických väzbách",
            "Energia spojená s polohou telesa",
          ],
          correctAnswer: "Energia spojená s pohybom telesa",
        },
        {
          question: "Aký je vzorec pre výpočet práce?",
          options: ["F = m * a", "P = m * v", "W = F * s", "E = m * c²"],
          correctAnswer: "W = F * s",
        },
        {
          question: "Čo je to elektrický odpor?",
          options: [
            "Odpor voči prúdeniu elektrického prúdu",
            "Sila medzi dvoma nábojmi",
            "Množstvo elektrického náboja",
            "Rýchlosť prúdenia elektrického prúdu",
          ],
          correctAnswer: "Odpor voči prúdeniu elektrického prúdu",
        },
        {
          question: "Aký je základný vzorec Ohmovho zákona?",
          options: ["E = m * g * h", "V = d * t", "U = R * I", "P = U * I"],
          correctAnswer: "U = R * I",
        },
        {
          question:
            "Ktorá veličina určuje energiu uloženú v objekte na základe jeho polohy?",
          options: [
            "Teplotná energia",
            "Kinetická energia",
            "Elektrická energia",
            "Potenciálna energia",
          ],
          correctAnswer: "Potenciálna energia",
        },
        {
          question: "Aká je základná jednotka elektrického prúdu v sústave SI?",
          options: ["Watt (W)", "Ampér (A)", "Ohm (Ω)", "Volt (V)"],
          correctAnswer: "Ampér (A)",
        },
        {
          question: "Čo je to frekvencia?",
          options: [
            "Množstvo sily za jednotku času",
            "Množstvo energie za jednotku času",
            "Počet otáčok za minútu",
            "Počet cyklov za sekundu",
          ],
          correctAnswer: "Počet cyklov za sekundu",
        },
        {
          question: "Aký je vzorec pre výpočet hustoty?",
          options: ["F = m * a", "V = d * t", "P = F * s", "ρ = m/V"],
          correctAnswer: "ρ = m/V",
        },
        {
          question: "Aká je jednotka práce v sústave SI?",
          options: ["Newton (N)", "Pascal (Pa)", "Joule (J)", "Watt (W)"],
          correctAnswer: "Joule (J)",
        },
        {
          question: "Čo meria termometer?",
          options: ["Tlak", "Dĺžku", "Hmotnosť", "Teplotu"],
          correctAnswer: "Teplotu",
        },
      ],
      backgroundImage: require("./categories/phys/phys-vseo-1.jpg"),
    },

    {
      name: "Elektrický prúd",
      questions: [
        {
          question: "Čo je to elektrický prúd?",
          options: [
            "Usmernený pohyb elektrických nábojov",
            "Množstvo energie v obvode",
            "Odpor voči prúdeniu elektriny",
            "Rýchlosť prúdenia elektrónov",
          ],
          correctAnswer: "Usmernený pohyb elektrických nábojov",
        },
        {
          question: "Aká je základná jednotka elektrického prúdu v sústave SI?",
          options: ["Ampér (A)", "Volt (V)", "Ohm (Ω)", "Watt (W)"],
          correctAnswer: "Ampér (A)",
        },
        {
          question: "Aký prístroj sa používa na meranie elektrického prúdu?",
          options: ["Amperometer", "Voltometer", "Ohmmeter", "Galvanometer"],
          correctAnswer: "Amperometer",
        },
        {
          question: "Čo určuje smer elektrického prúdu v kovovom vodiči?",
          options: [
            "Smer pohybu kladných nábojov",
            "Smer pohybu záporných nábojov",
            "Teplota vodiča",
            "Množstvo náboja",
          ],
          correctAnswer: "Smer pohybu kladných nábojov",
        },
        {
          question: "Aký je vzorec pre Ohmov zákon?",
          options: ["U = R * I", "P = U * I", "V = d * t", "I = Q / t"],
          correctAnswer: "U = R * I",
        },
        {
          question: "Čo je to odpor?",
          options: [
            "Odpor voči prúdeniu elektrického prúdu",
            "Rýchlosť prúdenia prúdu",
            "Energia v obvode",
            "Množstvo elektrického náboja",
          ],
          correctAnswer: "Odpor voči prúdeniu elektrického prúdu",
        },
        {
          question: "Aká je jednotka elektrického odporu?",
          options: ["Ohm (Ω)", "Volt (V)", "Ampér (A)", "Joule (J)"],
          correctAnswer: "Ohm (Ω)",
        },
        {
          question: "Čo je to napätie v elektrickom obvode?",
          options: [
            "Rozdiel elektrického potenciálu medzi dvoma bodmi",
            "Rýchlosť prúdenia elektrónov",
            "Množstvo náboja prechádzajúce cez obvod",
            "Energia v obvode",
          ],
          correctAnswer: "Rozdiel elektrického potenciálu medzi dvoma bodmi",
        },
        {
          question: "Aký je vzťah medzi napätím, prúdom a odporom v obvode?",
          options: ["U = R * I", "P = m * v", "V = d * t", "F = m * a"],
          correctAnswer: "U = R * I",
        },
        {
          question: "Aký prístroj sa používa na meranie elektrického napätia?",
          options: ["Voltometer", "Amperometer", "Ohmmeter", "Galvanometer"],
          correctAnswer: "Voltometer",
        },
        {
          question: "Čo je to elektrický výkon?",
          options: [
            "Množstvo práce vykonanej za jednotku času",
            "Množstvo prúdu v obvode",
            "Množstvo náboja prechádzajúce cez obvod",
            "Rýchlosť prúdenia elektrónov",
          ],
          correctAnswer: "Množstvo práce vykonanej za jednotku času",
        },
        {
          question: "Aká je jednotka elektrického výkonu?",
          options: ["Watt (W)", "Volt (V)", "Ohm (Ω)", "Ampér (A)"],
          correctAnswer: "Watt (W)",
        },
        {
          question: "Čo je to galvanický článok?",
          options: [
            "Zariadenie, ktoré premieňa chemickú energiu na elektrickú",
            "Zariadenie na meranie elektrického prúdu",
            "Zariadenie na zmenu napätia",
            "Súčasť elektrického obvodu",
          ],
          correctAnswer:
            "Zariadenie, ktoré premieňa chemickú energiu na elektrickú",
        },
        {
          question:
            "Ako sa nazýva zákon, ktorý hovorí o zachovaní elektrického náboja?",
          options: [
            "Zákon zachovania náboja",
            "Ohmov zákon",
            "Faradayov zákon",
            "Kirchhoffov zákon",
          ],
          correctAnswer: "Zákon zachovania náboja",
        },
        {
          question:
            "Ktorý z nasledujúcich materiálov je dobrým vodičom elektrického prúdu?",
          options: ["Meď", "Sklo", "Guma", "Drevo"],
          correctAnswer: "Meď",
        },
      ],
      backgroundImage: require("./categories/phys/phys-prud.jpg"),
    },

    {
      name: "Základy akustiky",
      questions: [
        {
          question: "Čo je akustika?",
          options: [
            "Veda o svetle",
            "Veda o elektrine",
            "Veda o pohybe",
            "Veda o zvuku",
          ],
          correctAnswer: "Veda o zvuku",
        },
        {
          question: "Aká je základná jednotka frekvencie v sústave SI?",
          options: ["Joule (J)", "Newton (N)", "Hertz (Hz)", "Watt (W)"],
          correctAnswer: "Hertz (Hz)",
        },
        {
          question: "Čo je to echolokácia?",
          options: [
            "Metóda určovania vzdialenosti pomocou svetelných vĺn",
            "Metóda určovania vzdialenosti pomocou odrazu zvukových vĺn",
            "Metóda určovania tlaku pomocou svetla",
            "Metóda určovania teploty pomocou zvuku",
          ],
          correctAnswer:
            "Metóda určovania vzdialenosti pomocou odrazu zvukových vĺn",
        },
        {
          question: "Akú vlastnosť zvuku označuje termín 'hlučnosť'?",
          options: [
            "Frekvenciu zvuku",
            "Vlnovú dĺžku zvuku",
            "Rýchlosť zvuku",
            "Intenzitu zvuku",
          ],
          correctAnswer: "Intenzitu zvuku",
        },
        {
          question:
            "Ktorý z nasledujúcich materiálov najlepšie absorbuje zvuk?",
          options: ["Plast", "Sklo", "Pena", "Kov"],
          correctAnswer: "Pena",
        },
        {
          question:
            "Aká je rýchlosť zvuku vo vzduchu pri normálnych podmienkach?",
          options: [
            "Približne 3000 m/s",
            "Približne 1500 m/s",
            "Približne 343 m/s",
            "Približne 500 m/s",
          ],
          correctAnswer: "Približne 343 m/s",
        },
        {
          question: "Čo označuje termín 'vlnová dĺžka' zvuku?",
          options: [
            "Vzdialenosť medzi dvoma po sebe idúcimi vrcholmi vlny",
            "Čas, za ktorý zvuk prejde určitú vzdialenosť",
            "Intenzita zvukového signálu",
            "Frekvencia zvukovej vlny",
          ],
          correctAnswer:
            "Vzdialenosť medzi dvoma po sebe idúcimi vrcholmi vlny",
        },
        {
          question: "Aký je vzťah medzi frekvenciou a výškou zvuku?",
          options: [
            "Výška zvuku je nezávislá od frekvencie",
            "Frekvencia nemá žiadny vplyv na výšku zvuku",
            "Vyššia frekvencia znamená nižšiu výšku zvuku",
            "Vyššia frekvencia znamená vyššiu výšku zvuku",
          ],
          correctAnswer: "Vyššia frekvencia znamená vyššiu výšku zvuku",
        },
        {
          question:
            "Ktorý z nasledujúcich pojmov sa používa na opis vlnového pohybu zvuku?",
          options: [
            "Gravitačné vlny",
            "Magnetické vlny",
            "Elektrické vlny",
            "Tlakové vlny",
          ],
          correctAnswer: "Tlakové vlny",
        },
        {
          question: "Čo je to Dopplerov efekt?",
          options: [
            "Zmena frekvencie zvuku v dôsledku pohybu zdroja alebo pozorovateľa",
            "Zmena výšky zvuku v dôsledku zmeny vzdialenosti",
            "Zmena intenzity zvuku v dôsledku absorbovania",
            "Zmena vlnovej dĺžky zvuku v dôsledku zmeny materiálu",
          ],
          correctAnswer:
            "Zmena frekvencie zvuku v dôsledku pohybu zdroja alebo pozorovateľa",
        },
        {
          question: "Čo označuje termín 'rezonancia' v akustike?",
          options: [
            "Zmena frekvencie zvuku v dôsledku zmeny materiálu",
            "Zvýšenie amplitúdy zvuku v dôsledku zhody frekvencií",
            "Zníženie intenzity zvuku v dôsledku absorbovania",
            "Vytvorenie nových zvukových vĺn v dôsledku interferencie",
          ],
          correctAnswer: "Zvýšenie amplitúdy zvuku v dôsledku zhody frekvencií",
        },
        {
          question:
            "Ako sa nazýva efekt, keď sa zvukové vlny odrážajú od prekážky a vracajú sa späť k zdroju?",
          options: ["Rezonancia", "Echolokácia", "Interferencia", "Odrážanie"],
          correctAnswer: "Odrážanie",
        },
        {
          question: "Čo je to frekvencia zvukovej vlny?",
          options: [
            "Čas, za ktorý zvuk prejde určitú vzdialenosť",
            "Počet kmitov vlny za sekundu",
            "Vzdialenosť medzi vrcholmi vlny",
            "Intenzita zvukového signálu",
          ],
          correctAnswer: "Počet kmitov vlny za sekundu",
        },
        {
          question:
            "Ako sa mení rýchlosť zvuku v závislosti od teploty vzduchu?",
          options: [
            "Rýchlosť zvuku zostáva nezmenená pri zmene teploty",
            "Rýchlosť zvuku sa nemení a závisí od tlaku",
            "Rýchlosť zvuku rastie s rastúcou teplotou",
            "Rýchlosť zvuku klesá s rastúcou teplotou",
          ],
          correctAnswer: "Rýchlosť zvuku rastie s rastúcou teplotou",
        },
        {
          question: "Aký je rozdiel medzi 'zvukom' a 'šumom' v akustike?",
          options: [
            "Zvuk je tichý, šum je hlasný",
            "Zvuk je chaotický, šum je pravidelný",
            "Zvuk je pravidelný a harmonický, šum je nepravidelný a chaotický",
            "Zvuk je vysokofrekvenčný, šum je nízkofrekvenčný",
          ],
          correctAnswer:
            "Zvuk je pravidelný a harmonický, šum je nepravidelný a chaotický",
        },
      ],
      backgroundImage: require("./categories/phys/phys-zaklady-akustiky.jpg"),
    },

    {
      name: "Test z digitálnej fotografie",
      questions: [
        {
          question: "Čo znamená pojem 'ISO' v digitálnej fotografii?",
          options: [
            "Veľkosť objektívu",
            "Citlivosť snímača na svetlo",
            "Rýchlosť uzávierky",
            "Kvalita rozlíšenia",
          ],
          correctAnswer: "Citlivosť snímača na svetlo",
        },
        {
          question: "Aký efekt spôsobí dlhý čas uzávierky?",
          options: [
            "Zachytí pohyb rozmazane",
            "Zvyšuje hĺbku ostrosti",
            "Znižuje citlivosť ISO",
            "Vytvára ostré fotografie pri slabom svetle",
          ],
          correctAnswer: "Zachytí pohyb rozmazane",
        },
        {
          question: "Čo je to clona (apertura)?",
          options: [
            "Veľkosť otvoru, ktorým prechádza svetlo do objektívu",
            "Zoom objektívu",
            "Čas uzávierky",
            "Otváranie a zatváranie objektívu",
          ],
          correctAnswer: "Veľkosť otvoru, ktorým prechádza svetlo do objektívu",
        },
        {
          question: "Čo vyjadruje ohnisková vzdialenosť objektívu?",
          options: [
            "Uhol záberu a približenie objektívu",
            "Čas uzávierky",
            "Vzdialenosť medzi objektívom a snímačom",
            "Veľkosť snímača",
          ],
          correctAnswer: "Uhol záberu a približenie objektívu",
        },
        {
          question: "Čo znamená pojem 'bokeh'?",
          options: [
            "Rozostrenie pozadia pri nízkej hĺbke ostrosti",
            "Zachytenie pohybu v záberoch",
            "Použitie vysokej citlivosti ISO",
            "Fotografovanie krajiny",
          ],
          correctAnswer: "Rozostrenie pozadia pri nízkej hĺbke ostrosti",
        },
        {
          question:
            "Aký je účel vyváženia bielej (white balance) v digitálnej fotografii?",
          options: [
            "Redukcia šumu",
            "Správne zobrazenie farieb na fotografii",
            "Zvýšenie jasnosti fotografie",
            "Zvýšenie kontrastu",
          ],
          correctAnswer: "Správne zobrazenie farieb na fotografii",
        },
        {
          question: "Čo spôsobí použitie vyššej citlivosti ISO?",
          options: [
            "Znižuje jasnosť fotografie",
            "Zlepšuje ostrosť obrazu",
            "Zvyšuje jasnosť a šum vo fotografii",
            "Redukuje šum vo fotografii",
          ],
          correctAnswer: "Zvyšuje jasnosť a šum vo fotografii",
        },
        {
          question: "Čo označuje pojem 'RAW' formát?",
          options: [
            "Formát na zdieľanie fotografií online",
            "Formát používaný pre video",
            "Komprimovaný formát na rýchle ukladanie",
            "Nekomprimovaný formát zachytávajúci všetky dáta zo snímača",
          ],
          correctAnswer:
            "Nekomprimovaný formát zachytávajúci všetky dáta zo snímača",
        },
        {
          question: "Čo je to histogram v digitálnej fotografii?",
          options: [
            "Zobrazenie veľkosti fotografie",
            "Nástroj na úpravu farieb",
            "Graf znázorňujúci rozloženie jasov na fotografii",
            "Graf znázorňujúci rozlíšenie fotografie",
          ],
          correctAnswer: "Graf znázorňujúci rozloženie jasov na fotografii",
        },
        {
          question: "Čo znamená pojem 'kompozícia' v digitálnej fotografii?",
          options: [
            "Použitie filtra na objektíve",
            "Nastavenie citlivosti ISO",
            "Usporiadanie prvkov v zábere",
            "Vyváženie bielej farby",
          ],
          correctAnswer: "Usporiadanie prvkov v zábere",
        },
        {
          question: "Aký efekt má použitie širokouhlého objektívu?",
          options: [
            "Rozšírenie záberu a zachytenie väčšej scény",
            "Zachytenie detailov na diaľku",
            "Zúženie zorného poľa",
            "Rozmazanie pozadia",
          ],
          correctAnswer: "Rozšírenie záberu a zachytenie väčšej scény",
        },
        {
          question: "Čo je 'expozícia' v digitálnej fotografii?",
          options: [
            "Rýchlosť uzávierky",
            "Množstvo svetla, ktoré dopadá na snímač",
            "Citlivosť ISO",
            "Uhol záberu objektívu",
          ],
          correctAnswer: "Množstvo svetla, ktoré dopadá na snímač",
        },
        {
          question: "Čo sa stane, keď znížite hodnotu clony (apertury)?",
          options: [
            "Zníži sa hĺbka ostrosti a viac svetla prejde cez objektív",
            "Zvýši sa hĺbka ostrosti a menej svetla prejde cez objektív",
            "Zlepší sa ostrosť fotografie",
            "Zvýši sa hĺbka ostrosti a viac svetla prejde cez objektív",
          ],
          correctAnswer:
            "Zníži sa hĺbka ostrosti a viac svetla prejde cez objektív",
        },
        {
          question: "Čo je 'expozičný trojuholník'?",
          options: [
            "Grafické zobrazenie histogramu",
            "Pomôcka na úpravu vyváženia bielej",
            "Vztah medzi clonou, rýchlosťou uzávierky a citlivosťou ISO",
            "Vztah medzi ohniskovou vzdialenosťou, clonou a zoomom",
          ],
          correctAnswer:
            "Vztah medzi clonou, rýchlosťou uzávierky a citlivosťou ISO",
        },
        {
          question: "Ako ovplyvní vysoká hodnota ISO kvalitu fotografie?",
          options: [
            "Zlepší farby",
            "Pridá viac šumu a zvýši svetlosť",
            "Zlepší ostrosť",
            "Zníži kontrast",
          ],
          correctAnswer: "Pridá viac šumu a zvýši svetlosť",
        },
      ],
      backgroundImage: require("./categories/phys/phys-photography.jpg"),
    },

    {
      name: "Rádioaktivita",
      questions: [
        {
          question: "Čo je to ionizujúce žiarenie?",
          options: [
            "Žiarenie, ktoré je neškodné pre živé organizmy",
            "Žiarenie, ktoré neinteraguje s hmotou",
            "Žiarenie, ktoré má nízku energiu",
            "Žiarenie, ktoré má dostatok energie na odstránenie elektrónov z atómov",
          ],
          correctAnswer:
            "Žiarenie, ktoré má dostatok energie na odstránenie elektrónov z atómov",
        },
        {
          question: "Aké typy ionizujúceho žiarenia existujú?",
          options: [
            "Infračervené, ultrafialové a rádiové vlny",
            "Len svetelné a zvukové vlny",
            "Alfa, beta a svetelné žiarenie",
            "Alfa, beta, gama a röntgenové žiarenie",
          ],
          correctAnswer: "Alfa, beta, gama a röntgenové žiarenie",
        },
        {
          question: "Ktoré žiarenie má najväčšiu prenikavosť?",
          options: [
            "Gama žiarenie",
            "Alfa žiarenie",
            "Ultrafialové žiarenie",
            "Beta žiarenie",
          ],
          correctAnswer: "Gama žiarenie",
        },
        {
          question: "Čo môže blokovať alfa žiarenie?",
          options: [
            "Hrubá vrstva vzduchu",
            "Olovená doska",
            "Betónová stena",
            "List papiera",
          ],
          correctAnswer: "List papiera",
        },
        {
          question: "Ktorý prístroj sa používa na meranie radiácie?",
          options: [
            "Termometer",
            "Teleskop",
            "Geiger-Müllerov počítač",
            "Barometer",
          ],
          correctAnswer: "Geiger-Müllerov počítač",
        },
        {
          question: "Aká je hlavná príčina prírodnej radiácie?",
          options: [
            "Zariadenia elektroniky",
            "Vydychovaný vzduch",
            "Počasie a zmeny teploty",
            "Kozmické žiarenie a rádioaktívne prvky v zemi",
          ],
          correctAnswer: "Kozmické žiarenie a rádioaktívne prvky v zemi",
        },
        {
          question: "Čo spôsobuje radónový plyn v dome?",
          options: [
            "Spôsobuje elektrické výboje",
            "Zvyšuje teplotu v dome",
            "Je neškodný a neinteraguje s človekom",
            "Môže zvýšiť riziko vzniku rakoviny pľúc",
          ],
          correctAnswer: "Môže zvýšiť riziko vzniku rakoviny pľúc",
        },
        {
          question: "Ako ovplyvňuje vysoká dávka radiácie ľudské telo?",
          options: [
            "Neovplyvňuje telo",
            "Zvyšuje imunitu",
            "Môže poškodiť bunky a tkanivá, viesť k ochoreniam alebo smrti",
            "Zlepšuje trávenie",
          ],
          correctAnswer:
            "Môže poškodiť bunky a tkanivá, viesť k ochoreniam alebo smrti",
        },
        {
          question:
            "Čo sa používa na ochranu pred radiáciou v jadrových elektrárňach?",
          options: [
            "Kovové dosky",
            "Olovo, betón a voda",
            "Drevené steny",
            "Plastové bariéry",
          ],
          correctAnswer: "Olovo, betón a voda",
        },
        {
          question: "Čo je polčas rozpadu rádioaktívneho prvku?",
          options: [
            "Čas, za ktorý látka získa dvojnásobok svojej hmotnosti",
            "Čas, za ktorý látka stratí všetky svoje rádioaktívne vlastnosti",
            "Čas, za ktorý sa rozpadne polovica atómov v látke",
            "Čas, za ktorý prvok prestane byť rádioaktívny",
          ],
          correctAnswer: "Čas, za ktorý sa rozpadne polovica atómov v látke",
        },
        {
          question: "Čo je zdrojom kozmického žiarenia?",
          options: [
            "Rádioaktívne prvky v pôde",
            "Mikrovlnné rúry",
            "Slnko a iné hviezdy",
            "Jadrové reaktory",
          ],
          correctAnswer: "Slnko a iné hviezdy",
        },
        {
          question: "Aký účinok má röntgenové žiarenie na ľudské telo?",
          options: [
            "Znižuje riziko rakoviny",
            "Môže poškodiť bunky a spôsobiť mutácie",
            "Ožaruje telo bez akéhokoľvek efektu",
            "Zlepšuje krvný obeh",
          ],
          correctAnswer: "Môže poškodiť bunky a spôsobiť mutácie",
        },
        {
          question: "Ktorý z týchto prvkov je rádioaktívny?",
          options: ["Hélium", "Uránium", "Sodík", "Zlato"],
          correctAnswer: "Uránium",
        },
        {
          question:
            "Aké sú hlavné dôsledky dlhodobého vystavenia nízkym dávkam radiácie?",
          options: [
            "Môže viesť k zvýšenému riziku vzniku rakoviny",
            "Znižuje hmotnosť tela",
            "Zlepšuje regeneráciu buniek",
            "Zlepšuje imunitný systém",
          ],
          correctAnswer: "Môže viesť k zvýšenému riziku vzniku rakoviny",
        },
        {
          question: "Čo je to radiácia?",
          options: [
            "Prenos energie vo forme vĺn alebo častíc",
            "Zmena farby látok vplyvom slnka",
            "Proces, pri ktorom sa látky stávajú tuhými",
            "Pohyb atómov v tekutinách",
          ],
          correctAnswer: "Prenos energie vo forme vĺn alebo častíc",
        },
      ],
      backgroundImage: require("./categories/phys/phys-radiacia.png"),
    },
  ],

  lang: [
    {
      name: "Preklady krátkych viet  (EN - SK): Téma Rodina",
      questions: [
        {
          question: "Preložte do Slovenčiny: 'My father is a doctor.'",
          options: [
            "Môj otec je lekár.",
            "Môj otec je učiteľ.",
            "Môj otec je inžinier.",
            "Môj otec je právnik.",
          ],
          correctAnswer: "Môj otec je lekár.",
        },
        {
          question: "Preložte do Slovenčiny: 'My mother is cooking dinner.'",
          options: [
            "Moja matka varí večeru.",
            "Moja matka číta knihu.",
            "Moja matka upratuje dom.",
            "Moja matka záhradníči.",
          ],
          correctAnswer: "Moja matka varí večeru.",
        },
        {
          question: "Preložte do Slovenčiny: 'My brother is 10 years old.'",
          options: [
            "Môj brat má 10 rokov.",
            "Môj brat má 8 rokov.",
            "Môj brat má 12 rokov.",
            "Môj brat má 14 rokov.",
          ],
          correctAnswer: "Môj brat má 10 rokov.",
        },
        {
          question:
            "Preložte do Slovenčiny: 'Our sister is studying at university.'",
          options: [
            "Naša sestra študuje na univerzite.",
            "Naša sestra pracuje v firme.",
            "Naša sestra hrá šport.",
            "Naša sestra cestuje do zahraničia.",
          ],
          correctAnswer: "Naša sestra študuje na univerzite.",
        },
        {
          question: "Preložte do Slovenčiny: 'Grandparents are visiting.'",
          options: [
            "Starí rodičia sú na návšteve.",
            "Starí rodičia pracujú.",
            "Starí rodičia nakupujú.",
            "Starí rodičia spia.",
          ],
          correctAnswer: "Starí rodičia sú na návšteve.",
        },
        {
          question:
            "Preložte do Slovenčiny: 'I have one sister and one brother.'",
          options: [
            "Mám jednu sestru a jedného brata.",
            "Mám dve sestry a jedného brata.",
            "Mám jednu sestru a dvoch bratov.",
            "Mám dve sestry a dvoch bratov.",
          ],
          correctAnswer: "Mám jednu sestru a jedného brata.",
        },
        {
          question: "Preložte do Slovenčiny: 'My uncle is a teacher.'",
          options: [
            "Môj strýko je učiteľ.",
            "Môj strýko je lekár.",
            "Môj strýko je inžinier.",
            "Môj strýko je právnik.",
          ],
          correctAnswer: "Môj strýko je učiteľ.",
        },
        {
          question:
            "Preložte do Slovenčiny: 'My aunt lives in another country.'",
          options: [
            "Moja teta býva v inej krajine.",
            "Moja teta býva v blízkosti.",
            "Moja teta nás navštevuje.",
            "Moja teta pracuje tu.",
          ],
          correctAnswer: "Moja teta býva v inej krajine.",
        },
        {
          question:
            "Preložte do Slovenčiny: 'My father and mother are married.'",
          options: [
            "Môj otec a matka sú manželia.",
            "Môj otec a matka sú priatelia.",
            "Môj otec a matka sú kolegovia.",
            "Môj otec a matka sú susedia.",
          ],
          correctAnswer: "Môj otec a matka sú manželia.",
        },
        {
          question: "Preložte do Slovenčiny: 'Siblings are at school.'",
          options: [
            "Súrodenci sú v škole.",
            "Súrodenci sú doma.",
            "Súrodenci sú v práci.",
            "Súrodenci sú na dovolenke.",
          ],
          correctAnswer: "Súrodenci sú v škole.",
        },
        {
          question: "Preložte do Slovenčiny: 'Our family has a dog.'",
          options: [
            "Naša rodina má psa.",
            "Naša rodina má mačku.",
            "Naša rodina má králika.",
            "Naša rodina má vtáka.",
          ],
          correctAnswer: "Naša rodina má psa.",
        },
        {
          question: "Preložte do Slovenčiny: 'My brother plays the guitar.'",
          options: [
            "Môj brat hrá na gitare.",
            "Môj brat hrá na klavíri.",
            "Môj brat hrá futbal.",
            "Môj brat hrá basketbal.",
          ],
          correctAnswer: "Môj brat hrá na gitare.",
        },
        {
          question: "Preložte do Slovenčiny: 'My sister has a new car.'",
          options: [
            "Moja sestra má nové auto.",
            "Moja sestra má nový dom.",
            "Moja sestra má novú prácu.",
            "Moja sestra má nový telefón.",
          ],
          correctAnswer: "Moja sestra má nové auto.",
        },
        {
          question: "Preložte do Slovenčiny: 'Our family has a big house.'",
          options: [
            "Naša rodina má veľký dom.",
            "Naša rodina má malý dom.",
            "Naša rodina má nový dom.",
            "Naša rodina má moderný dom.",
          ],
          correctAnswer: "Naša rodina má veľký dom.",
        },
        {
          question: "Preložte do Slovenčiny: 'My parents are very nice.'",
          options: [
            "Moji rodičia sú veľmi milí.",
            "Moji rodičia sú veľmi prísni.",
            "Moji rodičia sú veľmi zaneprázdnení.",
            "Moji rodičia sú veľmi unavení.",
          ],
          correctAnswer: "Moji rodičia sú veľmi milí.",
        },
        {
          question: "Preložte do Slovenčiny: 'I like when the family gathers.'",
          options: [
            "Rád/a mám, keď sa rodina stretáva.",
            "Rád/a mám, keď rodina cestuje.",
            "Rád/a mám, keď rodina varí.",
            "Rád/a mám, keď rodina pracuje.",
          ],
          correctAnswer: "Rád/a mám, keď sa rodina stretáva.",
        },
        {
          question: "Preložte do Slovenčiny: 'My mom works from home.'",
          options: [
            "Moja mama pracuje z domu.",
            "Moja mama pracuje v škole.",
            "Moja mama pracuje v kancelárii.",
            "Moja mama pracuje v obchode.",
          ],
          correctAnswer: "Moja mama pracuje z domu.",
        },
        {
          question: "Preložte do Slovenčiny: 'My father likes to read books.'",
          options: [
            "Môj otec rád číta knihy.",
            "Môj otec rád sleduje TV.",
            "Môj otec rád hrá šport.",
            "Môj otec rád varí.",
          ],
          correctAnswer: "Môj otec rád číta knihy.",
        },
        {
          question: "Preložte do Slovenčiny: 'Our family goes on trips.'",
          options: [
            "Naša rodina chodí na výlety.",
            "Naša rodina chodí do parku.",
            "Naša rodina chodí do kina.",
            "Naša rodina chodí nakupovať.",
          ],
          correctAnswer: "Naša rodina chodí na výlety.",
        },
        {
          question: "Preložte do Slovenčiny: 'My brother has his own room.'",
          options: [
            "Môj brat má svoju vlastnú izbu.",
            "Môj brat má svoje vlastné auto.",
            "Môj brat má svoj vlastný bicykel.",
            "Môj brat má svoj vlastný počítač.",
          ],
          correctAnswer: "Môj brat má svoju vlastnú izbu.",
        },
        {
          question:
            "Preložte do Slovenčiny: 'Our sister helps us with homework.'",
          options: [
            "Naša sestra nám pomáha s domácimi úlohami.",
            "Naša sestra nám pomáha s varením.",
            "Naša sestra nám pomáha s nakupovaním.",
            "Naša sestra nám pomáha so záhradníčením.",
          ],
          correctAnswer: "Naša sestra nám pomáha s domácimi úlohami.",
        },
        {
          question: "Preložte do Slovenčiny: 'My grandfather is very wise.'",
          options: [
            "Môj starý otec je veľmi múdry.",
            "Môj starý otec je veľmi silný.",
            "Môj starý otec je veľmi bohatý.",
            "Môj starý otec je veľmi mladý.",
          ],
          correctAnswer: "Môj starý otec je veľmi múdry.",
        },
      ],
      backgroundImage: require("./categories/lang/lang-preklad-sk-ang-rodina.png"),
    },

    {
      name: "Angličtina - slovná zásoba - programovanie",
      questions: [
        {
          question: "Aký je anglický výraz pre 'cyklus' v programovaní?",
          options: ["loop", "condition", "method", "variable"],
          correctAnswer: "loop",
        },
        {
          question: "Aký je anglický výraz pre 'premenná'?",
          options: ["variable", "function", "loop", "object"],
          correctAnswer: "variable",
        },
        {
          question: "Aký je anglický výraz pre 'podmienka'?",
          options: ["condition", "statement", "method", "class"],
          correctAnswer: "condition",
        },
        {
          question: "Ako sa povie po anglicky 'funkcia'?",
          options: ["function", "object", "loop", "method"],
          correctAnswer: "function",
        },
        {
          question: "Čo znamená slovo 'array' v programovaní?",
          options: ["pole", "objekt", "cyklus", "premenná"],
          correctAnswer: "pole",
        },
        {
          question:
            "Aký je anglický výraz pre 'trieda' v objektovo orientovanom programovaní?",
          options: ["class", "method", "object", "interface"],
          correctAnswer: "class",
        },
        {
          question: "Čo znamená 'inheritance' v programovaní?",
          options: ["dedičnosť", "metóda", "podmienka", "premenná"],
          correctAnswer: "dedičnosť",
        },
        {
          question: "Aký je anglický výraz pre 'rozhranie'?",
          options: ["interface", "object", "class", "function"],
          correctAnswer: "interface",
        },
        {
          question: "Čo znamená 'recursion' v programovaní?",
          options: ["rekurzia", "cyklus", "výnimka", "trieda"],
          correctAnswer: "rekurzia",
        },
        {
          question: "Aký je anglický výraz pre 'chyba' v programe?",
          options: ["bug", "loop", "object", "method"],
          correctAnswer: "bug",
        },
        {
          question: "Aký je anglický výraz pre 'výnimka' v programovaní?",
          options: ["exception", "condition", "method", "loop"],
          correctAnswer: "exception",
        },
        {
          question: "Čo znamená 'debugging' v programovaní?",
          options: ["ladeni", "testovanie", "kompilácia", "vývoj"],
          correctAnswer: "ladeni",
        },
        {
          question: "Aký je anglický výraz pre 'objekt'?",
          options: ["object", "interface", "method", "class"],
          correctAnswer: "object",
        },
        {
          question: "Aký je anglický výraz pre 'zdrojový kód'?",
          options: ["source code", "bug", "function", "algorithm"],
          correctAnswer: "source code",
        },
        {
          question: "Čo znamená 'syntax' v programovaní?",
          options: [
            "pravidlá zápisu kódu",
            "dedičnosť",
            "reťazec",
            "testovanie",
          ],
          correctAnswer: "pravidlá zápisu kódu",
        },
        {
          question: "Aký je anglický výraz pre 'kompilácia'?",
          options: ["compilation", "interface", "object", "class"],
          correctAnswer: "compilation",
        },
        {
          question: "Aký je anglický výraz pre 'algoritmus'?",
          options: ["algorithm", "method", "bug", "array"],
          correctAnswer: "algorithm",
        },
        {
          question: "Čo znamená slovo 'loop' v programovaní?",
          options: ["cyklus", "podmienka", "premenná", "metóda"],
          correctAnswer: "cyklus",
        },
        {
          question: "Aký je anglický výraz pre 'metóda'?",
          options: ["method", "loop", "function", "object"],
          correctAnswer: "method",
        },
        {
          question: "Aký je anglický výraz pre 'reťazec'?",
          options: ["string", "object", "function", "array"],
          correctAnswer: "string",
        },
        {
          question: "Aký je anglický výraz pre 'údaj'?",
          options: ["data", "condition", "array", "object"],
          correctAnswer: "data",
        },
        {
          question: "Čo znamená 'boolean' v programovaní?",
          options: [
            "logický údaj (pravda/nepravda)",
            "cyklus",
            "chyba",
            "premenna",
          ],
          correctAnswer: "logický údaj (pravda/nepravda)",
        },
        {
          question: "Aký je anglický výraz pre 'spúšťací súbor'?",
          options: ["executable", "source code", "interface", "class"],
          correctAnswer: "executable",
        },
        {
          question: "Čo znamená 'parameter' vo funkcii?",
          options: ["vstupná hodnota", "výstupná hodnota", "chyba", "objekt"],
          correctAnswer: "vstupná hodnota",
        },
        {
          question: "Aký je anglický výraz pre 'programovací jazyk'?",
          options: ["programming language", "source code", "data", "function"],
          correctAnswer: "programming language",
        },
        {
          question: "Aký je anglický výraz pre 'operačný systém'?",
          options: ["operating system", "interface", "object", "algorithm"],
          correctAnswer: "operating system",
        },
        {
          question: "Čo znamená slovo 'framework' v programovaní?",
          options: ["súbor nástrojov a knižníc", "cyklus", "premenná", "chyba"],
          correctAnswer: "súbor nástrojov a knižníc",
        },
        {
          question: "Aký je anglický výraz pre 'vývojové prostredie'?",
          options: ["development environment", "class", "object", "algorithm"],
          correctAnswer: "development environment",
        },
        {
          question: "Aký je anglický výraz pre 'zdrojový súbor'?",
          options: ["source file", "data file", "object file", "executable"],
          correctAnswer: "source file",
        },
        {
          question: "Čo znamená 'runtime' v programovaní?",
          options: ["čas behu programu", "cyklus", "syntax", "chyba"],
          correctAnswer: "čas behu programu",
        },
      ],
      backgroundImage: require("./categories/lang/lang-programovanie-anglictina.jpg"),
    },

    {
      name: "Angličtina - slovná zásoba - počítače",
      questions: [
        {
          question: "Čo znamená slovo 'hardware' v oblasti počítačov?",
          options: [
            "Používateľské rozhranie",
            "Programové vybavenie",
            "Internetové pripojenie",
            "Fyzické komponenty počítača",
          ],
          correctAnswer: "Fyzické komponenty počítača",
        },
        {
          question: "Čo je to 'software'?",
          options: [
            "Systémová aktualizácia",
            "Elektrická energia",
            "Programové vybavenie počítača",
            "Fyzické komponenty počítača",
          ],
          correctAnswer: "Programové vybavenie počítača",
        },
        {
          question: "Čo znamená 'CPU'?",
          options: [
            "Central Power Unit",
            "Computer Power Unit",
            "Central Processing Unit",
            "Core Processing Unit",
          ],
          correctAnswer: "Central Processing Unit",
        },
        {
          question: "Čo je to 'RAM'?",
          options: [
            "Read Only Memory",
            "Random Access Memory",
            "Real-time Active Memory",
            "Remote Access Management",
          ],
          correctAnswer: "Random Access Memory",
        },
        {
          question: "Čo znamená skratka 'GPU'?",
          options: [
            "Gigabyte Processing Unit",
            "Graphics Processing Unit",
            "Global Processing Unit",
            "General Purpose Unit",
          ],
          correctAnswer: "Graphics Processing Unit",
        },
        {
          question: "Čo je to 'motherboard'?",
          options: [
            "Externý pevný disk",
            "Záložný zdroj energie",
            "Hlavná doska počítača",
            "Sieťová karta",
          ],
          correctAnswer: "Hlavná doska počítača",
        },
        {
          question: "Čo znamená 'SSD'?",
          options: [
            "Super Speed Disk",
            "Solid State Drive",
            "System Storage Device",
            "Standard State Device",
          ],
          correctAnswer: "Solid State Drive",
        },
        {
          question: "Čo je to 'cloud computing'?",
          options: [
            "Optimalizácia výkonu počítača",
            "Používanie vzdialených serverov na ukladanie dát",
            "Záloha dát na externý disk",
            "Inštalácia softvéru cez internet",
          ],
          correctAnswer: "Používanie vzdialených serverov na ukladanie dát",
        },
        {
          question: "Čo znamená 'firewall'?",
          options: [
            "Systém na zvýšenie výkonu",
            "Program na zálohovanie dát",
            "Bezpečnostný systém na ochranu počítača",
            "Fyzická bariéra okolo serverov",
          ],
          correctAnswer: "Bezpečnostný systém na ochranu počítača",
        },
        {
          question: "Čo je to 'malware'?",
          options: [
            "Virtuálny disk",
            "Aktualizačný softvér",
            "Škodlivý softvér",
            "Bezpečnostný program",
          ],
          correctAnswer: "Škodlivý softvér",
        },
        {
          question: "Čo znamená 'HTTP'?",
          options: [
            "Host Text Transfer Protocol",
            "HyperText Transfer Protocol",
            "Hyper Transfer Text Platform",
            "High Transfer Text Program",
          ],
          correctAnswer: "HyperText Transfer Protocol",
        },
        {
          question: "Čo je to 'bit'?",
          options: [
            "Najmenšia jednotka informácie v počítači",
            "Súčasť procesora",
            "Typ pamäte",
            "Dátová banka",
          ],
          correctAnswer: "Najmenšia jednotka informácie v počítači",
        },
        {
          question: "Čo znamená 'URL'?",
          options: [
            "Uniform Resource Locator",
            "Unified Remote Link",
            "Unique Resource Locator",
            "Universal Resource Link",
          ],
          correctAnswer: "Uniform Resource Locator",
        },
        {
          question: "Čo znamená 'HTML'?",
          options: [
            "High Transfer Media Language",
            "Hyper Transfer Markup Language",
            "Host Text Media Link",
            "HyperText Markup Language",
          ],
          correctAnswer: "HyperText Markup Language",
        },
        {
          question: "Čo znamená 'OS' v kontexte počítačov?",
          options: [
            "Online Software",
            "Output System",
            "Operating System",
            "Operational Security",
          ],
          correctAnswer: "Operating System",
        },
      ],
      backgroundImage: require("./categories/lang/lang-pc-anglictina.jpg"),
    },

    {
      name: "Angličtina - minulý čas slovies ",
      questions: [
        {
          question: "Aký je minulý čas slovesa 'go'?",
          options: ["goed", "going", "went", "gone"],
          correctAnswer: "went",
        },
        {
          question: "Aký je minulý čas slovesa 'eat'?",
          options: ["eat", "ate", "eaten", "eated"],
          correctAnswer: "ate",
        },
        {
          question: "Aký je minulý čas slovesa 'have'?",
          options: ["haved", "had", "has", "having"],
          correctAnswer: "had",
        },
        {
          question: "Aký je minulý čas slovesa 'take'?",
          options: ["taken", "took", "tooken", "taked"],
          correctAnswer: "took",
        },
        {
          question: "Aký je minulý čas slovesa 'see'?",
          options: ["seed", "seen", "seeing", "saw"],
          correctAnswer: "saw",
        },
        {
          question: "Aký je minulý čas slovesa 'make'?",
          options: ["making", "maded", "maked", "made"],
          correctAnswer: "made",
        },
        {
          question: "Aký je minulý čas slovesa 'run'?",
          options: ["running", "ran", "runed", "runned"],
          correctAnswer: "ran",
        },
        {
          question: "Aký je minulý čas slovesa 'buy'?",
          options: ["buyed", "buying", "bought", "buys"],
          correctAnswer: "bought",
        },
        {
          question: "Aký je minulý čas slovesa 'write'?",
          options: ["written", "wrote", "writing", "writed"],
          correctAnswer: "wrote",
        },
        {
          question: "Aký je minulý čas slovesa 'come'?",
          options: ["comes", "coming", "came", "comed"],
          correctAnswer: "came",
        },
        {
          question: "Aký je minulý čas slovesa 'drink'?",
          options: ["drank", "drinking", "drinked", "drunk"],
          correctAnswer: "drank",
        },
        {
          question: "Aký je minulý čas slovesa 'sing'?",
          options: ["singed", "sung", "singing", "sang"],
          correctAnswer: "sang",
        },
        {
          question: "Aký je minulý čas slovesa 'drive'?",
          options: ["driven", "drove", "driving", "drived"],
          correctAnswer: "drove",
        },
        {
          question: "Aký je minulý čas slovesa 'begin'?",
          options: ["begun", "begined", "began", "beginning"],
          correctAnswer: "began",
        },
        {
          question: "Aký je minulý čas slovesa 'read'?",
          options: ["reading", "reads", "read", "readed"],
          correctAnswer: "read",
        },
      ],
      backgroundImage: require("./categories/lang/lang-minuly-cas-slovies.jpg"),
    },

    {
      name: "Angličtina - slovná zásoba - obchod",
      questions: [
        {
          question: "Čo znamená slovo 'invoice'?",
          options: ["Platba", "Faktúra", "Výdavok", "Zmluva"],
          correctAnswer: "Faktúra",
        },
        {
          question: "Čo znamená výraz 'revenue'?",
          options: ["Dlžník", "Príjem", "Výdavok", "Suma"],
          correctAnswer: "Príjem",
        },
        {
          question: "Aký je význam slova 'profit'?",
          options: ["Daň", "Rozpočet", "Náklady", "Zisk"],
          correctAnswer: "Zisk",
        },
        {
          question: "Čo označuje slovo 'expense'?",
          options: ["Účet", "Rezerva", "Výdavok", "Príjem"],
          correctAnswer: "Výdavok",
        },
        {
          question: "Čo znamená termín 'equity'?",
          options: ["Dlhová služba", "Vlastný kapitál", "Aktíva", "Zostatok"],
          correctAnswer: "Vlastný kapitál",
        },
        {
          question: "Čo znamená slovo 'assets'?",
          options: ["Straty", "Záväzky", "Náklady", "Aktíva"],
          correctAnswer: "Aktíva",
        },
        {
          question: "Aký je význam slova 'liability'?",
          options: ["Rezerva", "Výnos", "Nárok", "Záväzok"],
          correctAnswer: "Záväzok",
        },
        {
          question: "Čo znamená výraz 'market share'?",
          options: ["Výnos", "Podiel na trhu", "Kapitál", "Cenné papiere"],
          correctAnswer: "Podiel na trhu",
        },
        {
          question: "Čo označuje slovo 'budget'?",
          options: ["Faktúra", "Rozpočet", "Zisk", "Náklady"],
          correctAnswer: "Rozpočet",
        },
        {
          question: "Aký je význam termínu 'debt'?",
          options: ["Aktíva", "Výdavok", "Príjem", "Dlh"],
          correctAnswer: "Dlh",
        },
        {
          question: "Čo znamená slovo 'overhead'?",
          options: ["Režijné náklady", "Zisk", "Účet", "Podiel"],
          correctAnswer: "Režijné náklady",
        },
        {
          question: "Čo označuje slovo 'dividend'?",
          options: ["Náklady", "Poistenie", "Kapitalizácia", "Dividenda"],
          correctAnswer: "Dividenda",
        },
        {
          question: "Čo znamená termín 'inventory'?",
          options: ["Náklady", "Účtovníctvo", "Skladové zásoby", "Fakturácia"],
          correctAnswer: "Skladové zásoby",
        },
        {
          question: "Aký je význam slova 'interest'?",
          options: ["Podiel", "Úspory", "Dlh", "Úrok"],
          correctAnswer: "Úrok",
        },
        {
          question: "Čo znamená pojem 'gross profit'?",
          options: ["Hrubý zisk", "Režijné náklady", "Čistý zisk", "Výdavok"],
          correctAnswer: "Hrubý zisk",
        },
      ],
      backgroundImage: require("./categories/lang/lang-obch-anglictina.jpg"),
    },

    {
      name: "Angličtina - slovná zásoba - rodina",
      questions: [
        {
          question: "Čo znamená slovo 'mother'?",
          options: ["Dcéra", "Otec", "Matka", "Súrodenec"],
          correctAnswer: "Matka",
        },
        {
          question: "Čo označuje slovo 'cousin'?",
          options: [
            "Starý otec",
            "Synovec",
            "Sesternica alebo bratranec",
            "Teta",
          ],
          correctAnswer: "Sesternica alebo bratranec",
        },
        {
          question: "Aký je preklad slova 'sister'?",
          options: ["Matka", "Sestra", "Brat", "Syn"],
          correctAnswer: "Sestra",
        },
        {
          question: "Čo znamená výraz 'uncle'?",
          options: ["Súrodenec", "Bratanec", "Otec", "Strýko"],
          correctAnswer: "Strýko",
        },
        {
          question: "Aký je význam slova 'niece'?",
          options: ["Matka", "Sesternica", "Syn", "Neter"],
          correctAnswer: "Neter",
        },
        {
          question: "Čo označuje slovo 'grandmother'?",
          options: ["Dcéra", "Teta", "Matka", "Stará matka"],
          correctAnswer: "Stará matka",
        },
        {
          question: "Čo znamená výraz 'husband'?",
          options: ["Otec", "Manžel", "Starý otec", "Brat"],
          correctAnswer: "Manžel",
        },
        {
          question: "Čo znamená slovo 'daughter'?",
          options: ["Syn", "Dcéra", "Sestra", "Neter"],
          correctAnswer: "Dcéra",
        },
        {
          question: "Aký je preklad slova 'nephew'?",
          options: ["Strýko", "Brat", "Synovec", "Neter"],
          correctAnswer: "Synovec",
        },
        {
          question: "Čo označuje slovo 'wife'?",
          options: ["Matka", "Dcéra", "Manželka", "Babka"],
          correctAnswer: "Manželka",
        },
        {
          question: "Aký je význam slova 'brother'?",
          options: ["Otec", "Brat", "Strýko", "Synovec"],
          correctAnswer: "Brat",
        },
        {
          question: "Čo znamená slovo 'grandfather'?",
          options: ["Strýko", "Brat", "Otec", "Starý otec"],
          correctAnswer: "Starý otec",
        },
        {
          question: "Čo znamená slovo 'parents'?",
          options: ["Rodičia", "Stará rodičia", "Deti", "Súrodenci"],
          correctAnswer: "Rodičia",
        },
        {
          question: "Aký je význam slova 'aunt'?",
          options: ["Teta", "Babka", "Matka", "Neter"],
          correctAnswer: "Teta",
        },
        {
          question: "Čo znamená pojem 'siblings'?",
          options: ["Bratranec a sesternica", "Vnuci", "Súrodenci", "Deti"],
          correctAnswer: "Súrodenci",
        },
      ],
      backgroundImage: require("./categories/lang/lang-family-anglictina.jpg"),
    },

    {
      name: "Angličtina - slovná zásoba - číslovky",
      questions: [
        {
          question: "Ako sa povie číslovka '5' po anglicky?",
          options: ["Seven", "Five", "Three", "Four"],
          correctAnswer: "Five",
        },
        {
          question: "Čo znamená číslovka 'Ten'?",
          options: ["Desať", "Dvadsať", "Päť", "Tridsať"],
          correctAnswer: "Desať",
        },
        {
          question: "Aký je preklad číslovky 'Twenty'?",
          options: ["Štyridsať", "Dvanásť", "Tridsať", "Dvadsať"],
          correctAnswer: "Dvadsať",
        },
        {
          question: "Ako sa povie číslovka '9' po anglicky?",
          options: ["Eleven", "Eight", "Six", "Nine"],
          correctAnswer: "Nine",
        },
        {
          question: "Čo znamená číslovka 'Fifteen'?",
          options: ["Dvadsaťpäť", "Osemnásť", "Päť", "Pätnásť"],
          correctAnswer: "Pätnásť",
        },
        {
          question: "Ako sa povie číslovka '12' po anglicky?",
          options: ["Fourteen", "Sixteen", "Ten", "Twelve"],
          correctAnswer: "Twelve",
        },
        {
          question: "Aký je preklad číslovky 'Forty'?",
          options: ["Šesťdesiat", "Štyridsať", "Päťdesiat", "Tridsať"],
          correctAnswer: "Štyridsať",
        },
        {
          question: "Ako sa povie číslovka '3' po anglicky?",
          options: ["Five", "Three", "Two", "Four"],
          correctAnswer: "Three",
        },
        {
          question: "Čo znamená číslovka 'Eight'?",
          options: ["Deväť", "Osem", "Sedem", "Šesť"],
          correctAnswer: "Osem",
        },
        {
          question: "Ako sa povie číslovka '50' po anglicky?",
          options: ["Forty", "Fifty", "Thirty", "Sixty"],
          correctAnswer: "Fifty",
        },
        {
          question: "Aký je preklad číslovky 'Seventy'?",
          options: ["Štyridsať", "Sedemdesiat", "Sedem", "Sedemdesiatpäť"],
          correctAnswer: "Sedemdesiat",
        },
        {
          question: "Čo znamená číslovka 'Eleven'?",
          options: ["Jedenásť", "Trinásť", "Dvanásť", "Desať"],
          correctAnswer: "Jedenásť",
        },
        {
          question: "Ako sa povie číslovka '6' po anglicky?",
          options: ["Seven", "Six", "Eight", "Five"],
          correctAnswer: "Six",
        },
        {
          question: "Čo znamená číslovka 'Thirty'?",
          options: ["Šesťdesiat", "Päťdesiat", "Dvadsať", "Tridsať"],
          correctAnswer: "Tridsať",
        },
        {
          question: "Ako sa povie číslovka '100' po anglicky?",
          options: ["One thousand", "One hundred", "One million", "Ten"],
          correctAnswer: "One hundred",
        },
      ],
      backgroundImage: require("./categories/lang/lang-cislovky-anglictina.jpg"),
    },

    {
      name: "Angličtina - gramatika základy",
      questions: [
        {
          question: "Ktorý z nasledujúcich časov je prítomný jednoduchý čas?",
          options: ["I walk", "I walked", "I will walk", "I am walking"],
          correctAnswer: "I walk",
        },
        {
          question: "Ktorá veta je v prítomnom priebehovom čase?",
          options: ["She will eat", "She is eating", "She eats", "She ate"],
          correctAnswer: "She is eating",
        },
        {
          question: "Ako sa tvorí zápor v prítomnom jednoduchom čase?",
          options: [
            "Is not + základný tvar slovesa",
            "Will not + základný tvar slovesa",
            "Do not + základný tvar slovesa",
            "Did not + základný tvar slovesa",
          ],
          correctAnswer: "Do not + základný tvar slovesa",
        },
        {
          question: "Ktorá z nasledujúcich viet je v minulom jednoduchom čase?",
          options: [
            "They will visit",
            "They visited",
            "They are visiting",
            "They visit",
          ],
          correctAnswer: "They visited",
        },
        {
          question:
            "Aký je správny tvar slovesa 'to be' v prítomnom jednoduchom čase pre 'he'?",
          options: ["He am", "He is", "He was", "He are"],
          correctAnswer: "He is",
        },
        {
          question: "Ktorý z nasledujúcich časov vyjadruje budúcnosť?",
          options: ["I went", "I am going", "I go", "I will go"],
          correctAnswer: "I will go",
        },
        {
          question:
            "Ktorá z nasledujúcich viet obsahuje správny tvar minulého priebehového času?",
          options: [
            "They will watch",
            "They were watching",
            "They watched",
            "They are watching",
          ],
          correctAnswer: "They were watching",
        },
        {
          question: "Ktorá veta je v prítomnom dokonavom čase?",
          options: ["He is eating", "He has eaten", "He eats", "He ate"],
          correctAnswer: "He has eaten",
        },
        {
          question: "Ako sa tvorí otázka v prítomnom jednoduchom čase?",
          options: [
            "Did + podmet + základný tvar slovesa",
            "Is/Are + podmet + sloveso-ing",
            "Do/Does + podmet + základný tvar slovesa",
            "Will + podmet + základný tvar slovesa",
          ],
          correctAnswer: "Do/Does + podmet + základný tvar slovesa",
        },
        {
          question: "Ktorá z nasledujúcich viet je správne zložená?",
          options: [
            "He didn't likes apples",
            "He don't like apples",
            "He doesn't like apples",
            "He isn't likes apples",
          ],
          correctAnswer: "He doesn't like apples",
        },
        {
          question:
            "Ktorý z nasledujúcich tvarov je v správnej podobe pre budúci čas?",
          options: [
            "She works",
            "She worked",
            "She will work",
            "She is working",
          ],
          correctAnswer: "She will work",
        },
        {
          question: "Ktorý čas vyjadruje akciu, ktorá sa práve deje?",
          options: [
            "Budúci čas",
            "Prítomný priebehový čas",
            "Minulý jednoduchý čas",
            "Prítomný jednoduchý čas",
          ],
          correctAnswer: "Prítomný priebehový čas",
        },
        {
          question: "Ktorá veta je v správnom minulom dokonavom čase?",
          options: [
            "They will finish",
            "They finish",
            "They have finished",
            "They had finished",
          ],
          correctAnswer: "They had finished",
        },
        {
          question: "Aký je správny zápor v prítomnom priebehovom čase?",
          options: [
            "Did not + základný tvar slovesa",
            "Do not + základný tvar slovesa",
            "Is/Are not + sloveso-ing",
            "Will not + základný tvar slovesa",
          ],
          correctAnswer: "Is/Are not + sloveso-ing",
        },
        {
          question: "Ako sa tvorí otázka v budúcom čase?",
          options: [
            "Do/Does + podmet + základný tvar slovesa",
            "Is/Are + podmet + sloveso-ing",
            "Did + podmet + základný tvar slovesa",
            "Will + podmet + základný tvar slovesa",
          ],
          correctAnswer: "Will + podmet + základný tvar slovesa",
        },
      ],
      backgroundImage: require("./categories/lang/lang-gramatika-anglictina.jpg"),
    },

    {
      name: "Angličtina - slovná zásoba - obliekanie",
      questions: [
        {
          question: "Ako sa po anglicky povie 'tričko'?",
          options: ["Jacket", "T-shirt", "Pants", "Sweater"],
          correctAnswer: "T-shirt",
        },
        {
          question: "Ako sa po anglicky povie 'nohavice'?",
          options: ["Pants", "Hat", "Shoes", "Dress"],
          correctAnswer: "Pants",
        },
        {
          question: "Ako sa po anglicky povie 'šaty'?",
          options: ["Dress", "Scarf", "Shirt", "Jeans"],
          correctAnswer: "Dress",
        },
        {
          question: "Ako sa po anglicky povie 'topánky'?",
          options: ["Belt", "Shoes", "Coat", "Gloves"],
          correctAnswer: "Shoes",
        },
        {
          question: "Ako sa po anglicky povie 'klobúk'?",
          options: ["Hat", "Sweater", "T-shirt", "Shorts"],
          correctAnswer: "Hat",
        },
        {
          question: "Ako sa po anglicky povie 'šál'?",
          options: ["Jacket", "Scarf", "Skirt", "Gloves"],
          correctAnswer: "Scarf",
        },
        {
          question: "Ako sa po anglicky povie 'rukavice'?",
          options: ["Gloves", "Belt", "Trousers", "Boots"],
          correctAnswer: "Gloves",
        },
        {
          question: "Ako sa po anglicky povie 'kabát'?",
          options: ["Jeans", "Socks", "Coat", "Shirt"],
          correctAnswer: "Coat",
        },
        {
          question: "Ako sa po anglicky povie 'opasok'?",
          options: ["Shoes", "Belt", "Hat", "T-shirt"],
          correctAnswer: "Belt",
        },
        {
          question: "Ako sa po anglicky povie 'sukňa'?",
          options: ["Skirt", "Shirt", "Sweater", "Trousers"],
          correctAnswer: "Skirt",
        },
        {
          question: "Ako sa po anglicky povie 'sveter'?",
          options: ["Sweater", "Boots", "Scarf", "Coat"],
          correctAnswer: "Sweater",
        },
        {
          question: "Ako sa po anglicky povie 'džínsy'?",
          options: ["Skirt", "Jeans", "Hat", "Socks"],
          correctAnswer: "Jeans",
        },
        {
          question: "Ako sa po anglicky povie 'ponožky'?",
          options: ["Socks", "Sweater", "Boots", "Pants"],
          correctAnswer: "Socks",
        },
        {
          question: "Ako sa po anglicky povie 'čižmy'?",
          options: ["Dress", "Shirt", "Boots", "Gloves"],
          correctAnswer: "Boots",
        },
        {
          question: "Ako sa po anglicky povie 'košeľa'?",
          options: ["Shirt", "T-shirt", "Coat", "Pants"],
          correctAnswer: "Shirt",
        },
      ],
      backgroundImage: require("./categories/lang/lang-obliekanie-anglictina.jpg"),
    },
  ],

  // Ostatné kategórie a testy koniec
};

export default categoryTests;
