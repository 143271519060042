import { useParams } from "react-router-dom";
import AdsHorizontal from "../AdsHorizontal";
import "./TestDetailPage.css";
import { useState, useEffect } from "react";
import categoryTests from "../categoryTests";

const TestDetailPage = () => {
  const { testName } = useParams();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [displayedProgress, setDisplayedProgress] = useState(0);
  const [progressInterval, setProgressInterval] = useState(null);
  const [isQuestionVisible, setIsQuestionVisible] = useState(true);
  const [shuffledOptions, setShuffledOptions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]); // Vybrané otázky

  // Funkcia na zamiešanie polí
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  // Nájdeme testy vo všetkých kategóriách a vyhľadáme test s názvom testName
  const test = Object.values(categoryTests)
    .flat()
    .find((t) => t.name.replace(/\s+/g, "-") === testName);

  // Pri načítaní testu vyberieme maximálne 15 otázok a zamiešame ich
  useEffect(() => {
    if (test && test.questions.length > 0) {
      // Zamiešame všetky otázky
      const shuffledQuestions = shuffleArray([...test.questions]);

      // Ak je viac ako 15 otázok, vyberieme 15 náhodných
      const questionsToUse = shuffledQuestions.slice(0, 15);

      setSelectedQuestions(questionsToUse);

      // Zamiešame možnosti pre prvú otázku
      const shuffledOptions = shuffleArray([...questionsToUse[0].options]);
      setShuffledOptions(shuffledOptions);
    }
  }, [test]);

  // Pri zmene aktuálnej otázky zamiešaj možnosti
  useEffect(() => {
    if (selectedQuestions.length > 0) {
      const shuffled = shuffleArray([
        ...selectedQuestions[currentQuestionIndex].options,
      ]);
      setShuffledOptions(shuffled);
    }
  }, [currentQuestionIndex, selectedQuestions]);

  // Funkcie na prechádzanie otázkami
  const goToNextQuestion = () => {
    if (currentQuestionIndex < selectedQuestions.length - 1) {
      setIsQuestionVisible(false);
      setTimeout(() => {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        updateProgress();
        setIsQuestionVisible(true);
      }, 500); // Čas v milisekundách na prechod
    } else if (currentQuestionIndex === selectedQuestions.length - 1) {
      setShowResult(true);
      setDisplayedProgress(100);
    }
  };

  const goToPreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setIsQuestionVisible(false);
      setTimeout(() => {
        setCurrentQuestionIndex(currentQuestionIndex - 1);
        setIsQuestionVisible(true);
      }, 500); // Čas v milisekundách na prechod
    }
  };

  const handleAnswerClick = (option) => {
    const newAnswers = [...userAnswers];
    newAnswers[currentQuestionIndex] = option;
    setUserAnswers(newAnswers);
  };

  const calculateScore = () => {
    if (!selectedQuestions) return 0;
    let correctCount = 0;
    selectedQuestions.forEach((question, index) => {
      if (userAnswers[index] === question.correctAnswer) {
        correctCount++;
      }
    });
    return Math.round((correctCount / selectedQuestions.length) * 100);
  };

  const calculateProgress = () => {
    if (!selectedQuestions) return 0;
    return Math.round(
      ((currentQuestionIndex + 1) / selectedQuestions.length) * 100
    );
  };

  const updateProgress = () => {
    const targetProgress = calculateProgress();
    if (progressInterval) {
      clearInterval(progressInterval);
    }
    const increment = targetProgress > displayedProgress ? 1 : -1;
    const interval = setInterval(() => {
      setDisplayedProgress((prevProgress) => {
        const newProgress = prevProgress + increment;
        if (
          (increment > 0 && newProgress >= targetProgress) ||
          (increment < 0 && newProgress <= targetProgress)
        ) {
          clearInterval(interval);
          return targetProgress;
        }
        return newProgress;
      });
    }, 30);
    setProgressInterval(interval);
  };

  useEffect(() => {
    if (currentQuestionIndex === 0) {
      setDisplayedProgress(0);
    }
  }, [currentQuestionIndex]);

  // Scroll to top on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (showResult) {
      window.scrollTo(0, 0);
    }
  }, [showResult]);

  return (
    <div className="test-detail-page">
      <AdsHorizontal />

      <div className="test-wrapper">
        <div className="test-details">
          {test ? (
            showResult ? (
              <div className="result-block">
                <h1>{test.name}</h1>
                <h2>Výsledok testu: {calculateScore()}%</h2>

                <div className="answers-review">
                  {selectedQuestions.map((question, index) => (
                    <div key={index} className="question-review">
                      <p className="review-question">
                        <strong>Otázka {index + 1}: </strong>
                        {question.question}
                      </p>
                      <ul>
                        {question.options.map((option, optionIndex) => {
                          const isCorrect = option === question.correctAnswer;
                          const isUserAnswer = option === userAnswers[index];
                          const className = isCorrect
                            ? "correct-answer"
                            : isUserAnswer
                            ? "incorrect-answer"
                            : "";
                          return (
                            <li key={optionIndex} className={className}>
                              {option}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <>
                <img className="test-image" src={test.backgroundImage} alt="" />
                <h2>{test.name}</h2>

                <div className="progress-bar">
                  <div
                    className="progress-bar-fill"
                    style={{ width: `${displayedProgress}%` }}
                  >
                    {displayedProgress}%
                  </div>
                </div>

                <p className="question-number">
                  Otázka {currentQuestionIndex + 1} z {selectedQuestions.length}
                </p>

                {selectedQuestions.length > 0 && (
                  <div
                    className={`question-block ${
                      isQuestionVisible ? "" : "hidden"
                    }`}
                  >
                    <p className="question">
                      <strong>
                        Otázka: <br />
                      </strong>{" "}
                      {selectedQuestions[currentQuestionIndex].question}
                    </p>
                    <ul>
                      {shuffledOptions.map((option, optionIndex) => (
                        <li
                          key={optionIndex}
                          className={
                            userAnswers[currentQuestionIndex] === option
                              ? "selected"
                              : ""
                          }
                          onClick={() => {
                            handleAnswerClick(option);
                            goToNextQuestion();
                          }}
                        >
                          {option}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="navigation-buttons">
                  <button
                    onClick={goToPreviousQuestion}
                    disabled={currentQuestionIndex === 0}
                  >
                    Naspäť
                  </button>
                </div>
              </>
            )
          ) : (
            <p>Test neexistuje.</p>
          )}
        </div>
      </div>

      <AdsHorizontal />
    </div>
  );
};

export default TestDetailPage;
